import React, { useMemo } from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";
import { BsArrowUpRight, BsArrowRight } from "react-icons/bs";
import { FiArrowDownRight } from "react-icons/fi";

// Extracted Badge component
const Badge = ({ text }) => (
  <span className="badge bg-grey text-xs text-white mr-2">{text}</span>
);

// Extracted HeaderNav component
const HeaderNav = ({ t }) => (
  <Nav className="d-flex justify-content-between align-items-center underline mt-2">
    <Nav.Item className="d-flex">
      <Nav.Link href="/" className="">
        {t("directServicePage.Home")}
      </Nav.Link>
      <Nav.Link href="/about-us-Stayforall">
        {t("directServicePage.About")}
      </Nav.Link>
      <Nav.Link href="/FAQS-Stayforall">{t("directServicePage.Faq")}</Nav.Link>
    </Nav.Item>
  </Nav>
);

// Extracted ServiceInfo component
const ServiceInfo = ({ t, item, smallDescription }) => (
  <div className="d-flex justify-content-end mt-2">
    <p className="w-50 text-start text-secondary text-xs mr-5">
      <img
        src="/images/directPage/celebration.webp"
        alt="Celebration"
        loading="lazy"
        className="mr-1 small-icon"
      />
      {t(`directServicePage.${smallDescription}`)}
    </p>
  </div>
);

export default function FirstHead({ t, item, categoryData, handleNext }) {
  // Destructure properties from categoryData for easier access
  const { contsection, smallDescription } = categoryData;

  // Memoize image paths and translations to avoid recalculations
  const smallLogoSrc = useMemo(() => "/images/logo/SFAsmallLogo.webp", []);
  const headerImageSrc = useMemo(() => "/images/inDirect/inDHead.webp", []);
  const contactIconSrc = useMemo(() => "/images/smallicons/contact.png", []);

  return (
    <div className="direct1st">
      <Container className="directcont1">
        <Row className="m-0 p-0">
          <Col
            className="directsection1"
            style={{
              backgroundImage: `url(${contsection})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "20px",
            }}
          >
            <div className="d-flex align-items-center mb-3">
              <img
                alt="SFA small logo"
                src={smallLogoSrc}
                className="w-10 mr-2"
                loading="lazy"
              />
              <div className="font-bold text-white">
                <p className="text-xs m-0">
                  Stayforall {t("directServicePage.Helps")}
                </p>
                <p className="text-xs m-0">
                  {t("directServicePage.Students and Workers")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <Badge text={t("directServicePage.our expertise")} />
                <Badge text={t("directServicePage.services")} />
              </div>
              <BsArrowUpRight className="icon bg-black text-white p-1 rounded" />
            </div>
          </Col>
          <Col className="directsection2">
            <HeaderNav t={t} />
            <div className="d-flex align-items-center justify-content-start">
              <img
                className="w-9 mr-3"
                src={smallLogoSrc}
                alt="SFA logo"
                loading="lazy"
              />
              <h2 className="m-0">
                {t("directServicePage.Stayforall helps you with")} <br />
                {t(`directServicePage.${item}`)}
              </h2>
            </div>
            <ServiceInfo
              t={t}
              item={item}
              smallDescription={smallDescription}
            />
            <img
              className="w-100 mt-2"
              src={headerImageSrc}
              alt="Direct Service Header"
              loading="lazy"
            />
            <div className="text-end ml-5 mt-3">
              <h6 className="text-grey m-0">
                {t(`directServicePage.${item}`)}{" "}
                {t("directServicePage.made possible with Stayforall")}
              </h6>
              <p className="text-secondary text-xs mt-1">
                {t("directServicePage.Please fill out the form provided below")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
