import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetOthersInterests,
  MyInDService,
} from "../../../store/Actions/InDirectService";
import { loadUser } from "../../../store/Actions/User";

export const useEffectService = ({
  dispatch,
  query,
  serviceName,
  status,
  setError,
  category,
  user,
  newService,
  resetServiceState,
  location,
  setSavedUrl,
  setServices,
  setServicesCount,
  handleServiceIdSelect,
  savedUrl,
  serviceIdSelect,
  interestStatusSelect,
  inDService,
  setServiceInterests,
  othersInterests,
  explain,
  setServiceIdSelect,
  servicesCount,
  services,
  setCompleteSelect,
  serviceInterests,
  interest,
  setInterestView,
  interestIdSelect,
  setInterestError,
}) => {
  const navigate = useNavigate();
  const myInDService = useSelector((state) => state.myInDService);

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    setError("");
    dispatch(MyInDService({ serviceName: category, status: 0 }));
  }, []);

  useEffect(() => {
    dispatch(loadUser());
    if (user.isAuthenticated) {
      dispatch(MyInDService({ serviceName: category, status: 0 }));
    }
  }, [user, category]);

  useEffect(() => {
    const handleStatusChange = () => {
      switch (status) {
        case "form":
          setError("");
          break;
        case "pending":
          if (newService) {
            setError("");
            resetServiceState();
            dispatch(MyInDService({ serviceName: category, status: 0 }));
          }
          break;
        default:
          break;
      }
    };

    handleStatusChange();
  }, [category, status, newService]);

  useEffect(() => {
    const handleSavedUrl = () => {
      const newQuery = new URLSearchParams(location.search);
      if (myInDService.services?.length === 0) {
        newQuery.set("status", "form");
      } else if (
        status === "form" &&
        !newService &&
        myInDService.services?.length !== 0
      ) {
        newQuery.set("status", "pending");
      }

      setSavedUrl(`${location.pathname}?${newQuery.toString()}`);
      setServices(myInDService.services);
      setServicesCount(myInDService.services?.length);
      handleServiceIdSelect(myInDService.services?.slice(-1)[0]?.id);
    };

    handleSavedUrl();
  }, [category, myInDService.services, newService]);

  useEffect(() => {
    if (savedUrl) {
      navigate(savedUrl, { replace: true });
    }
  }, [savedUrl]);

  useEffect(() => {
    const handleServiceInterests = () => {
      const fetchInterests = () => {
        if (status === "completed") {
          dispatch(
            GetOthersInterests({
              serviceName: category,
              id: serviceIdSelect,
              accepted: "accepted",
            })
          );
        } else {
          dispatch(
            GetOthersInterests({
              serviceName: category,
              id: serviceIdSelect,
              accepted: interestStatusSelect,
            })
          );
        }
      };
      fetchInterests();
    };

    handleServiceInterests();
  }, [category, serviceIdSelect, interestStatusSelect]);

  useEffect(() => {
    const handleInDServiceMessages = () => {
      const handleMessages = () => {
        switch (inDService.message) {
          case "Service created successfully":
            dispatch({ type: "clearMessage" });
            dispatch({ type: "clearErrors" });
            query.set("status", "pending");
            localStorage.setItem(`explain${serviceName}`, 1);
            window.location.href = `${location.pathname}?${query.toString()}`;
            break;
          case "Post Deleted successfully":
            dispatch({ type: "clearMessage" });
            dispatch({ type: "clearErrors" });
            toast.success("Service removed successfully!");
            setServices(services.filter((e) => e.id !== serviceIdSelect));
            setServiceIdSelect("");
            setServiceInterests("");
            setServicesCount(servicesCount - 1);
            break;
          case "Service completed successfully":
            dispatch({ type: "clearMessage" });
            dispatch({ type: "clearErrors" });
            toast.success("Service completed successfully!");
            setServices(services.filter((e) => e.id !== serviceIdSelect));
            setServiceIdSelect("");
            setServiceInterests("");
            break;
          default:
            break;
        }
      };
      handleMessages();
    };

    handleInDServiceMessages();
    setError(inDService.error);
  }, [status, inDService]);

  useEffect(() => {
    const handleInterestMessages = () => {
      const handleMessages = () => {
        switch (interest.message) {
          case "Interest Accepted Successfully":
            dispatch({ type: "clearMessage" });
            dispatch({ type: "clearErrors" });
            toast.success("Interest accepted!");
            setInterestView("");
            setServiceInterests(
              serviceInterests?.filter((e) => e.id !== interestIdSelect)
            );
            break;
          case "Successfully Removed Interest":
            dispatch({ type: "clearMessage" });
            dispatch({ type: "clearErrors" });
            toast.warn("Interest rejected!");
            setServiceInterests(
              serviceInterests?.filter((e) => e.id !== interestIdSelect)
            );
            break;
          default:
            break;
        }
      };
      handleMessages();
      setInterestError(interest.error);
    };

    handleInterestMessages();
  }, [status, interest]);

  useEffect(() => {
    setServiceInterests(othersInterests.interests);
  }, [othersInterests.interests]);

  return;
};
