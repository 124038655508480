import React, { useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import { roomStaticData } from "../../../../DataFiles/data";

export default function AirportAssistanceFilters({
  t,
  germanStates,
  service,
  stateOptions,
  state,
  setState,
  uploadDate,
  setUploadDate,
  airportOptions,
  airportName,
  setAirportName,
  arrivalDate,
  setArrivalDate,
  showSort,
  setShowSort,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const updateQueryParams = useCallback(() => {
    const params = new URLSearchParams(location.search);

    if (state) params.set("state", state);
    if (uploadDate) params.set("uploadDate", uploadDate);
    if (airportName) params.set("airportName", airportName);
    if (arrivalDate) params.set("arrivalDate", arrivalDate);

    navigate({ search: params.toString() }, { replace: true });
  }, [navigate, location.search, state, uploadDate, airportName, arrivalDate]);

  useEffect(() => {
    updateQueryParams();
  }, [state, uploadDate, airportName, arrivalDate]);

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleCheckChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleDateChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <Form className="sortingDiv">
      {/* State Sorting */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {state && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.state")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={(e) => {
            setState(e.target.value);
          }}
          value={state}
        >
          <option value="" hidden>
            {t("findWork.state")}
          </option>
          {stateOptions?.map((e, i) => (
            <option key={`state${i}`} value={e.sampleName}>
              {e.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Upload Date */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {uploadDate && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.date order")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={(e) => setUploadDate(e.target.value)}
          value={uploadDate}
        >
          <option value="" hidden>
            {t("findWork.date order")}
          </option>
          <option value="-1">{t("findWork.newest first")}</option>
          <option value="1">{t("findWork.oldest first")}</option>
        </Form.Select>
      </Form.Group>

      {/* Lease Length */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {airportOptions && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.Airport name")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={(e) => setAirportName(e.target.value)}
          value={airportName}
        >
          <option value="">-------</option>
          {airportOptions?.map((airport, idx) => (
            <option key={idx} value={airport.name}>
              {airport.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Arrival date */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        <Form.Label className="font-bold text-secondary">
          {t("findWork.Arrival date")}
        </Form.Label>
        <Form.Control
          className="shadow-none border-0 p-0"
          value={arrivalDate}
          onChange={handleDateChange(setArrivalDate)}
          type="date"
        />
      </Form.Group>
    </Form>
  );
}
