import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../utils/apiUrl";

// Fetch all webinars with pagination
export const getAllWebinars = createAsyncThunk(
  "webinars/getAllWebinars",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/webinar`, {
        withCredentials: true,
      });
      return data.allWebinar;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Create the webinar slice
const webinarSlice = createSlice({
  name: "webinars",
  initialState: {
    loading: false,
    error: null,
    allWebinar: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getAllWebinars actions
      .addCase(getAllWebinars.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allWebinar = null;
      })
      .addCase(getAllWebinars.fulfilled, (state, action) => {
        state.loading = false;
        state.allWebinar = action.payload;
      })
      .addCase(getAllWebinars.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Update webinar notification preferences
export const updateWebinarNotify = createAsyncThunk(
  "webinars/updateWebinarNotify",
  async ({ id, email }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${apiUrl}/updateNotify/${id}/${email}`,
        {
          withCredentials: true,
        }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Create the webinar slice
const webinarNotifySlice = createSlice({
  name: "webinars",
  initialState: {
    loading: false,
    error: null,
    webinarNotify: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle updateWebinarNotify actions
      .addCase(updateWebinarNotify.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.webinarNotify = null;
      })
      .addCase(updateWebinarNotify.fulfilled, (state, action) => {
        state.loading = false;
        state.webinarNotify = action.payload;
      })
      .addCase(updateWebinarNotify.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer and actions
export const webinarReducer = webinarSlice.reducer;
export const webinarNotifyReducer = webinarNotifySlice.reducer;
