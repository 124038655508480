import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { forgotPasswordChange, logoutUser } from "../../store/Actions/User";
import { useTranslation } from "react-i18next";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function NewPasswordPage() {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Selectors
  const errorElement = document.getElementById("error");

  const { updateProfile } = useSelector((state) => state);

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [updateStatus, setUpdateStatus] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password != confirmPassword) {
      return (errorElement.innerText = "Password does'nt match");
    }
    errorElement.innerText = "";
    dispatch(forgotPasswordChange({ token, password })).then(() => {
      updateStatus.error
        ? (errorElement.innerText = updateStatus.error)
        : navigate("/login/recoverySuccess");
    });
  };

  useEffect(() => {
    dispatch(logoutUser());
    setUpdateStatus(updateProfile);
  }, [updateProfile]);

  const seo = seoData.NewPasswordPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />
      <div className="flex items-center justify-center  w-[100vw] h-[100vh]">
        <Form
          className="flex flex-column items-center justify-center"
          onSubmit={handleSubmit}
        >
          <img
            className=" w-[240px] "
            src="/images/logo/SFAlargeLogo.webp"
            alt="SFA large Logo"
          />
          <Form.Group className="w-[360px] flex flex-column items-center justify-center ">
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder={t("authentication.New Password")}
              required
            />
            <Form.Control
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="my-3"
              type="text"
              placeholder={t("authentication.Confirm Password")}
              required
            />
          </Form.Group>
          <div className="text-danger my-2" id="error"></div>
          <Button className="w-[360px]" type="submit">
            {t("authentication.Submit")}
          </Button>
        </Form>
      </div>
    </>
  );
}
