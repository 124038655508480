import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import Services from "./components/Services";
import FooterTop from "../../components/common/FooterTop";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";
import { reactUrl } from "../../utils/apiUrl";

import "./HomeScreen.scss";
import SEO from "../../utils/SEO";
import seoData from "../../DataFiles/seoData";

export default function HomeScreen() {
  const { t } = useTranslation();

  const seo = seoData.HomeScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <Navigation />
      <Header t={t} />
      <div id="services">
        <Services t={t} />
      </div>
      <FooterTop top="top" bottom="bottom" />
    </>
  );
}
