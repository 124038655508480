import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Create a Direct Service
export const createDService = createAsyncThunk(
  "dService/createDService",
  async ({ file, serviceName }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/directService/${serviceName}`,
        file,
        {
          withCredentials: true,
        }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Delete a Direct Service
export const deleteDService = createAsyncThunk(
  "dService/deleteDService",
  async ({ serviceName, id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/directService/${serviceName}/${id}`,
        {
          withCredentials: true,
        }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Slice definition
const dServiceSlice = createSlice({
  name: "dService",
  initialState: {
    loading: false,
    message: null,
    error: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.message = null;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // CreateDService actions
      .addCase(createDService.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createDService.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createDService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // DeleteDService actions
      .addCase(deleteDService.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(deleteDService.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(deleteDService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Thunk for getting a direct service
export const getDService = createAsyncThunk(
  "dService/getDService",
  async ({ serviceName }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/directService/myService/${serviceName}`,
        {
          withCredentials: true,
        }
      );
      return data.service;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Slice definition
const myDServiceSlice = createSlice({
  name: "myDService",
  initialState: {
    loading: false,
    services: null,
    error: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDService.pending, (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase(getDService.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(getDService.rejected, (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      });
  },
});

// Export actions for clearing messages and errors
export const { clearMessages, clearErrors: clearDServiceErrors } =
  dServiceSlice.actions;
export const { clearErrors: clearMyDServiceErrors } = myDServiceSlice.actions;

// Export the reducers to include in your store
export const dServiceReducer = dServiceSlice.reducer;
export const myDServiceReducer = myDServiceSlice.reducer;
