export const germanStates = [
  {
    name: "Baden-Württemberg",
    sampleName: "Baden-Württemberg",
    cities: [
      "Stuttgart",
      "Mannheim",
      "Karlsruhe",
      "Freiburg im Breisgau",
      "Heidelberg",
      "Heilbronn",
      "Ulm",
    ],
    airport: [
      { name: "Stuttgart", code: "STR" },
      { name: "Karlsruhe/Baden-Baden", code: "FKB" },
      { name: "Friedrichshafen", code: "FDH" },
      { name: "Mannheim City", code: "MHG" },
      { name: "Heidelberg", code: "HDB" },
      { name: "Schwäbisch Hall", code: "EDTY" },
      { name: "Hahnweide", code: "EDST" },
      { name: "Pattonville", code: "EDTQ" },
    ],
  },
  {
    name: "Bavaria (Bayern)",
    sampleName: "Bavaria",
    cities: [
      "Munich (München)",
      "Nuremberg (Nürnberg)",
      "Augsburg",
      "Regensburg",
      "Erlangen",
      "Würzburg",
      "Passau",
    ],
    airport: [
      { name: "Munich", code: "MUC" },
      { name: "Nuremberg", code: "NUE" },
      { name: "Augsburg", code: "AGB" },
      { name: "Memmingen", code: "FMM" },
      { name: "Straubing Wallmühle", code: "RBM" },
      { name: "Oberpfaffenhofen", code: "OBF" },
      { name: "Hof–Plauen", code: "HOQ" },
      { name: "Bayreuth", code: "BYU" },
      { name: "Bamberg-Breitenau", code: "EDQA" },
      { name: "Ansbach-Petersdorf", code: "QOB" },
    ],
  },
  {
    name: "Berlin",
    sampleName: "Berlin",
    cities: ["Berlin"],
    airport: [
      { name: "Berlin Brandenburg", code: "BER" },
      { name: "Berlin Tegel", code: "TXL", status: "closed" },
      { name: "Berlin Tempelhof", code: "THF", status: "closed" },
      {
        name: "Berlin Schönefeld",
        code: "SXF",
        status: "integrated into BER",
      },
    ],
  },
  {
    name: "Brandenburg",
    sampleName: "Brandenburg",
    cities: ["Potsdam", "Cottbus", "Brandenburg an der Havel"],
    airport: [
      { name: "Berlin Brandenburg", code: "BER" },
      { name: "Neubrandenburg", code: "FNB" },
      { name: "Cottbus-Drewitz", code: "CBU" },
      { name: "Strausberg", code: "EDAY" },
      { name: "Eisenhüttenstadt", code: "EDCE" },
      { name: "Finsterwalde/Schacksdorf", code: "EDUS" },
    ],
  },
  {
    name: "Bremen",
    sampleName: "Bremen",
    cities: ["Bremen", "Bremerhaven"],
    airport: [{ name: "Bremen", code: "BRE" }],
  },
  {
    name: "Hamburg",
    sampleName: "Hamburg",
    cities: ["Hamburg"],
    airport: [
      { name: "Hamburg", code: "HAM" },
      { name: "Hamburg Finkenwerder", code: "XFW" },
    ],
  },
  {
    name: "Hesse (Hessen)",
    sampleName: "Hesse",
    cities: [
      "Frankfurt",
      "Wiesbaden",
      "Darmstadt",
      "Kassel",
      "Marburg",
      "Giessen",
      "Fulda",
    ],
    airport: [
      { name: "Frankfurt", code: "FRA" },
      { name: "Frankfurt-Hahn", code: "HHN" },
      { name: "Kassel", code: "KSF" },
      { name: "Egelsbach", code: "QEF" },
      { name: "Fritzlar Air Base", code: "FRZ" },
    ],
  },
  {
    name: "Lower Saxony (Niedersachsen)",
    sampleName: "Lower Saxony",
    cities: [
      "Hanover (Hannover)",
      "Brunswick (Braunschweig)",
      "Osnabrück",
      "Oldenburg",
      "Göttingen",
      "Wolfsburg",
      "Lüneburg",
    ],
    airport: [
      { name: "Hannover", code: "HAJ" },
      { name: "Braunschweig-Wolfsburg", code: "BWE" },
      { name: "Emden", code: "EME" },
      { name: "Wilhelmshaven-Mariensiel", code: "WVN" },
      { name: "Langeoog", code: "LGO" },
      { name: "Borkum Airfield", code: "BMK" },
      { name: "Norderney", code: "NRD" },
      { name: "Wangerooge", code: "AGE" },
      { name: "Cuxhaven/Nordholz", code: "FCN" },
      { name: "Leer-Papenburg", code: "ZOW" },
    ],
  },
  {
    name: "Mecklenburg-Vorpommern",
    sampleName: "Mecklenburg-Vorpommern",
    cities: ["Schwerin", "Rostock", "Greifswald"],
    airport: [
      { name: "Rostock-Laage", code: "RLG" },
      { name: "Heringsdorf", code: "HDF" },
      { name: "Neubrandenburg", code: "FNB" },
      { name: "Anklam", code: "ANK" },
    ],
  },
  {
    name: "North Rhine-Westphalia (Nordrhein-Westfalen)",
    sampleName: "North Rhine-Westphalia",
    cities: [
      "Cologne (Köln)",
      "Düsseldorf",
      "Dortmund",
      "Essen",
      "Bonn",
      "Aachen",
      "Münster",
    ],
    airport: [
      { name: "Düsseldorf", code: "DUS" },
      { name: "Cologne Bonn", code: "CGN" },
      { name: "Dortmund", code: "DTM" },
      { name: "Münster Osnabrück International", code: "FMO" },
      { name: "Paderborn Lippstadt", code: "PAD" },
      { name: "Weeze", code: "NRN" },
      { name: "Bielefeld", code: "BFE" },
      { name: "Mönchengladbach", code: "MGL" },
      { name: "Essen/Mülheim", code: "ESS" },
      { name: "Aachen Merzbrück", code: "AAH" },
      { name: "Siegerland", code: "SGE" },
      { name: "Borkenberge", code: "EDLB" },
    ],
  },
  {
    name: "Rhineland-Palatinate (Rheinland-Pfalz)",
    sampleName: "Rhineland-Palatinate",
    cities: ["Mainz", "Ludwigshafen", "Koblenz", "Trier"],
    airport: [
      { name: "Frankfurt-Hahn", code: "HHN" },
      { name: "Zweibrücken", code: "ZQW" },
      { name: "Speyer", code: "SPY" },
      { name: "Mainz Finthen", code: "QFZ" },
      { name: "Koblenz-Winningen", code: "ZNV" },
    ],
  },
  {
    name: "Saarland",
    sampleName: "Saarland",
    cities: ["Saarbrücken", "Neunkirchen", "Homburg"],
    airport: [
      { name: "Saarbrücken", code: "SCN" },
      { name: "Saarlouis-Düren", code: "EDRJ" },
    ],
  },
  {
    name: "Saxony (Sachsen)",
    sampleName: "Saxony",
    cities: [
      "Dresden",
      "Leipzig",
      "Chemnitz",
      "Zwickau",
      "Freiberg",
      "Görlitz",
      "Plauen",
    ],
    airport: [
      { name: "Dresden", code: "DRS" },
      { name: "Leipzig/Halle", code: "LEJ" },
      { name: "Altenburg-Nobitz", code: "AOC" },
      { name: "Zwickau", code: "EDBI" },
    ],
  },
  {
    name: "Saxony-Anhalt (Sachsen-Anhalt)",
    sampleName: "Saxony-Anhalt",
    cities: ["Magdeburg", "Halle (Saale)", "Dessau", "Halberstadt"],
    airport: [
      { name: "Magdeburg-Cochstedt", code: "CSO" },
      { name: "Magdeburg", code: "ZMG" },
      { name: "Stendal-Borstel", code: "ZSN" },
    ],
  },
  {
    name: "Schleswig-Holstein",
    sampleName: "Schleswig-Holstein",
    cities: ["Kiel", "Lübeck", "Flensburg"],
    airport: [
      { name: "Lübeck", code: "LBC" },
      { name: "Kiel-Holtenau", code: "KEL" },
      { name: "Flensburg-Schaferhaus", code: "FLF" },
      { name: "Westerland/Sylt", code: "GWT" },
      { name: "Wyk auf Föhr", code: "OHR" },
      { name: "St. Peter-Ording", code: "PSH" },
    ],
  },
  {
    name: "Thuringia (Thüringen)",
    sampleName: "Thuringia",
    cities: ["Erfurt", "Jena", "Weimar", "Gera", "Ilmenau", "Eisenach"],
    airport: [
      { name: "Erfurt-Weimar", code: "ERF" },
      { name: "Altenburg-Nobitz", code: "AOC" },
      { name: "Nordhausen", code: "ZNN" },
    ],
  },
];

export const germanCities = [
  "Stuttgart",
  "Mannheim",
  "Karlsruhe",
  "Freiburg im Breisgau",
  "Heidelberg",
  "Heilbronn",
  "Ulm",
  "Munich (München)",
  "Nuremberg (Nürnberg)",
  "Augsburg",
  "Regensburg",
  "Erlangen",
  "Würzburg",
  "Passau",
  "Berlin",
  "Potsdam",
  "Cottbus",
  "Brandenburg an der Havel",
  "Bremen",
  "Bremerhaven",
  "Hamburg",
  "Frankfurt",
  "Wiesbaden",
  "Darmstadt",
  "Kassel",
  "Marburg",
  "Giessen",
  "Fulda",
  "Hanover (Hannover)",
  "Brunswick (Braunschweig)",
  "Osnabrück",
  "Oldenburg",
  "Göttingen",
  "Wolfsburg",
  "Lüneburg",
  "Schwerin",
  "Rostock",
  "Greifswald",
  "Cologne (Köln)",
  "Düsseldorf",
  "Dortmund",
  "Essen",
  "Bonn",
  "Aachen",
  "Münster",
  "Mainz",
  "Ludwigshafen",
  "Koblenz",
  "Trier",
  "Saarbrücken",
  "Neunkirchen",
  "Homburg",
  "Dresden",
  "Leipzig",
  "Chemnitz",
  "Zwickau",
  "Freiberg",
  "Görlitz",
  "Plauen",
  "Magdeburg",
  "Halle (Saale)",
  "Dessau",
  "Halberstadt",
  "Kiel",
  "Lübeck",
  "Flensburg",
  "Erfurt",
  "Jena",
  "Weimar",
  "Gera",
  "Ilmenau",
  "Eisenach",
];

export const studyCountriesLanguages = [
  { name: "English", code: "en" },
  { name: "German", code: "de" },
  { name: "French", code: "fr" },
];

export const allCountries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (formerly Swaziland)",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
