import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import "./SignUpScreen.scss";
import { useDispatch, useSelector } from "react-redux";
import { germanStates } from "../../DataFiles/countries";
import { loadUser, registerUser } from "../../store/Actions/User";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { reactUrl } from "../../utils/apiUrl";

import { useEffectSignUp, useSignUpHandler } from "./useSignUpHandler";
import { TiTick } from "react-icons/ti";
import { IoIosArrowRoundBack } from "react-icons/io";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function SignUpScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  //All useStates
  const [accepted, setAccepted] = useState(false);
  const [name, setName] = useState(query.get("name") || "");
  const [email, setEmail] = useState(query.get("email") || "");
  const [number, setNumber] = useState("");
  const [gender, setGender] = useState("");
  const [preferredState, setPreferredState] = useState("");
  const [password, setPassword] = useState(query.get("password") || "");

  //Datas
  const file = {
    name,
    email,
    number,
    gender,
    preferredState,
    password,
  };
  //Selectors
  const submitBtn = document.getElementById("submit_btn");
  const user = useSelector((state) => state.user);

  const [error, setError] = useState("");

  //functions
  let { signUpHandler, handlePhoneNumberChange } = useSignUpHandler({
    submitBtn,
    password,
    setError,
    dispatch,
    registerUser,
    file,
    user,
    setNumber,
  });

  $(".option_choose").on("click", () => $(".def_Option ml-2").hide());

  // useEffect
  useEffectSignUp({ dispatch, loadUser, location, user, navigate, setError });

  const seo = seoData.SignUpScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <IoIosArrowRoundBack
        onClick={() => navigate(-1)}
        className="absolute top-0 left-0 text-[40px] mx-2 my-1"
      />

      <div className="registercont ">
        <Col data-content={`signuppage`} className="registerconthead1">
          <img
            className="w-[150px]"
            src="/images/logo/SFAmediumLogo.webp"
            alt="SFA medium Logo"
          />
          <div className="bg-[#ffffffd5] rounded text-secondary p-2 m-2">
            <h5 className="text-center font-bold text-black">
              {t("authentication.On registering, you can")}
            </h5>
            <p className="flex items-center">
              <TiTick className="bg-[green] rounded-circle mr-2 text-white text-[18px]" />
              {t(
                "authentication.Build your profile and find services like room, airport assistance,..."
              )}
            </p>
            <p className="flex items-center">
              <TiTick className="bg-[green] rounded-circle mr-2 text-white text-[18px]" />
              {t(
                "authentication.Get services interest from other users right after created service request"
              )}
            </p>
            <p className="flex items-center">
              <TiTick className="bg-[green] rounded-circle mr-2 text-white text-[18px]" />
              {t(
                "authentication.Help others and earn money by responding there service requests"
              )}
            </p>
          </div>
        </Col>
        <Col className="h-[100vh] overflow-y-scroll">
          <Form onSubmit={signUpHandler} className="registercontform">
            <div className="flex flex-column items-center justify-center text-center pb-2">
              <img
                className="w-[240px] pb-2"
                src="/images/logo/SFAlargeLogo.webp"
                alt="SFA large Logo"
              />
              <h5 className="font-bold">
                {t("authentication.Create your Stayforall profile")}
              </h5>
              <p className="text-start text-secondary">
                {t(
                  "authentication.Create services and send response to services from germany's No.1 service provider"
                )}
              </p>
            </div>

            <p className="setP text-[#0CAFFF] text-[25px] mt-3">
              {t("authentication.Create your Stayforall profile")}
            </p>
            {!query.get("name") && (
              <Form.Group className="my-3">
                <Form.Label className="font-bold text-[14px] m-0 p-0">
                  {t("authentication.Full name")}
                  <span className="text-[red]">*</span>
                </Form.Label>
                <div className="border rounded-[15px] px-2 py-1">
                  <Form.Control
                    style={{ border: "none", boxShadow: "none" }}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type="text"
                    id="name"
                    name="name"
                    placeholder={t("authentication.What is your name?")}
                    required
                  />
                </div>
              </Form.Group>
            )}
            {!query.get("email") && (
              <Form.Group className="my-3">
                <Form.Label className="font-bold text-[14px] m-0 p-0">
                  {t("authentication.Email ID")}
                  <span className="text-[red]">*</span>
                </Form.Label>
                <div className="border rounded-[15px] px-2 py-1">
                  <Form.Control
                    style={{ border: "none", boxShadow: "none" }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    id="email"
                    name="email"
                    placeholder={t("authentication.Tell us your Email ID")}
                    required
                  />
                </div>
                <p className="text-[14px] text-secondary mt-1">
                  {t(
                    "authentication.We'll send relevant notifications and updates to this email"
                  )}
                </p>
              </Form.Group>
            )}

            {!query.get("password") && (
              <Form.Group className="my-3">
                <Form.Label className="font-bold text-[14px] m-0 p-0">
                  {t("authentication.Password")}
                  <span className="text-[red]">*</span>
                </Form.Label>
                <div className="border rounded-[15px] px-2 py-1">
                  <Form.Control
                    style={{ border: "none", boxShadow: "none" }}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type="password"
                    id="password"
                    name="password"
                    placeholder={t("authentication.(Minimum 7 characters)")}
                    required
                  />
                </div>
                <p className="text-[14px] text-secondary mt-1">
                  {t("authentication.This helps your account stay protected")}
                </p>
              </Form.Group>
            )}

            <Form.Group className="my-3">
              <Form.Label className="font-bold text-[14px] m-0 p-0">
                {t("authentication.Gender")}
                <span className="text-[red]">*</span>
              </Form.Label>
              <div className="border rounded-[15px] px-2 py-1">
                {["male", "female", "others"].map((e, i) => (
                  <Form.Check
                    key={`gender${i}`}
                    inline
                    label={
                      e == "male" ? (
                        <div className="flex items-center">
                          <img
                            src="/images/icons/signUp/male.webp"
                            alt="male"
                            className="w-[15px] h-[15px] mx-2"
                          />
                          {t("authentication.Male")}
                        </div>
                      ) : e == "female" ? (
                        <div className="flex items-center">
                          <img
                            src="/images/icons/signUp/female.webp"
                            alt="male"
                            className="w-[16px] h-[16px] mx-2"
                          />
                          {t("authentication.Female")}
                        </div>
                      ) : (
                        t("authentication.Others")
                      )
                    }
                    value={e}
                    name="group0"
                    type="radio"
                    id={`inline-radio-group0-${i}`}
                    onChange={() => setGender(e)}
                    checked={gender === e}
                    className="mx-3"
                  />
                ))}
              </div>
            </Form.Group>

            <Form.Group className="my-3">
              <Form.Label className="font-bold text-[14px] m-0 p-0">
                {t("authentication.Mobile number")}
                <span className="text-[red]">*</span>
              </Form.Label>
              <div className="border rounded-[12px] px-2 py-1">
                <PhoneInput
                  country={"de"} // Default country
                  value={number}
                  onChange={handlePhoneNumberChange}
                  placeholder={t("authentication.Enter your mobile number")}
                  inputStyle={{ width: "100%", border: "none" }} // Custom styles for the input field
                />
              </div>
              <p className="text-[14px] text-secondary mt-1">
                {t("authentication.Stayforall will contact you on this number")}
              </p>
            </Form.Group>

            <Form.Group className="my-2">
              <Form.Label className="font-bold text-[14px] m-0 p-0">
                {t("authentication.State")}
                <span className="text-[red]">*</span>
              </Form.Label>
              <div className="border rounded-[15px] px-2 py-1">
                <Form.Select
                  style={{ border: "none", boxShadow: "none" }}
                  className="option_choose"
                  onChange={(e) => {
                    setPreferredState(e.target.value);
                  }}
                  value={preferredState}
                  aria-label="select state"
                  required
                >
                  <option className="def_Option" id="state" value="" hidden>
                    {t("authentication.Select your state")}
                  </option>
                  {germanStates.map((e, i) => (
                    <option value={e.sampleName} key={`preferredState${i}`}>
                      {e.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <p className="text-[14px] text-secondary mt-1">
                {t(
                  "authentication.This helps to create services and sort services created"
                )}
              </p>
            </Form.Group>

            <Form.Group className=" items-center justify-start">
              <Form.Label className="d-flex ml-2 text-secondary text-[15px]">
                <Form.Check
                  className="mr-2"
                  type="checkbox"
                  checked={accepted}
                  onChange={(e) => setAccepted(e.target.checked)}
                />
                {t("authentication.I have read and accept the")}{" "}
                <a className="text-black" href="/privacy-policy"></a>
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline mx-1 text-[#4486dd]"
                >
                  {" "}
                  {t("authentication.Privacy Policy")}
                </Link>{" "}
                {t("authentication.and")}
                <Link
                  to="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline ml-1 text-[#4486dd]"
                >
                  {t("authentication.Terms and Conditions")}
                </Link>
              </Form.Label>
            </Form.Group>
            <div className="text-danger" id="error">
              {error}
            </div>
            <Button
              disabled={!accepted ? true : false}
              id="submit_btn"
              type="submit"
              style={{ background: "#0CAFFF", width: "100%" }}
              className=" my-3 border-light text-white"
            >
              {t("authentication.Create Free account")}
            </Button>
            <p className="text-[15px] text-secondary">
              {t("authentication.already existing customer")}{" "}
              <a aria-label="Login" href="/login" style={{ color: "#0CAFFF" }}>
                {t("authentication.login here")}
              </a>
            </p>
          </Form>
        </Col>
      </div>
    </>
  );
}
