import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Form,
  InputGroup,
  Container,
  ListGroup,
  Pagination,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";

function UserList() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [filters, setFilters] = useState({
    country: "",
    visaType: "",
    state: "",
    isVerified: "",
    gender: "",
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/admin/user`, {
          params: { page, limit, ...filters, search },
        });
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [page, limit, filters, search]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <Container className="my-4">
      <h2 className="text-center mb-4">User List</h2>

      {/* Search and Filters */}
      <Row className="mb-4">
        <Col md={6}>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Search users..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button variant="primary" onClick={() => setPage(1)}>
              Search
            </Button>
          </InputGroup>
        </Col>

        <Col md={6}>
          <Row>
            <Col>
              <Form.Control
                placeholder="Country"
                name="country"
                value={filters.country}
                onChange={handleFilterChange}
              />
            </Col>
            <Col>
              <Form.Control
                placeholder="Visa Type"
                name="visaType"
                value={filters.visaType}
                onChange={handleFilterChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Loading Spinner */}
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <ListGroup>
          {users.length > 0 ? (
            users.map((user) => (
              <ListGroup.Item
                key={user.id}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <strong>{user.name}</strong> <br />
                  <span className="text-muted">{user.email}</span>
                </div>
                <Button
                  variant="link"
                  href={`/admin/user/${user.id}`}
                  className="text-decoration-none"
                >
                  View Profile
                </Button>
              </ListGroup.Item>
            ))
          ) : (
            <p className="text-center text-muted">No users found.</p>
          )}
        </ListGroup>
      )}

      {/* Pagination */}
      <Pagination className="justify-content-center mt-4">
        <Pagination.Prev
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Previous
        </Pagination.Prev>
        <Pagination.Item active>{page}</Pagination.Item>
        <Pagination.Next onClick={() => setPage(page + 1)}>
          Next
        </Pagination.Next>
      </Pagination>
    </Container>
  );
}

export default UserList;
