import React, { useState, useCallback, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { TiTickOutline } from "react-icons/ti";
import axios from "axios"; // Assuming you're using axios for API requests
import {
  getDocumentStatus,
  uploadDocument,
} from "../../../../store/Actions/User";
import { useSelector } from "react-redux";
import { MdOutlinePendingActions } from "react-icons/md";

export default function DocumentUpload({ dispatch, t }) {
  const [selectedDocument, setSelectedDocument] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [documentStatus, setDocumentStatus] = useState("");

  const { loadDocumentVerify } = useSelector((state) => ({
    loadDocumentVerify: state.loadDocumentVerify,
  }));

  const handleSubmitVerification = async (event) => {
    event.preventDefault();

    if (!selectedDocument || !uploadedFile) {
      alert(t("profilePage.Please select a document type"));
      return;
    }

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("type", selectedDocument);
      formData.append("document", uploadedFile.file);

      dispatch(uploadDocument(formData));

      // alert(response.data.message);
      setUploadedFile(null);
      setSelectedDocument("");
    } catch (error) {
      alert(t("profilePage.There was an error uploading"));
    } finally {
      setUploading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (
      acceptedFiles.length > 0 &&
      acceptedFiles[0].type === "application/pdf"
    ) {
      const file = acceptedFiles[0];
      const fileURL = URL.createObjectURL(file);
      setUploadedFile({ file, fileURL });
    } else {
      alert("profilePage.Only PDF files are accepted");
    }
  }, []);

  const removeUploadedFile = () => {
    if (uploadedFile) {
      URL.revokeObjectURL(uploadedFile.fileURL);
      setUploadedFile(null);
    }
  };

  const documentOptions = [
    {
      value: "passport",
      label: t("profilePage.Passport"),
      description: t(
        "profilePage.Upload your passport as proof of identification"
      ),
    },
    {
      value: "national-id",
      label: t("profilePage.National ID Card"),
      description: t(
        "profilePage.Upload your National ID Card as proof of identity"
      ),
    },
    {
      value: "drivers-license",
      label: t("profilePage.Driver’s License"),
      description: t(
        "profilePage.Upload your Driver’s License for verification purposes"
      ),
    },
    {
      value: "residence-permit",
      label: t("profilePage.Residence Permit"),
      description: t(
        "profilePage.Upload your Residence Permit to prove your residency status"
      ),
    },
  ];

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: false,
  });

  const selectedOption = documentOptions.find(
    (option) => option.value === selectedDocument
  );

  useEffect(() => {
    dispatch(getDocumentStatus());
  }, []);

  useEffect(() => {
    setDocumentStatus(loadDocumentVerify);
  }, [loadDocumentVerify]);

  return (
    <>
      {documentStatus.document === null && (
        <section className="bg-white my-4 border rounded-2xl p-3">
          <div>
            <Form onSubmit={handleSubmitVerification}>
              <div className="font-bold flex justify-between">
                <h6 className="font-bold">
                  {t("profilePage.Complete Verification Process")}
                </h6>
                {uploadedFile && (
                  <button
                    type="submit"
                    className="flex items-center cursor-pointer hover:scale-110 border rounded px-3 py-2 text-white bg-[#0CAFFF] shadow ml-2"
                    disabled={uploading}
                  >
                    <TiTickOutline className="mr-1" />
                    {uploading
                      ? `${t("profilePage.Uploading")}...`
                      : t("profilePage.Submit")}
                  </button>
                )}
              </div>
              <p>{t("profilePage.Submit your document for verification")}</p>
              <Form.Group>
                {documentOptions.map((option) => (
                  <div className="border rounded p-2 my-3" key={option.value}>
                    <Form.Check
                      type="radio"
                      id={option.value}
                      label={
                        <>
                          <strong>{option.label}</strong>
                          <p className="text-sm">{option.description}</p>
                        </>
                      }
                      name="documentType"
                      value={option.value}
                      checked={selectedDocument === option.value}
                      onChange={(e) => {
                        setSelectedDocument(e.target.value);
                        removeUploadedFile();
                      }}
                      className="mb-3"
                    />
                    {selectedDocument === option.value && (
                      <Form.Group
                        controlId={`form${option.value}`}
                        className="mb-3"
                      >
                        <Form.Label className="block mb-2">
                          {t("profilePage.Upload")} {option.label} (
                          {t("profilePage.PDF only")})
                        </Form.Label>
                        {!uploadedFile ? (
                          <div
                            {...getRootProps()}
                            className="border-dashed border-2 border-gray-300 p-4 mb-2 text-center cursor-pointer"
                          >
                            <input {...getInputProps()} />
                            <Button variant="primary" className="mb-2">
                              {t("profilePage.Upload File (PDF)")}
                            </Button>
                            <p>{t("profilePage.or drag it here")}</p>
                          </div>
                        ) : (
                          <div className="mb-4">
                            <p>
                              {t("profilePage.File uploaded successfully")}.{" "}
                              <a
                                href={uploadedFile.fileURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("profilePage.View File")}
                              </a>
                            </p>
                            <Button
                              variant="danger"
                              className="mt-2"
                              onClick={removeUploadedFile}
                            >
                              {t("profilePage.Remove File")}
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    )}
                  </div>
                ))}
              </Form.Group>
            </Form>
          </div>
        </section>
      )}

      {documentStatus.document === 0 && (
        <section className="bg-gradient-to-r from-[#0CAFFF] to-[grey] text-white font-bold flex items-center my-4 border rounded-2xl p-3">
          <MdOutlinePendingActions className="mr-2" />{" "}
          {t("profilePage.Document is under verification")}
        </section>
      )}
    </>
  );
}
