import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";
import SocialMedia from "../../components/common/SocialMedia";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";

import { reactUrl } from "../../utils/apiUrl";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function AboutScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const features = [
    {
      head: t("infoPage.No membership fee"),
      body: t(
        "infoPage.Stayforall Community will remain free for Students and Universities"
      ),
      image: "/images/aboutPage/svgs/no-fee.svg",
    },
    {
      head: `1,00,000+ ${t("infoPage.Members")}`,
      body: t("infoPage.300+ volunteers from 20+ countries"),
      image: "/images/aboutPage/svgs/members.svg",
    },
    {
      head: `100,000+ ${t("infoPage.reach")}`,
      body: t(
        "infoPage.Monthly through all our channels like Website, Social Media and Newsletter"
      ),
      image: "/images/aboutPage/svgs/invitations.svg",
    },
    {
      head: `700+ ${t("infoPage.Buddies")}`,
      body: t(
        "infoPage.Matched through the Stayforall Programs in the last year"
      ),
      image: "/images/aboutPage/svgs/mentors.svg",
    },
    {
      head: t("infoPage.Yearly scholarships"),
      body: t(
        "infoPage.Sign up for our newsletter to get notified about the next round"
      ),
      image: "/images/aboutPage/svgs/scholarships.svg",
    },
    {
      head: t("infoPage.1k+ Blog articles"),
      body: t("infoPage.with up-to-date information, written by our blog team"),
      image: "/images/aboutPage/svgs/whitepaper.svg",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const seo = seoData.AboutScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />
      <Navigation />
      <Container className="pb-[120px] md:my-5 sm:mt-1">
        <Row className="flex justify-center items-center lg:h-90vh">
          <Col md={12} lg={3}>
            <h2 className="my-6">{t("infoPage.About us")}</h2>
            <h5 className="tracking-tight mb-10 text-lightBlack">
              {t("infoPage.With our diverse team of experts, we have")}
            </h5>
          </Col>
          <Col>
            <LazyLoad height={200} offset={100} once>
              <img
                className="md:w-full lg:w-9/10"
                src="/images/aboutPage/aboutus.webp"
                alt="about us"
              />
            </LazyLoad>
            <div className="hoverCommunity">
              <h5>{t("infoPage.Why We Do This")}</h5>
              <p>
                {t("infoPage.We want to simplify your journey")}
                <span
                  onClick={() => navigate("/register")}
                  className="text-danger"
                >
                  {t("infoPage.Join Us")}
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="text-center mb-6">
          <h3 className="md:w-4/5 sm:w-full mx-auto">
            {t(
              "infoPage.FOSTERING CONNECTIONS BETWEEN INDIVIDUALS AND FACILITATING MUTUAL ASSISTANCE"
            )}
          </h3>
          <p className="text-lightBlack text-base my-3">
            {t("infoPage.Finding new friends in a new country")}
          </p>
        </div>
        <div className="flex justify-center">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-4 my-4 rounded border shadow-lg">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex items-center p-2 rounded shadow-sm"
              >
                <LazyLoad height={140} offset={100} once>
                  <img
                    className="w-35 h-35"
                    src={feature.image}
                    alt={feature.head}
                  />
                </LazyLoad>
                <div className="w-full text-center">
                  <h5 className="font-bold">{feature.head}</h5>
                  <p className="text-secondary text-sm">{feature.body}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <SocialMedia t={t} className="mb-3" />
      <FooterTop top="top" bottom="bottom" />
    </>
  );
}
