import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import "./ChatPage.scss";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
import {
  authReceiver,
  getMessages,
  sendMessage,
} from "../../store/Actions/chat";
import MessageInput from "./components/MessageInput";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../store/Actions/User";
import Message from "./components/Message";
import NewChat from "./components/NewChat";
import Loading from "../../components/samplePage/Loading";
import { IoArrowBack } from "react-icons/io5";
import { IoWarningOutline } from "react-icons/io5";
import { IoTimerOutline } from "react-icons/io5";
import { socketUrl } from "../../utils/apiUrl";
import { useTranslation } from "react-i18next";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function ChatPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { service, interestId, receiverId } = useParams();

  const lastMessageRef = useRef();

  // selectors
  const getChatSender = useSelector((state) => state.userInfo);
  const getChatReceiver = useSelector((state) => state.getChatReceiver);
  const getAllMessages = useSelector((state) => state.getMessages);
  const sendMessageStatus = useSelector((state) => state.sendMessage);

  // useState
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [sender, setSender] = useState({});
  const [receiver, setReceiver] = useState({});
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [messageStatus, setMessageStatus] = useState(true);
  const [message, setMessage] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  // functions
  const handleSendMessage = useCallback(
    (message) => {
      dispatch(sendMessage({ message, service, receiverId }));
    },
    [dispatch, service, receiverId]
  );

  const handleEmojiSelect = useCallback((emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
    setShowPicker(false);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setShowPicker(false);
      if (!message) return;

      socket?.emit("chatroomMessage", conversationId, {
        receiverId: receiverId,
        message: message,
        timestamp: Date.now(),
      });
      handleSendMessage(message);

      setMessage("");
    },
    [message, conversationId, receiverId, socket, handleSendMessage]
  );

  // Receiver useEffects
  useEffect(() => {
    dispatch(loadUser());
    dispatch(authReceiver(receiverId));
  }, [receiverId, dispatch]);

  useEffect(() => {
    setReceiver(getChatReceiver.receiver);
    setSender(getChatSender.user);
  }, [getChatReceiver.receiver, getChatSender.user]);

  // Messages useEffect
  useEffect(() => {
    dispatch(getMessages({ receiverId, service, interestId }));
  }, [receiverId, dispatch, service, interestId]);

  useEffect(() => {
    setMessages(getAllMessages.messages?.messages);
    setConversationId(getAllMessages.messages?.conversationId);
  }, [getAllMessages?.messages]);

  // send Message useEffect
  useEffect(() => {
    setMessageStatus(sendMessageStatus.status);
  }, [sendMessageStatus.status]);

  // scroll message
  useLayoutEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // socket useEffect
  useEffect(() => {
    const socket = io(socketUrl, {
      secure: process.env.NODE_ENV === "production",
    });
    setSocket(socket);

    socket.emit("joinRoom", conversationId);

    return () => {
      socket.disconnect();
    };
  }, [conversationId]);

  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    };

    socket.on("newMessage", handleNewMessage);

    return () => {
      socket.off("newMessage", handleNewMessage);
    };
  }, [socket]);

  const seo = seoData.ChatPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <div className="flex flex-column h-[100vh] overflow-hidden bg-[#2e2e2e]">
        {getAllMessages.error ? (
          <div className="w-full text-[#ffffff]">
            <div className="flex flex-column items-center justify-center gap-2 px-4 font-semibold">
              <h4>{t("chatPage.Welcome 👋 to SFA Chat❄")}</h4>
              <p>{getAllMessages.error}</p>
              <IoWarningOutline className="text-3xl md:text-6xl text-center" />
            </div>
          </div>
        ) : (
          <>
            {/* Header */}
            <div className="flex">
              <div className="flex items-center py-[6px]">
                <IoArrowBack
                  onClick={() =>
                    navigate(`/inDirectServicePage/${service}?status=pending`)
                  }
                  className="text-[24px] ml-2 text-[#ffffff]"
                />
                <img
                  alt="Profile"
                  className="rounded-circle w-[42px] h-[42px] mr-3 ml-[30px]"
                  src={receiver?.profileImage}
                />
                <p className="text-[22px] text-[#ffffff] pt-2">
                  {receiver?.name}
                </p>
              </div>
            </div>

            {/* messages */}
            <div className="px-4 flex-1 overflow-auto">
              {messages?.length > 15 && (
                <div className="flex justify-center text-[13px]">
                  <span className="flex items-center border p-1 rounded-[10px]">
                    <IoTimerOutline className="mr-2" />
                    {t("chatPage.Older messages automatically removed")}
                  </span>
                </div>
              )}
              {messages?.map((message, index) => (
                <div key={message?._id} ref={lastMessageRef}>
                  <Message
                    t={t}
                    message={message}
                    receiverId={receiverId}
                    read={message.read}
                    showStatusIcon={index === messages.length - 1}
                  />
                </div>
              ))}

              {getAllMessages.loading && (
                <Loading type="colone" color="#36d7b7" size={20} />
              )}
              {!getAllMessages.loading && messages?.length === 0 && (
                <p className="text-center mt-[30%]">
                  <NewChat t={t} />
                </p>
              )}
            </div>

            {/* input */}
            <MessageInput
              t={t}
              sendMessageStatus={sendMessageStatus}
              handleSendMessage={handleSendMessage}
              message={message}
              setMessage={setMessage}
              showPicker={showPicker}
              setShowPicker={setShowPicker}
              handleEmojiSelect={handleEmojiSelect}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </div>
    </>
  );
}
