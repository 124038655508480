import { useNavigate } from "react-router-dom";

export const useDServiceFunctions = ({
  setError,
  dispatch,
  createDService,
  deleteDService,
  item,
  services,
  dService,
  directCategories,
}) => {
  const navigate = useNavigate();

  // Submit Forms of Direct Service
  const formSubmit = (file) => {
    const hasEmptyValue = Object.values(file).some((value) => value === "");

    if (hasEmptyValue) {
      setError("Enter full details");
    } else {
      dispatch(createDService({ file, serviceName: item }));
    }
  };

  // Remove Direct Service
  const handleRemove = () => {
    if (services?.services?.id) {
      dispatch(
        deleteDService({
          serviceName: item,
          id: services.services.id,
        })
      );
    } else {
      setError("Service ID not found.");
    }
  };

  // Navigate to the next direct service category
  const handleNext = () => {
    const currentIndex = directCategories.findIndex((e) => e.name === item);
    const nextIndex = (currentIndex + 1) % directCategories.length;

    navigate(
      `/directServicePage/${directCategories[nextIndex].name}?step=status`
    );
  };

  return {
    formSubmit,
    handleRemove,
    handleNext,
  };
};
