import { Button, Container } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import CSS for react-confirm-alert
import { PiWarningOctagonBold } from "react-icons/pi";
import { ImSpinner9 } from "react-icons/im";

export default function Status({ t, item, handleRemove, error, loading }) {
  const handleDeleteConfirmation = () => {
    confirmAlert({
      title: t("directServicePage.Confirm to Delete"),
      message: `${t("directServicePage.Are you sure to delete the")} ${item}?`,
      buttons: [
        {
          label: t("directServicePage.Yes"),
          onClick: handleRemove,
        },
        {
          label: t("directServicePage.No"),
        },
      ],
    });
  };

  return (
    <Container fluid className="shadow rounded my-20 py-5 bg-warning">
      <div className="flex items-start">
        <img
          className="statusImg"
          src="/images/directPage/status.webp"
          alt="Service Status"
        />
        <div className="statusDiv ml-5">
          <h3 className="font-bold text-danger">
            {t("directServicePage.Services Successfully Completed")}
          </h3>
          <h5 className="font-bold mb-3">
            {t("directServicePage.Congratulations")}! 🎉
          </h5>
          <div className="text-gray-600 max-w-[1200px]">
            <p>
              {t(
                "directServicePage.You have successfully completed the necessary"
              )}
            </p>
            <p className="mb-3">
              {t("directServicePage.Thank you for choosing our service")}
            </p>
          </div>
          <Button
            onClick={handleDeleteConfirmation}
            className="d-flex items-center border-0 px-5 py-2 text-white rounded hover:shadow-lg"
            style={{ fontSize: "20px", backgroundColor: "rgb(133, 9, 9)" }}
            disabled={loading}
            aria-disabled={loading}
          >
            {loading && (
              <ImSpinner9 className="animate-spin mr-2" aria-hidden="true" />
            )}
            {t("directServicePage.Start Over")}
          </Button>
          {error && (
            <div className="col-span-2 text-danger my-2" id="error">
              <div className="flex justify-center items-center text-danger">
                <PiWarningOctagonBold className="mr-2 text-[25px]" />
                <span>{error}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
