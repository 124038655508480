import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Navigation from "../../../components/Navigation/Navigation";
import FooterTop from "../../../components/common/FooterTop";
import "../Social.scss";
import { useDispatch, useSelector } from "react-redux";
import { MdDateRange } from "react-icons/md";
import {
  getAllWebinars,
  updateWebinarNotify,
} from "../../../store/Actions/Webinar";
import { GiButterfly } from "react-icons/gi";
import { ImCancelCircle } from "react-icons/im";
import Loading from "../../../components/samplePage/Loading";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";

export default function WebinarScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allWebinar, loading } = useSelector((state) => state.allWebinar);

  const [email, setEmail] = useState("");
  const [notifyId, setNotifyId] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getAllWebinars());
  }, [dispatch]);

  const handleNotify = (id) => {
    setNotifyId(id);
  };

  const notifySubmit = (e) => {
    e.preventDefault();
    if (notifyId) {
      dispatch(updateWebinarNotify({ id: notifyId, email }));
      setNotifyId(null);
      setEmail("");
      alert(t("infoPage.Notification setup successfully"));
    }
  };

  const seo = seoData.WebinarPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <Navigation />

      {notifyId && (
        <div className="popup-view">
          <div className="webinarNotifyPopView">
            <div className="flex flex-column justify-center items-center">
              <LazyLoad height={200} offset={100} once>
                <img
                  src="/images/logo/SFAmediumLogo.webp"
                  className="w-[100px]"
                  alt="SFA LOGO"
                />
              </LazyLoad>
              <h3 className="font-bold">{t("infoPage.Notify Now")}</h3>
              <p className="text-secondary">
                {t(
                  "infoPage.You will get a mail contains the link to live webinar section"
                )}
              </p>
              <Form className="flex" onSubmit={notifySubmit}>
                <Form.Group>
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    placeholder={t("infoPage.Enter your email id")}
                  />
                </Form.Group>
                <Button className="ml-2" type="submit">
                  {t("infoPage.Notify Now")}
                </Button>
              </Form>
            </div>
            <div onClick={() => setNotifyId(null)} className="closeWebinarBtn">
              <ImCancelCircle className="hover:scale-105" />
            </div>
          </div>
        </div>
      )}

      <section className="min-h-[60vh] border-top border-bottom py-2">
        <div className="text-center mt-5 mb-2">
          <h1 className="flex items-center justify-center my-2">
            {t("infoPage.The Webinar")} <GiButterfly className="ml-2" />
          </h1>
          <h6>{t("infoPage.Informative webinars covering various")}</h6>
        </div>

        <div className="min-h-[60vh]">
          {loading ? (
            <Loading type="servicePage" color="#36d7b7" size={120} />
          ) : (
            allWebinar?.map((webinar, index) => (
              <Container fluid key={index} className="bg-grey mb-5 mt-5">
                <Row className="firstRowWebinar">
                  <Col md={4}>
                    <LazyLoad height={200} offset={100} once>
                      <img
                        className="px-3 img-fluid"
                        src={webinar.image}
                        alt={`Webinar ${index}`}
                      />
                    </LazyLoad>
                  </Col>
                  <Col md={8} className="text-black">
                    <p className="mt-2">
                      {t("infoPage.WEBINARS")} {` > `} {webinar.category}
                    </p>
                    <p>
                      <span className="bg-warning p-2">
                        {t("infoPage.UPCOMING LIVE")}
                      </span>
                      <span className="bg-orange p-2">{webinar.date}</span>
                    </p>
                    <h3 className="mt-2 font-bold">{webinar.caption}</h3>
                    <div className="d-flex align-items-start m-4">
                      <LazyLoad height={40} offset={100} once>
                        <img
                          className="w-[40px] rounded-circle mr-3"
                          src="/images/commonitems/avatar.webp"
                          alt="user"
                        />
                      </LazyLoad>
                      <p>
                        {webinar.userName}
                        <br />
                        {webinar.userQualification}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between align-items-center bg-light p-2 rounded my-3">
                  <p className="d-flex align-items-center mb-0">
                    <MdDateRange className="mr-2" /> {webinar.date}
                  </p>
                  <Button
                    id={`button-${webinar._id}`}
                    onClick={() => handleNotify(webinar._id)}
                  >
                    {t("infoPage.Notify Me")}
                  </Button>
                </div>
                <div className="p-2">
                  <h3>{t("infoPage.Details")}</h3>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(webinar?.details),
                    }}
                  />
                </div>
              </Container>
            ))
          )}
        </div>
      </section>

      <FooterTop bottom="bottom" />
    </>
  );
}
