import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function Error404Page() {
  const seo = seoData.Error404Page;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      {/* Navigation */}
      <Navigation />
      <div className="oops h-[80vh] p-3 flex flex-column items-center justify-center">
        <h1>Oops</h1>
        <h3>404 - PAGE NOT FOUND</h3>
        <p className="text-center">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
      </div>
      {/* footer */}
      <FooterTop top="top" bottom="bottom" />
    </>
  );
}
