import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import "./policies.scss";

import { reactUrl } from "../../utils/apiUrl";
import Navigation from "../../components/Navigation/Navigation";
import { CiSquareInfo } from "react-icons/ci";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  const seo = seoData.PrivacyPolicy;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <Navigation />

      <div className="privacyPolicyContainer">
        <h1 className="flex items-center font-bold mt-3 mb-6">
          Privacy Policy
          <CiSquareInfo className="ml-2" />
        </h1>
        <article>
          <section>
            {/*PRIVACY POLICY  */}
            <h5>Last updated April 26, 2024</h5>
            <p>
              This privacy notice for stayforall ('we', 'us', or 'our'),
              describes how and why we might collect, store, use, and/or share
              ('process') your information when you use our services
              ('Services'), such as when you:
            </p>
            <ul>
              <li>
                &#x2022; Visit our website at{" "}
                <a className="text-black" href="https://stayforall.com">
                  https://stayforall.com
                </a>{" "}
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                &#x2022; Engage with us in other related ways, including any
                sales, marketing, or events
              </li>
            </ul>
            <p>
              <span>Questions or concerns? </span>Reading this privacy notice
              will help you understand your privacy rights and choices. If you
              do not agree with our policies and practices, please do not use
              our Services. If you still have any questions or concerns, please
              contact us at stayforall.info@gmail.com.
            </p>
          </section>

          {/* SUMMARY OF KEY POINTS */}
          <section>
            <h4 className="font-bold">SUMMARY OF KEY POINTS</h4>
            <h6 className="font-bold">
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our{" "}
              <a className="text-black" href="#tableOfContents">
                table of contents
              </a>{" "}
              below to find the section you are looking for.
            </h6>
            <p>
              <span>What personal information do we process?</span>
              <br />
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use. Learn more about{" "}
              <a
                className="text-black"
                href="#personal-information-you-disclose-to-us"
              >
                personal information you disclose to us.
              </a>
            </p>
            <p>
              <span>Do we process any sensitive personal information?</span>
              <br />
              We do not process sensitive personal information.
            </p>
            <p>
              <span>Do we receive any information from third parties?</span>
              <br />
              We do not receive any information from third parties.
            </p>
            <p>
              <span>How do we process your information?</span>
              <br />
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so. Learn
              more about{" "}
              <a
                className="text-black"
                href="#HOW-DO-WE-PROCESS-YOUR-INFORMATION"
              >
                how we process your information.
              </a>
            </p>
            <p>
              <span>
                In what situations and with which parties do we share personal
                information?
              </span>
              <br />
              We may share information in specific situations and with specific
              third parties. Learn more about{" "}
              <a
                className="text-black"
                href="#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION"
              >
                when and with whom we share your personal information.
              </a>
            </p>
            <p>
              <span>How do we keep your information safe?</span>
              <br />
              We have organisational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Learn more about{" "}
              <a
                className="text-black"
                href="#HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE"
              >
                how we keep your information safe.
              </a>
            </p>
            <p>
              <span>What are your rights?</span>
              <br />
              Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your
              personal information. Learn more about{" "}
              <a className="text-black" href="#WHAT-ARE-YOUR-PRIVACY-RIGHTS">
                your privacy rights.
              </a>
            </p>
            <p>
              <span>How do you exercise your rights?</span>
              <br />
              The easiest way to exercise your rights is by visiting
              <a
                className="text-black"
                href="https://stayforall.com/myProfile/profile"
              >
                https://stayforall.com/myProfile/profile
              </a>{" "}
              , or by contacting us. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>
            <p>
              Want to learn more about what we do with any information we
              collect?
              <br />
              <a
                className="text-black"
                href="#Review-the-privacy-notice-in-full"
              >
                {" "}
                Review the privacy notice in full.
              </a>
            </p>
          </section>

          {/* TABLE OF CONTENTS */}
          <section>
            <h3 id="tableOfContents">TABLE OF CONTENTS</h3>
            <ul>
              <a className="text-black" href="#WHAT-INFORMATION-DO-WE-COLLECT">
                1. WHAT INFORMATION DO WE COLLECT?
              </a>
              <a
                className="text-black"
                href="#HOW-DO-WE-PROCESS-YOUR-INFORMATION"
              >
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </a>
              <a
                className="text-black"
                href="#WHAT-LEGAL-BASES-DO-WE-RELY-ON-TO-PROCESS-YOUR-PERSONAL-INFORMATION"
              >
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </a>
              <a
                className="text-black"
                href="#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION"
              >
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
              <a
                className="text-black"
                href="#DO-WE-USE-COOKIES-AND-OTHER-TRACKING-TECHNOLOGIES"
              >
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
              <a
                className="text-black"
                href="#HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS"
              >
                6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
              <a
                className="text-black"
                href="#HOW-LONG-DO-WE-KEEP-YOUR-INFORMATION"
              >
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </a>
              <a
                className="text-black"
                href="#HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE"
              >
                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </a>
              <a
                className="text-black"
                href="#DO-WE-COLLECT-INFORMATION-FROM-MINORS"
              >
                9. DO WE COLLECT INFORMATION FROM MINORS?
              </a>
              <a className="text-black" href="#WHAT-ARE-YOUR-PRIVACY-RIGHTS">
                10. WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
              <a
                className="text-black"
                href="#CONTROLS-FOR-DO-NOT-TRACK-FEATURES"
              >
                11. CONTROLS FOR DO-NOT-TRACK FEATURES
              </a>
              <a
                className="text-black"
                href="#DO-UNITED-STATES-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS"
              >
                12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
              <a
                className="text-black"
                href="#DO-OTHER-REGIONS-HAVE-SPECIFIC-PRIVACY-RIGHTS"
              >
                13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
              <a
                className="text-black"
                href="#DO-WE-MAKE-UPDATES-TO-THIS-NOTICE"
              >
                14. DO WE MAKE UPDATES TO THIS NOTICE?
              </a>
              <a
                className="text-black"
                href="#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE"
              >
                15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              <a
                className="text-black"
                href="#HOW-CAN-YOU-REVIEW,-UPDATE,-OR-DELETE-THE-DATA-WE-COLLECT-FROM-YOU"
              >
                16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </a>
            </ul>
          </section>
        </article>

        {/* Table Detailed article */}
        <article>
          {/* Number 1 */}
          <section id="Review-the-privacy-notice-in-full">
            <h3 id="WHAT-INFORMATION-DO-WE-COLLECT">
              1. WHAT INFORMATION DO WE COLLECT?
            </h3>
            <h5 id="personal-information-you-disclose-to-us">
              Personal information you disclose to us
            </h5>
            <p>
              <span>In Short:</span> We collect personal information that you
              provide to us.
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <p>
              <span>Personal Information Provided by You.</span> The personal
              information that we collect depends on the context of your
              interactions with us and the Services, the choices you make, and
              the products and features you use. The personal information we
              collect may include the following:
            </p>
            <ul>
              {[
                "Name",
                "Email address",
                "Phone number",
                "Gender",
                "Profile image",
              ].map((e) => (
                <li>&#x2022; {e}</li>
              ))}
            </ul>
            <p>
              <span>Contact Information Provided by You.</span> The contact
              information that we collect depends on the context of your
              interactions with us and the Services, the choices you make, and
              the products and features you use. The contact information we
              collect may include the following:
            </p>
            <ul>
              {["Address", "City", "State", "Postal/Zip code"].map((e) => (
                <li>&#x2022; {e}</li>
              ))}
            </ul>
            <p>
              <span>
                Educational and Professional Information Provided by You.
              </span>{" "}
              The educational and professional information that we collect
              depends on the context of your interactions with us and the
              Services, the choices you make, and the products and features you
              use. The educational and professional information we collect may
              include the following:
            </p>
            <ul>
              {[
                "Educational history",
                "Work experience",
                "Skills and expertise",
              ].map((e) => (
                <li>&#x2022; {e}</li>
              ))}
            </ul>
            <h5>Information Collected for Direct Services</h5>
            <p>
              We also collect additional information when you use our direct
              services, which include the following:
            </p>
            <ul>
              <li>
                <strong>Blocked Account Service:</strong>
                <ul>
                  <li>Full Name</li>
                  <li>Date of Birth</li>
                  <li>Gender</li>
                  <li>Nationality</li>
                  <li>Purpose of Visit</li>
                  <li>Name of Institution</li>
                  <li>Course</li>
                  <li>Expected Duration</li>
                  <li>Proof of Financial Ability</li>
                  <li>Source of Funding</li>
                  <li>Payment</li>
                  <li>Mode of Payment</li>
                </ul>
              </li>
              <li>
                <strong>Admission Assistance Service:</strong>
                <ul>
                  <li>Full Name</li>
                  <li>Date of Birth</li>
                  <li>Gender</li>
                  <li>Nationality</li>
                  <li>Highest Qualification</li>
                  <li>CGPA</li>
                  <li>Course</li>
                  <li>Course Duration</li>
                  <li>Preferred University</li>
                  <li>Preferred Course</li>
                </ul>
              </li>
              <li>
                <strong>City Registration Service:</strong>
                <ul>
                  <li>Full Name</li>
                  <li>Date of Birth</li>
                  <li>Gender</li>
                  <li>Nationality</li>
                  <li>Purpose of Visit</li>
                  <li>Name of Institution</li>
                  <li>Course</li>
                  <li>Expected Duration</li>
                  <li>Residence Permit</li>
                  <li>Local Registration</li>
                  <li>Health Insurance</li>
                  <li>Tax Registration</li>
                  <li>Bank Account</li>
                </ul>
              </li>
              <li>
                <strong>Health Insurance Service:</strong>
                <ul>
                  <li>Full Name</li>
                  <li>Date of Birth</li>
                  <li>Gender</li>
                  <li>Nationality</li>
                  <li>Purpose of Visit</li>
                  <li>Name of Institution</li>
                  <li>Course</li>
                  <li>Expected Duration</li>
                  <li>Insurance Type</li>
                  <li>Insurance Duration</li>
                  <li>Insurance Range</li>
                  <li>Source of Funding</li>
                  <li>Mode of Payment</li>
                </ul>
              </li>
            </ul>
            <h5>Information Collected for Indirect Services</h5>
            <p>
              We also collect additional information when you use our indirect
              services, which include the following:
            </p>
            <ul>
              <li>
                <strong>Room Rentals Service:</strong>
                <ul>
                  <li>State</li>
                  <li>City</li>
                  <li>Area Code</li>
                  <li>Move-in Date</li>
                  <li>Rent</li>
                  <li>Security Deposit</li>
                  <li>Property Type</li>
                  <li>Number of Bedrooms</li>
                  <li>Mode of Payment</li>
                  <li>Length of Lease</li>
                  <li>Room Type</li>
                  <li>Utilities</li>
                  <li>Amenities</li>
                  <li>Preferred Contact</li>
                </ul>
              </li>
              <li>
                <strong>Airport Assistance Service:</strong>
                <ul>
                  <li>State</li>
                  <li>City</li>
                  <li>Arrival Date</li>
                  <li>Arrival Time</li>
                  <li>Flight Number</li>
                  <li>Airline</li>
                  <li>Airport Name</li>
                  <li>Terminal</li>
                  <li>Specific Location</li>
                  <li>Drop-Off Location</li>
                  <li>Number of Passengers</li>
                  <li>Requirements</li>
                  <li>Additional Services</li>
                  <li>Payment</li>
                  <li>Mode of Payment</li>
                  <li>Preferred Contact</li>
                </ul>
              </li>
              <li>
                <strong>Find Skill Service:</strong>
                <ul>
                  <li>State</li>
                  <li>City</li>
                  <li>Timeline</li>
                  <li>Skill Details</li>
                  <li>Payment</li>
                  <li>Preferred Contact</li>
                </ul>
              </li>
              <li>
                <strong>Join a Group Service:</strong>
                <ul>
                  <li>State</li>
                  <li>City</li>
                  <li>Event Name</li>
                  <li>Event Type</li>
                  <li>Date</li>
                  <li>Location</li>
                  <li>Member Count</li>
                  <li>Preferred Contact</li>
                </ul>
              </li>
            </ul>

            <h5>Information Collected for Service Interests</h5>
            <p>
              Additionally, when you express interest in any service, we collect
              certain information related to your interests, including:
            </p>
            <ul>
              <li>
                <strong>Room Interest:</strong>
                <ul>
                  <li>Rent Response</li>
                  <li>Security Deposit Response</li>
                  <li>Service Fee Response</li>
                  <li>Images</li>
                  <li>Details Response</li>
                  <li>Status</li>
                </ul>
              </li>
              <li>
                <strong>Airport Assistance Interest:</strong>
                <ul>
                  <li>Primary Number</li>
                  <li>Secondary Number</li>
                  <li>Service Fee Response</li>
                  <li>Details Response</li>
                  <li>Status</li>
                </ul>
              </li>
              <li>
                <strong>Find Skill Interest:</strong>
                <ul>
                  <li>Skills Response</li>
                  <li>Payment Response</li>
                  <li>Details Response</li>
                  <li>Status</li>
                </ul>
              </li>
              <li>
                <strong>Join a Group Interest:</strong>
                <ul>
                  <li>Member Type Response</li>
                  <li>Contact Info</li>
                  <li>Details Response</li>
                  <li>Status</li>
                </ul>
              </li>
            </ul>
            <ul></ul>
            <p>
              <span>Transaction Information Provided by You.</span> The
              transaction information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The transaction information
              we collect may include the following:
            </p>
            <ul>
              {["Payment details", "Billing and shipping address"].map((e) => (
                <li>&#x2022; {e}</li>
              ))}
            </ul>
            <p>
              <span>
                Please note that we may collect additional information as
                necessary for the operation of our services.
              </span>
            </p>
            <p>
              <span>Sensitive Information.</span> We do not process sensitive
              information.
            </p>
            <p>
              <span>Social Media Login Data.</span> We may provide you with the
              option to register with us using your existing social media
              account details, like your Facebook, Twitter, or other social
              media account. If you choose to register in this way, we will
              collect the information described in the section called{" "}
              <a
                className="text-black"
                href="#HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS"
              >
                'HOW DO WE HANDLE YOUR SOCIAL LOGINS?'
              </a>{" "}
              below.
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>

            <h4>Information automatically collected</h4>
            <p>
              <span>In Short:</span> Some information — such as your Internet
              Protocol (IP) address and/or browser and device characteristics —
              is collected automatically when you visit our Services.
            </p>
            <p>
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <p>The information we collect includes:</p>
            <ul>
              <li>
                &#x2022; Log and Usage Data. Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called 'crash dumps'), and hardware
                settings).
              </li>
              <li>
                &#x2022; Device Data. We collect device data such as information
                about your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system, and system
                configuration information.
              </li>
              <li>
                &#x2022; Location Data. We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Services. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. However, if you choose to opt out, you may not be able
                to use certain aspects of the Services.
              </li>
            </ul>
          </section>

          {/* Number 2 */}
          <section>
            <h3 id="HOW-DO-WE-PROCESS-YOUR-INFORMATION">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </h3>
            <p>
              <span>In Short:</span> We process your information to provide,
              improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also
              process your information for other purposes with your consent.
            </p>
            <p>
              <span>User Registration: </span>When you register on our platform,
              we collect and process the information you provide, such as your
              name, email address, phone number, and any other details required
              for account creation. This information is necessary for creating
              and managing your account, providing personalized services, and
              facilitating communication between users.
            </p>
            <p>
              <span>inDirect Service Requests: </span>When you create requests
              for room rentals, airport assistance, finding skills, or joining
              groups, we collect and process the information you provide in
              these requests. This includes details such as your preferences,
              requirements, and any additional information necessary for
              fulfilling your request. We use this information to match you with
              suitable providers or events and facilitate communication between
              parties involved.
            </p>
            <p>
              <span>Direct Service Requests: </span>When you create requests for
              blocked accounts, admission assistance, city registration, or
              health insurance, we collect and process the following
              information:
            </p>
            <ul>
              <li>
                Your personal information such as name, date of birth, gender,
                and nationality
              </li>
              <li>
                Educational background including your highest qualification,
                course details, and duration
              </li>
              <li>
                Travel details such as purpose of visit, institution name,
                expected duration, and proof of financial ability
              </li>
              <li>
                Additional information required for processing your request
              </li>
            </ul>
            <p>
              We may outsource the processing of this information to trusted
              third-party service providers who assist us in delivering the
              requested services. These service providers are contractually
              obligated to handle your information securely and confidentially
              and are only permitted to use it for the specific purposes
              outlined in our agreements with them.
            </p>

            <p>
              <span>Provider Interests: </span>When service providers express
              interest in fulfilling your requests, we collect and process the
              information they provide, such as their contact details and any
              additional information relevant to their services. This
              information is used to facilitate communication between you and
              the providers and to assist you in selecting the most suitable
              option.
            </p>
            <p>
              <span>Communication: </span>We process information related to your
              communication activities on our platform, including messages sent
              and received, to facilitate communication between users and ensure
              a smooth interaction experience. This information may be used for
              customer support purposes and to improve our services.
            </p>
            <p>
              <span>Event Attendance: </span>If you express interest in
              attending events created by other users, we collect and process
              the information necessary for event coordination, such as your
              attendance status and any additional details provided by the event
              coordinator. This information is used to facilitate event
              organization and communication among attendees.
            </p>
            <p>
              <span>Account Management: </span>We process your information for
              account management purposes, including account verification,
              authentication, and security measures. This helps us ensure the
              integrity and security of our platform and protect your account
              from unauthorized access or misuse.
            </p>
            <p>
              <span>Legal Compliance: </span>We may process your information to
              comply with legal obligations, respond to legal requests or
              orders, enforce our terms of service, or protect our rights,
              property, or safety, as required by applicable laws and
              regulations.
            </p>
            <p>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </p>
            <ul>
              <li>
                &#x2022;
                <span>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.
                </span>{" "}
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
              </li>
              <li>
                &#x2022;
                <span>
                  To deliver and facilitate delivery of services to the user.
                </span>{" "}
                We may process your information to provide you with the
                requested service.
              </li>
              <li>
                &#x2022;
                <span>
                  To respond to user inquiries/offer support to users.
                </span>{" "}
                We may process your information to respond to your inquiries and
                solve any potential issues you might have with the requested
                service.
              </li>
              <li>
                &#x2022;
                <span>To send administrative information to you.</span> We may
                process your information to send you details about our products
                and services, changes to our terms and policies, and other
                similar information.
              </li>
              <li>
                &#x2022;
                <span>To fulfil and manage your orders.</span> We may process
                your information to fulfil and manage your orders, payments,
                returns, and exchanges made through the Services.
              </li>
              <li>
                &#x2022;
                <span>To enable user-to-user communications.</span> We may
                process your information if you choose to use any of our
                offerings that allow for communication with another user.
              </li>
              <li>
                &#x2022;
                <span>
                  To save or protect an individual's vital interest.
                </span>{" "}
                We may process your information when necessary to save or
                protect an individual’s vital interest, such as to prevent harm.
              </li>
            </ul>
          </section>

          {/* Number 3 */}
          <section>
            <h3 id="WHAT-LEGAL-BASES-DO-WE-RELY-ON-TO-PROCESS-YOUR-PERSONAL-INFORMATION">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h3>
            <p>
              <span>In Short:</span> We only process your personal information
              when we believe it is necessary and we have a valid legal reason
              (i.e. legal basis) to do so under applicable law, like with your
              consent, to comply with laws, to provide you with services to
              enter into or fulfil our contractual obligations, to protect your
              rights, or to fulfil our legitimate business interests.
            </p>
            <p>
              If you are located in the EU or UK, this section applies to you.
            </p>
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </p>
            <ul>
              <li>
                &#x2022;
                <span>Consent.</span> We may process your information if you
                have given us permission (i.e. consent) to use your personal
                information for a specific purpose. You can withdraw your
                consent at any time. Learn more about{" "}
                <a className="text-black" href="#withdrawing-your-consent">
                  withdrawing your consent.
                </a>
              </li>
              <li>
                &#x2022;
                <span>Performance of a Contract.</span> We may process your
                personal information when we believe it is necessary to fulfil
                our contractual obligations to you, including providing our
                Services or at your request prior to entering into a contract
                with you.
              </li>
              <li>
                &#x2022;
                <span>Legal Obligations.</span> We may process your information
                where we believe it is necessary for compliance with our legal
                obligations, such as to cooperate with a law enforcement body or
                regulatory agency, exercise or defend our legal rights, or
                disclose your information as evidence in litigation in which we
                are involved.
              </li>
              <li>
                &#x2022;
                <span>Vital Interests.</span> We may process your information
                where we believe it is necessary to protect your vital interests
                or the vital interests of a third party, such as situations
                involving potential threats to the safety of any person.
              </li>
            </ul>
            <p>If you are located in Canada, this section applies to you.</p>
            <p>
              We may process your information if you have given us specific
              permission (i.e. express consent) to use your personal information
              for a specific purpose, or in situations where your permission can
              be inferred (i.e. implied consent). You can{" "}
              <a className="text-black" href="#withdrawing-your-consent">
                withdraw your consent
              </a>{" "}
              at any time.
            </p>
            <p>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <ul>
              <li>
                &#x2022; If collection is clearly in the interests of an
                individual and consent cannot be obtained in a timely way
              </li>
              <li>
                &#x2022; For investigations and fraud detection and prevention
              </li>
              <li>
                &#x2022; For business transactions provided certain conditions
                are met
              </li>
              <li>
                &#x2022; If it is contained in a witness statement and the
                collection is necessary to assess, process, or settle an
                insurance claim
              </li>
              <li>
                &#x2022; For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>
              <li>
                &#x2022; If we have reasonable grounds to believe an individual
                has been, is, or may be victim of financial abuse
              </li>
              <li>
                &#x2022; If it is reasonable to expect collection and use with
                consent would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>
              <li>
                &#x2022; If disclosure is required to comply with a subpoena,
                warrant, court order, or rules of the court relating to the
                production of records
              </li>
              <li>
                &#x2022; If it was produced by an individual in the course of
                their employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>
              <li>
                &#x2022; If the collection is solely for journalistic, artistic,
                or literary purposes
              </li>
              <li>
                &#x2022; If the information is publicly available and is
                specified by the regulations
              </li>
            </ul>
          </section>

          {/* Number 4 */}
          <section>
            <h3 id="WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h3>
            <p>
              <span>In Short:</span> We may share information in specific
              situations described in this section and/or with the following
              third parties
            </p>
            <p>
              We may need to share your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                &#x2022;
                <span>Business Transfers.</span> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
              <li>
                &#x2022;
                <span>When we use Google Maps Platform APIs.</span> We may share
                your information with certain Google Maps Platform APIs (e.g.
                Google Maps API, Places API). We obtain and store on your device
                ('cache') your location for six (6) months. You may revoke your
                consent anytime by contacting us at the contact details provided
                at the end of this document.
              </li>
              <li>
                &#x2022;
                <span>Other Users.</span> When you share personal information
                (for example, by posting comments, contributions, or other
                content to the Services) or otherwise interact with public areas
                of the Services, such personal information may be viewed by all
                users and may be publicly made available outside the Services in
                perpetuity. If you interact with other users of our Services and
                register for our Services through a social network (such as
                Facebook), your contacts on the social network will see your
                name, profile photo, and descriptions of your activity.
                Similarly, other users will be able to view descriptions of your
                activity, communicate with you within our Services, and view
                your profile.
              </li>
            </ul>
          </section>

          {/* Number 5 */}
          <section>
            <h3 id="DO-WE-USE-COOKIES-AND-OTHER-TRACKING-TECHNOLOGIES">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h3>
            <p>
              <span>In Short:</span> We may use cookies and other tracking
              technologies to collect and store your information.
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </section>

          {/* Number 6 */}
          <section>
            <h3 id="HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS">
              6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </h3>
            <p>
              <span>In Short:</span> If you choose to register or log in to our
              Services using a social media account, we may have access to
              certain information about you.
            </p>
            <p>
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </p>
            <p>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </p>
          </section>

          {/* Number 7 */}
          <section>
            <h3 id="HOW-LONG-DO-WE-KEEP-YOUR-INFORMATION">
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h3>
            <p>
              <span>In Short:</span> We keep your information for as long as
              necessary to fulfil the purposes outlined in this privacy notice
              unless otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymise such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </section>

          {/* Number 8 */}
          <section>
            <h3 id="HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE">
              8. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h3>
            <p>
              <span>In Short:</span> We aim to protect your personal information
              through a system of organisational and technical security
              measures.
            </p>
            <p>
              We have implemented appropriate and reasonable technical and
              organisational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </section>

          {/* Number 9 */}
          <section>
            <h3 id="DO-WE-COLLECT-INFORMATION-FROM-MINORS">
              9. DO WE COLLECT INFORMATION FROM MINORS?
            </h3>
            <p>
              <span>In Short:</span> We do not knowingly collect data from or
              market to children under 18 years of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at
              stayforall.info@gmail.com.
            </p>
          </section>

          {/* Number 10 */}
          <section>
            <h3 id="WHAT-ARE-YOUR-PRIVACY-RIGHTS">
              10. WHAT ARE YOUR PRIVACY RIGHTS?
            </h3>
            <p>
              <span>In Short:</span> In some regions, such as the European
              Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada,
              you have rights that allow you greater access to and control over
              your personal information. You may review, change, or terminate
              your account at any time.
            </p>
            <p>
              In some regions (like the EEA, UK, Switzerland, and Canada), you
              have certain rights under applicable data protection laws. These
              may include the right (i) to request access and obtain a copy of
              your personal information, (ii) to request rectification or
              erasure; (iii) to restrict the processing of your personal
              information; (iv) if applicable, to data portability; and (v) not
              to be subject to automated decision-making. In certain
              circumstances, you may also have the right to object to the
              processing of your personal information. You can make such a
              request by contacting us by using the contact details provided in
              the section{" "}
              <a
                className="text-black"
                href="#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE"
              >
                'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
              </a>{" "}
              below.
            </p>
            <p>
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <p>
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your{" "}
              <a
                target="_blank"
                href="https://ec.europa.eu/newsroom/article29/items/612080"
              >
                Member State data protection authority
              </a>{" "}
              or{" "}
              <a
                target="_blank"
                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
              >
                UK data protection authority.
              </a>
            </p>
            <p>
              If you are located in Switzerland, you may contact the{" "}
              <a
                target="_blank"
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
              >
                Federal Data Protection and Information Commissioner.
              </a>
            </p>
            <p id="withdrawing-your-consent">
              <span>Withdrawing your consent:</span> If we are relying on your
              consent to process your personal information, which may be express
              and/or implied consent depending on the applicable law, you have
              the right to withdraw your consent at any time. You can withdraw
              your consent at any time by contacting us by using the contact
              details provided in the section{" "}
              <a
                className="text-black"
                href="#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE"
              >
                'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
              </a>{" "}
              below or updating your preferences.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <h4>Account Information</h4>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li>
                &#x2022; Log in to your account settings and update your user
                account.
              </li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>
            <p>
              <span>Cookies and similar technologies:</span> Most Web browsers
              are set to accept cookies by default. If you prefer, you can
              usually choose to set your browser to remove cookies and to reject
              cookies. If you choose to remove cookies or reject cookies, this
              could affect certain features or services of our Services.
            </p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at stayforall.info@gmail.com.
            </p>
          </section>

          {/* Number 11 */}
          <section>
            <h3 id="CONTROLS-FOR-DO-NOT-TRACK-FEATURES">
              11. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h3>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ('DNT') feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognising and
              implementing DNT signals has been finalised. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
          </section>

          {/* Number 12 */}
          <section>
            <h3 id="DO-UNITED-STATES-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS">
              12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h3>
            <p>
              <span>In Short:</span> If you are a resident of California,
              Colorado, Connecticut, Utah or Virginia, you are granted specific
              rights regarding access to your personal information.
            </p>
            <p>What categories of personal information do we collect?</p>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <Table striped="columns">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                  <th>Collected</th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    Category: "A. Identifiers",
                    Examples:
                      "Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name",
                    Collected: "YES",
                  },
                  {
                    Category:
                      "B. Personal information as defined in the California Customer Records statute",
                    Examples:
                      "Name, contact information, education, employment, employment history, and financial information",
                    Collected: "YES",
                  },
                  {
                    Category:
                      "C. Protected classification characteristics under state or federal law",
                    Examples: "Gender and date of birth",
                    Collected: "YES",
                  },
                  {
                    Category: "D. Commercial information",
                    Examples:
                      "Transaction information, purchase history, financial details, and payment information",
                    Collected: "YES",
                  },
                  {
                    Category: "E. Biometric information",
                    Examples: "Fingerprints and voiceprints",
                    Collected: "NO",
                  },
                  {
                    Category: "F. Internet or other similar network activity",
                    Examples:
                      "Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements",
                    Collected: "YES",
                  },
                  {
                    Category: "G. Geolocation data",
                    Examples: "Device location",
                    Collected: "YES",
                  },
                  {
                    Category:
                      "H. Audio, electronic, visual, thermal, olfactory, or similar information",
                    Examples:
                      "Images and audio, video or call recordings created in connection with our business activities",
                    Collected: "NO",
                  },
                  {
                    Category:
                      "I. Professional or employment-related information",
                    Examples:
                      "Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us",
                    Collected: "YES",
                  },
                  {
                    Category: "J. Education Information",
                    Examples: "Student records and directory information",
                    Collected: "YES",
                  },
                  {
                    Category:
                      "K. Inferences drawn from collected personal information",
                    Examples:
                      "Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics",
                    Collected: "NO",
                  },
                  {
                    Category: "L. Sensitive personal Information",
                    Examples: "",
                    Collected: "NO",
                  },
                ].map((e) => (
                  <tr>
                    <td>{e.Category}</td>
                    <td>{e.Examples}</td>
                    <td>{e.Collected}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <p>
              We will use and retain the collected personal information as
              needed to provide the Services or for:
            </p>
            <ul>
              <li>
                &#x2022; Category A - As long as the user has an account with us
              </li>
              <li>
                &#x2022; Category B - As long as the user has an account with us
              </li>
              <li>
                &#x2022; Category C - As long as the user has an account with us
              </li>
              <li>
                &#x2022; Category D - As long as the user has an account with us
              </li>
              <li>
                &#x2022; Category F - As long as the user has an account with us
              </li>
              <li>
                &#x2022; Category G - As long as the user has an account with us
              </li>
              <li>
                &#x2022; Category I - As long as the user has an account with us
              </li>
              <li>
                &#x2022; Category J - As long as the user has an account with us
              </li>
            </ul>
            <p>
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul>
              <li>
                &#x2022; Receiving help through our customer support channels;
              </li>
              <li>
                &#x2022; Participation in customer surveys or contests; and
              </li>
              <li>
                &#x2022; Facilitation in the delivery of our Services and to
                respond to your inquiries.
              </li>
            </ul>
            <p>How do we use and share your personal information?</p>
            <p>
              Learn about how we use your personal information in the section,
              <a
                className="text-black"
                href="#HOW-DO-WE-PROCESS-YOUR-INFORMATION"
              >
                'HOW DO WE PROCESS YOUR INFORMATION?'
              </a>{" "}
            </p>
            <p>Will your information be shared with anyone else?</p>
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Learn more about how we disclose personal
              information to in the section,
              <a
                className="text-black"
                href="#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION"
              >
                'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'
              </a>{" "}
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be 'selling' of your personal information.
            </p>
            <p>
              We have not disclosed, sold, or shared any personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months. We will not sell or share personal
              information in the future belonging to website visitors, users,
              and other consumers.
            </p>
            <h3>California Residents</h3>
            <p>
              California Civil Code Section 1798.83, also known as the 'Shine
              The Light' law permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from all our systems
              (e.g. backups, etc.).
            </p>
            <h5>CCPA Privacy Notice</h5>
            <p>
              This section applies only to California residents. Under the
              California Consumer Privacy Act (CCPA), you have the rights listed
              below.
            </p>
            <p>The California Code of Regulations defines a 'residents' as:</p>
            <p>
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </p>
            <p>
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </p>
            <p>All other individuals are defined as 'non-residents'.</p>
            <p>
              If this definition of 'resident' applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <p className="font-bold">
              Your rights with respect to your personal data
            </p>
            <p className="underline">
              Right to request deletion of the data — Request to delete
            </p>
            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </p>

            <p className="underline">Right to be informed — Request to know</p>
            <p>Depending on the circumstances, you have a right to know:</p>
            <ul>
              <li>
                &#x2022; whether we collect and use your personal information;
              </li>
              <li>
                &#x2022; the categories of personal information that we collect;
              </li>
              <li>
                &#x2022; the purposes for which the collected personal
                information is used;
              </li>
              <li>
                &#x2022; whether we sell or share personal information to third
                parties;
              </li>
              <li>
                &#x2022; the categories of personal information that we sold,
                shared, or disclosed for a business purpose;
              </li>
              <li>
                &#x2022; the categories of third parties to whom the personal
                information was sold, shared, or disclosed for a business
                purpose;
              </li>
              <li>
                &#x2022; the business or commercial purpose for collecting,
                selling, or sharing personal information; and
              </li>
              <li>
                &#x2022; the specific pieces of personal information we
                collected about you.
              </li>
            </ul>
            <p>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>
            <p className="underline">
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </p>
            <p>
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>
            <p className="underline">
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </p>
            <p>We do not process consumer's sensitive personal information.</p>
            <p className="underline">Verification process</p>
            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
            <p className="underline">Other privacy rights</p>
            <ul>
              <li>
                &#x2022; You may object to the processing of your personal
                information.
              </li>
              <li>
                &#x2022; You may request correction of your personal data if it
                is incorrect or no longer relevant, or ask to restrict the
                processing of the information.
              </li>
              <li>
                &#x2022; You can designate an authorised agent to make a request
                under the CCPA on your behalf. We may deny a request from an
                authorised agent that does not submit proof that they have been
                validly authorised to act on your behalf in accordance with the
                CCPA.
              </li>
              <li>
                &#x2022; You may request to opt out from future selling or
                sharing of your personal information to third parties. Upon
                receiving an opt-out request, we will act upon the request as
                soon as feasibly possible, but no later than fifteen (15) days
                from the date of the request submission.
              </li>
            </ul>
            <p>
              To exercise these rights, you can contact us by visiting{" "}
              <a
                target="_blank"
                href="https://stayforall.com/myProfile/profile"
              >
                https://stayforall.com/myProfile/profile
              </a>
              , by email at stayforall.info@gmail.com, or by referring to the
              contact details at the bottom of this document. If you have a
              complaint about how we handle your data, we would like to hear
              from you.
            </p>
            <h3>Colorado Residents</h3>
            <p>
              This section applies only to Colorado residents. Under the
              Colorado Privacy Act (CPA), you have the rights listed below.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law.
            </p>
            <ul>
              <li>
                &#x2022; Right to be informed whether or not we are processing
                your personal data
              </li>
              <li>&#x2022; Right to access your personal data</li>
              <li>
                &#x2022; Right to correct inaccuracies in your personal data
              </li>
              <li>&#x2022; Right to request deletion of your personal data</li>
              <li>
                &#x2022; Right to obtain a copy of the personal data you
                previously shared with us
              </li>
              <li>
                &#x2022; Right to opt out of the processing of your personal
                data if it is used for targeted advertising, the sale of
                personal data, or profiling in furtherance of decisions that
                produce legal or similarly significant effects ('profiling')
              </li>
            </ul>
            <p>
              To submit a request to exercise these rights described above,
              please email stayforall.info@gmail.com or visit
              <a
                target="_blank"
                href="https://stayforall.com/myProfile/profile"
              >
                https://stayforall.com/myProfile/profile
              </a>{" "}
              .
            </p>
            <p>
              If we decline to take action regarding your request and you wish
              to appeal our decision, please email us at
              stayforall.info@gmail.com. Within forty-five (45) days of receipt
              of an appeal, we will inform you in writing of any action taken or
              not taken in response to the appeal, including a written
              explanation of the reasons for the decisions.
            </p>
            <h3>Connecticut Residents</h3>
            <p>
              This section applies only to Connecticut residents. Under the
              Connecticut Data Privacy Act (CTDPA), you have the rights listed
              below. However, these rights are not absolute, and in certain
              cases, we may decline your request as permitted by law.
            </p>
            <ul>
              <li>
                &#x2022; Right to be informed whether or not we are processing
                your personal data
              </li>
              <li>&#x2022; Right to access your personal data</li>
              <li>
                &#x2022; Right to correct inaccuracies in your personal data
              </li>
              <li>&#x2022; Right to request deletion of your personal data</li>
              <li>
                &#x2022; Right to obtain a copy of the personal data you
                previously shared with us
              </li>
              <li>
                &#x2022; Right to opt out of the processing of your personal
                data if it is used for targeted advertising, the sale of
                personal data, or profiling in furtherance of decisions that
                produce legal or similarly significant effects ('profiling')
              </li>
            </ul>
            <p>
              To submit a request to exercise these rights described above,
              please email stayforall.info@gmail.com or visit{" "}
              <a
                target="_blank"
                href="https://stayforall.com/myProfile/profile"
              >
                https://stayforall.com/myProfile/profile.
              </a>{" "}
            </p>
            <p>
              If we decline to take action regarding your request and you wish
              to appeal our decision, please email us at
              stayforall.info@gmail.com. Within sixty (60) days of receipt of an
              appeal, we will inform you in writing of any action taken or not
              taken in response to the appeal, including a written explanation
              of the reasons for the decisions.
            </p>
            <h3>Utah Residents</h3>
            <p>
              This section applies only to Utah residents. Under the Utah
              Consumer Privacy Act (UCPA), you have the rights listed below.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law.
            </p>
            <ul>
              <li>
                &#x2022; Right to be informed whether or not we are processing
                your personal data
              </li>
              <li>&#x2022; Right to access your personal data</li>
              <li>&#x2022; Right to request deletion of your personal data</li>
              <li>
                &#x2022; Right to obtain a copy of the personal data you
                previously shared with us
              </li>
              <li>
                &#x2022; Right to opt out of the processing of your personal
                data if it is used for targeted advertising or the sale of
                personal data
              </li>
            </ul>
            <p>
              To submit a request to exercise these rights described above,
              please email stayforall.info@gmail.com or visit{" "}
              <a
                target="_blank"
                href="https://stayforall.com/myProfile/profile"
              >
                https://stayforall.com/myProfile/profile.
              </a>{" "}
            </p>
            <h3>Virginia Residents</h3>
            <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
            <p>
              'Consumer' means a natural person who is a resident of the
              Commonwealth acting only in an individual or household context. It
              does not include a natural person acting in a commercial or
              employment context.
            </p>
            <p>
              'Personal data' means any information that is linked or reasonably
              linkable to an identified or identifiable natural person.
              'Personal data' does not include de-identified data or publicly
              available information.
            </p>
            <p>
              'Sale of personal data' means the exchange of personal data for
              monetary consideration.
            </p>
            <p>
              If this definition of 'consumer' applies to you, we must adhere to
              certain rights and obligations regarding your personal data.
            </p>
            <p className="underline">
              Your rights with respect to your personal data
            </p>
            <ul>
              <li>
                &#x2022; Right to be informed whether or not we are processing
                your personal data
              </li>
              <li>&#x2022; Right to access your personal data</li>
              <li>
                &#x2022; Right to correct inaccuracies in your personal data
              </li>
              <li>&#x2022; Right to request deletion of your personal data</li>
              <li>
                &#x2022; Right to obtain a copy of the personal data you
                previously shared with us
              </li>
              <li>
                &#x2022; Right to opt out of the processing of your personal
                data if it is used for targeted advertising, the sale of
                personal data, or profiling in furtherance of decisions that
                produce legal or similarly significant effects ('profiling')
              </li>
            </ul>
            <p className="underline">
              Exercise your rights provided under the Virginia VCDPA
            </p>
            <p>
              {" "}
              className="underline"
              <a
                target="_blank"
                href="https://stayforall.com/myProfile/profile"
              ></a>
              https://stayforall.com/myProfile/profile.
            </p>
            <p>
              If you are using an authorised agent to exercise your rights, we
              may deny a request if the authorised agent does not submit proof
              that they have been validly authorised to act on your behalf.
            </p>
            <p className="underline">Verification process</p>
            <p>
              We may request that you provide additional information reasonably
              necessary to verify you and your consumer's request. If you submit
              the request through an authorised agent, we may need to collect
              additional information to verify your identity before processing
              your request.
            </p>
            <p>
              Upon receiving your request, we will respond without undue delay,
              but in all cases, within forty-five (45) days of receipt. The
              response period may be extended once by forty-five (45) additional
              days when reasonably necessary. We will inform you of any such
              extension within the initial 45-day response period, together with
              the reason for the extension.
            </p>
            <p className="underline">Right to appeal</p>
            <p>
              If we decline to take action regarding your request, we will
              inform you of our decision and reasoning behind it. If you wish to
              appeal our decision, please email us at stayforall.info@gmail.com.
              Within sixty (60) days of receipt of an appeal, we will inform you
              in writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions. If your appeal is denied, you may contact the{" "}
              <a
                target="_blank"
                href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
              >
                Attorney General to submit a complaint.
              </a>{" "}
            </p>
          </section>

          {/* Number 13 */}
          <section>
            <h3 id="DO-OTHER-REGIONS-HAVE-SPECIFIC-PRIVACY-RIGHTS">
              13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
            </h3>
            <p>
              In Short: You may have additional rights based on the country you
              reside in.
            </p>
            <h5>Australia and New Zealand</h5>
            <p>
              We collect and process your personal information under the
              obligations and conditions set by Australia's Privacy Act 1988 and
              New Zealand's Privacy Act 2020 (Privacy Act).
            </p>
            <p>
              This privacy notice satisfies the notice requirements defined in
              both Privacy Acts, in particular: what personal information we
              collect from you, from which sources, for which purposes, and
              other recipients of your personal information.
            </p>
            <p>
              If you do not wish to provide the personal information necessary
              to fulfill their applicable purpose, it may affect our ability to
              provide our services, in particular:
            </p>
            <ul>
              <li>&#x2022; offer you the products or services that you want</li>
              <li>&#x2022; respond to or help with your requests</li>
              <li>&#x2022; manage your account with us</li>
              <li>&#x2022; confirm your identity and protect your account</li>
            </ul>
            <p>
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section{" "}
              <a
                className="text-black"
                href="#HOW-CAN-YOU-REVIEW,-UPDATE,-OR-DELETE-THE-DATA-WE-COLLECT-FROM-YOU"
              >
                'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?'
              </a>{" "}
            </p>
            <p>
              If you believe we are unlawfully processing your personal
              information, you have the right to submit a complaint about a
              breach of the Australian Privacy Principles to the{" "}
              <a
                target="_blank"
                href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
              >
                Office of the Australian Information Commissioner
              </a>{" "}
              and a breach of New Zealand's Privacy Principles to the{" "}
              <a
                target="_blank"
                href="https://www.privacy.org.nz/your-rights/making-a-complaint-to-the-privacy-commissioner/"
              >
                Office of New Zealand Privacy Commissioner
              </a>{" "}
              .
            </p>
            <h5>Republic of South Africa</h5>
            <p>
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              <a
                className="text-black"
                href="#HOW-CAN-YOU-REVIEW,-UPDATE,-OR-DELETE-THE-DATA-WE-COLLECT-FROM-YOU"
              >
                'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?'
              </a>
            </p>
            <p>
              If you are unsatisfied with the manner in which we address any
              complaint with regard to our processing of personal information,
              you can contact the office of the regulator, the details of which
              are:
            </p>
            <p>
              <a target="_blank" href="https://inforegulator.org.za/">
                The Information Regulator (South Africa)
              </a>
            </p>
            <p>
              General enquiries:
              <a target="_blank" href="mailto:enquiries@inforegulator.org.za">
                enquiries@inforegulator.org.za
              </a>
            </p>
            <p>Complaints (complete POPIA/PAIA form</p>
            <p>
              5):
              <a
                target="_blank"
                href="mailto:PAIAComplaints@inforegulator.org.za"
              >
                PAIAComplaints@inforegulator.org.za
              </a>{" "}
              &
              <a
                target="_blank"
                href="mailto:POPIAComplaints@inforegulator.org.za"
              >
                POPIAComplaints@inforegulator.org.za
              </a>
            </p>
          </section>

          {/* Number 14 */}
          <section>
            <h3 id="DO-WE-MAKE-UPDATES-TO-THIS-NOTICE">
              14. DO WE MAKE UPDATES TO THIS NOTICE?
            </h3>
            <p>
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated 'Revised' date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </section>

          {/* Number 15 */}
          <section>
            <h3 id="HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE">
              15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h3>
            <p>
              If you have questions or comments about this notice, you may email
              us at stayforall.info@gmail.com or contact us by post at:
            </p>
            <p>stayforall</p>
            <p>Berlin</p>
            <p>Address line 2</p>
            <p>City , State ZIP code</p>
            <p>Germany</p>
          </section>

          {/* Number 16 */}
          <section>
            <h3 id="HOW-CAN-YOU-REVIEW,-UPDATE,-OR-DELETE-THE-DATA-WE-COLLECT-FROM-YOU">
              16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h3>
            <p>
              You have the right to request access to the personal information
              we collect from you, change that information, or delete it. To
              request to review, update, or delete your personal information,
              please visit:{" "}
              <a
                target="_blank"
                href="https://stayforall.com/myProfile/profile"
              >
                https://stayforall.com/myProfile/profile
              </a>{" "}
              .
            </p>
          </section>
        </article>
      </div>
    </>
  );
}
