import { BsSend } from "react-icons/bs";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Form } from "react-bootstrap";
import Loading from "../../../components/samplePage/Loading";
import { LiaSmileBeam } from "react-icons/lia";

export default function MessageInput({
  t,
  sendMessageStatus,
  message,
  setMessage,
  showPicker,
  setShowPicker,
  handleEmojiSelect,
  handleSubmit,
}) {
  return (
    <>
      {showPicker && (
        <div className="absolute bottom-[70px] left-[10px]">
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
      <Form
        onSubmit={handleSubmit}
        className="d-flex items-center justify-between px-4 py-3"
      >
        <Form.Group className="w-full">
          <div
            className="flex items-center rounded-lg p-1"
            style={{
              backgroundColor: "#fff",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowPicker(!showPicker);
              }}
            >
              <LiaSmileBeam className="text-[black] text-[22px] mx-1" />
            </button>
            <Form.Control
              as="textarea"
              rows={1}
              style={{
                backgroundColor: "#fff",
                border: "none",
                color: "[black]",
              }}
              className="shadow-none text-sm"
              type="text"
              placeholder={t("chatPage.Type a message")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </Form.Group>
        <button
          variant=""
          type="submit"
          className="rounded-circle bg-[#83e2da] ml-2 p-[12px]"
        >
          {sendMessageStatus.loading ? (
            <Loading type="colTwo" color="#36d7b7" size={25} margin={0} />
          ) : (
            <BsSend className="text-[22px] text-[black]" />
          )}
        </button>
      </Form>
    </>
  );
}
