import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";

const WebinarPage = () => {
  const [loading, setLoading] = useState(false);
  const [webinars, setWebinars] = useState([]);
  const [editingWebinar, setEditingWebinar] = useState(null);
  const [image, setImage] = useState("");
  const [caption, setCaption] = useState("");
  const [details, setDetails] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [userName, setUserName] = useState("");
  const [userQualification, setUserQualification] = useState("");

  useEffect(() => {
    fetchWebinars();
  }, []);

  useEffect(() => {
    if (editingWebinar) {
      setImage(editingWebinar.image || "");
      setCaption(editingWebinar.caption || "");
      setDetails(editingWebinar.details || "");
      setDate(editingWebinar.date || "");
      setCategory(editingWebinar.category || "");
      setUserName(editingWebinar.userName || "");
      setUserQualification(editingWebinar.userQualification || "");
    } else {
      setImage("");
      setCaption("");
      setDetails("");
      setDate("");
      setCategory("");
      setUserName("");
      setUserQualification("");
    }
  }, [editingWebinar]);

  const fetchWebinars = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/webinar`);
      setWebinars(response.data.allWebinar);
    } catch (error) {
      console.error("Error fetching webinars:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateWebinar = async (e) => {
    e.preventDefault();
    try {
      if (editingWebinar) {
        await axios.put(`${apiUrl}/webinar/${editingWebinar._id}`, {
          image,
          caption,
          details,
          date,
          category,
          userName,
          userQualification,
        });
      } else {
        await axios.post(`${apiUrl}/webinar`, {
          image,
          caption,
          details,
          date,
          category,
          userName,
          userQualification,
        });
      }
      setEditingWebinar(null);
      fetchWebinars();
    } catch (error) {
      console.error("Error submitting webinar:", error);
    }
  };

  const handleNewWebinar = () => {
    setEditingWebinar(null);
  };

  const submit = (title, message, label1, label2, clickHandler) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [{ label: label1, onClick: clickHandler }, { label: label2 }],
    });
  };

  const handleEditWebinar = (webinar) => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setEditingWebinar(webinar);
  };

  const handleDeleteWebinar = async (id) => {
    try {
      await axios.delete(`${apiUrl}/webinar/${id}`);
      fetchWebinars();
    } catch (error) {
      console.error("Error deleting webinar:", error);
    }
  };

  return loading ? (
    <div>
      <Spinner animation="border" />
    </div>
  ) : (
    <div className="container mx-auto mt-5">
      <h1 className="text-center mb-4">Webinars</h1>
      <Form
        onSubmit={handleCreateOrUpdateWebinar}
        className="border bg-white p-4 my-3 rounded-md shadow-md"
      >
        <Form.Group controlId="formCategory" className="mb-3">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formImage" className="mb-3">
          <Form.Label>Image URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter image URL"
            value={image}
            onChange={(e) => setImage(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formCaption" className="mb-3">
          <Form.Label>Caption</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter caption"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formDetails" className="mb-3">
          <Form.Label>Details</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formDate" className="mb-3">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formUserName" className="mb-3">
          <Form.Label>Conducting Person's Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formUserQualification" className="mb-3">
          <Form.Label>Conducting Person's Qualification</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter qualification"
            value={userQualification}
            onChange={(e) => setUserQualification(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          {editingWebinar ? "Update Webinar" : "Create Webinar"}
        </Button>
      </Form>
      <Button variant="success" onClick={handleNewWebinar} className="mb-4">
        Create New Webinar
      </Button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {webinars && webinars?.length > 0 ? (
          webinars.map((webinar) => (
            <Card key={webinar._id} className="shadow-md">
              <Card.Img
                variant="top"
                src={webinar.image}
                alt="Webinar Image"
                loading="lazy"
              />
              <Card.Body>
                <Card.Title>{webinar.caption}</Card.Title>
                <Card.Text>{webinar.details}</Card.Text>
                <Button
                  variant="warning"
                  onClick={() => handleEditWebinar(webinar)}
                  className="me-2"
                  aria-label={`Edit ${webinar.caption}`}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() =>
                    submit(
                      "Delete Webinar",
                      "are you sure to delete this webinar",
                      "Yes, I do",
                      "No, I do'nt",
                      () => handleDeleteWebinar(webinar._id)
                    )
                  }
                  aria-label={`Delete ${webinar.caption}`}
                >
                  Delete
                </Button>
              </Card.Body>
            </Card>
          ))
        ) : (
          <p className="text-center">No webinars available.</p>
        )}
      </div>
    </div>
  );
};

export default WebinarPage;
