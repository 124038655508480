import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const NewsPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [allNews, setAllNews] = useState([]);
  const [editingNews, setEditingNews] = useState(null);
  const [page, setPage] = useState(query.get("page") || 1);
  const [image, setImage] = useState("");
  const [caption, setCaption] = useState("");
  const [shortDetails, setShortDetails] = useState("");
  const [fullDetails, setFullDetails] = useState("");

  useEffect(() => {
    fetchNews();
  }, [page]);

  useEffect(() => {
    if (editingNews) {
      setImage(editingNews.image || "");
      setCaption(editingNews.caption || "");
      setShortDetails(editingNews.shortDetails || "");
      setFullDetails(editingNews.fullDetails || "");
    } else {
      // Clear the form when not editing
      setImage("");
      setCaption("");
      setShortDetails("");
      setFullDetails("");
    }
  }, [editingNews]);

  const handlePageChange = (newPage) => {
    if (newPage < 1) return;
    query.set("page", newPage);
    navigate(`${window.location.pathname}?${query.toString()}`);
    setPage(newPage);
  };

  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/news/${page}`);
      setAllNews(response.data.allNews);
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateAllNews = async (e) => {
    e.preventDefault();
    try {
      if (editingNews) {
        await axios.put(`${apiUrl}/news/${editingNews._id}`, {
          image,
          caption,
          shortDetails,
          fullDetails,
        });
      } else {
        await axios.post(`${apiUrl}/news`, {
          image,
          caption,
          shortDetails,
          fullDetails,
        });
      }
      setEditingNews({
        _id: null,
        image: "",
        caption: "",
        shortDetails: "",
        fullDetails: "",
      });
      fetchNews();
    } catch (error) {
      console.error("Error submitting all News:", error);
    }
  };

  const handleNewNews = () => {
    setEditingNews(null);
  };

  const submit = (title, message, label1, label2, clickHandler) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [{ label: label1, onClick: clickHandler }, { label: label2 }],
    });
  };

  const handleEditNews = (news) => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setEditingNews(news);
  };
  const handleDeleteNews = async (id) => {
    try {
      await axios.delete(`${apiUrl}/news/${id}`);
      fetchNews();
    } catch (error) {
      console.error("Error deleting all News:", error);
    }
  };

  return (
    <div className="container mx-auto mt-5">
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <h1 className="text-center mb-4">News</h1>
          <Form
            onSubmit={handleCreateOrUpdateAllNews}
            className="border bg-white p-4 my-3 rounded-md shadow-md"
          >
            <Form.Group controlId="formImage" className="mb-3">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image URL"
                value={image} // changed from defaultValue to value
                onChange={(e) => setImage(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCaption" className="mb-3">
              <Form.Label>Caption</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter caption"
                value={caption} // changed from defaultValue to value
                onChange={(e) => setCaption(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDetails" className="mb-3">
              <Form.Label>Short Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter short details"
                value={shortDetails} // changed from defaultValue to value
                onChange={(e) => setShortDetails(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDetails" className="mb-3">
              <Form.Label>Full Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter Full details"
                value={fullDetails} // changed from defaultValue to value
                onChange={(e) => setFullDetails(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {editingNews ? "Update News" : "Create News"}
            </Button>
          </Form>
          <Button variant="success" onClick={handleNewNews} className="mb-4">
            Create New News
          </Button>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {allNews && allNews.length > 0 ? (
              allNews.map((news) => (
                <Card key={news._id} className="shadow-md">
                  <Card.Img
                    variant="top"
                    src={news.image}
                    alt="news Image"
                    loading="lazy"
                  />

                  <Card.Body>
                    <Card.Title>{news.caption}</Card.Title>
                    <Card.Text>{news.shortDetails}</Card.Text>
                    <Button
                      variant="warning"
                      onClick={() => handleEditNews(news)}
                      className="me-2"
                      aria-label={`Edit ${news.caption}`}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() =>
                        submit(
                          "Delete News",
                          "are you sure to delete this news",
                          "Yes, I do",
                          "No, I do'nt",
                          () => handleDeleteNews(news._id)
                        )
                      }
                      aria-label={`Delete ${news.caption}`}
                    >
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p className="text-center">No News available.</p>
            )}
          </div>
          <div className="my-5 pt-5 border-top flex items-center justify-center">
            <Button
              onClick={() => handlePageChange(Number(page) - 1)}
              disabled={Number(page) === 1}
            >
              Previous
            </Button>
            <Button
              className="mx-2"
              onClick={() => handlePageChange(Number(page) + 1)}
              disabled={allNews.length < 12}
            >
              Next
            </Button>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default NewsPage;
