import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../store/Actions/User";

export const useLoadUser = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userInfo = useSelector((state) => state.userInfo);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
    dispatch(loadUser());
  }, []);

  useEffect(() => {
    if (user.isAuthenticated) {
      setUserData(userInfo);
    }
  }, [user, userInfo]);

  return userData;
};
