import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../utils/apiUrl";

// Create Service
export const createInDService = createAsyncThunk(
  "createInDService",
  async ({ file, serviceName }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/service/${serviceName}`,
        file,
        { withCredentials: true }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Delete Service
export const DeleteInDService = createAsyncThunk(
  "DeleteInDService",
  async ({ serviceName, serviceIdSelect }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/service/${serviceName}/${serviceIdSelect}`,
        { withCredentials: true }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Complete Service
export const completeService = createAsyncThunk(
  "CompleteService",
  async ({ serviceName, id, interestId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${apiUrl}/service/${serviceName}/completeService/${id}/${interestId}`,
        "",
        { withCredentials: true }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// inDService Slice definition
const inDServiceSlice = createSlice({
  name: "dService",
  initialState: {
    loading: false,
    message: null,
    error: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.message = null;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // CreateInDService actions
      .addCase(createInDService.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createInDService.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createInDService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // DeleteInDService actions
      .addCase(DeleteInDService.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(DeleteInDService.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(DeleteInDService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // CompleteInDService actions
      .addCase(completeService.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(completeService.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(completeService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Fetch My Services
export const MyInDService = createAsyncThunk(
  "MyInDService",
  async ({ serviceName, status }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/service/${serviceName}/myService/${status}`,
        { withCredentials: true }
      );
      return data.service;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// myInDService Slice definition
const myInDServiceSlice = createSlice({
  name: "dService",
  initialState: {
    loading: false,
    services: null,
    error: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.message = null;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // CompleteIDService actions
      .addCase(MyInDService.pending, (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase(MyInDService.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase(MyInDService.rejected, (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      });
  },
});

// Fetch Other Services
export const OthersInDService = createAsyncThunk(
  "OthersInDService",
  async (
    {
      service,
      state,
      city,
      uploadDate,
      pageNumber,
      rent,
      securityDeposit,
      moveInDate,
      serviceFee,
      propertyType,
      numberOfBedrooms,
      lengthOfLease,
      roomType,
      utilities,
      airportName,
      arrivalDate,
      payment,
    },
    { rejectWithValue }
  ) => {
    try {
      let url =
        service === "Room"
          ? `${apiUrl}/service/${service}/othersService?state=${state}&city=${city}&uploadDate=${uploadDate}&pageNumber=${pageNumber}&rent=${rent}&securityDeposit=${securityDeposit}&moveInDate=${moveInDate}&serviceFee=${serviceFee}&propertyType=${propertyType}&numberOfBedrooms=${numberOfBedrooms}&lengthOfLease=${lengthOfLease}&roomType=${roomType}&utilities=${utilities}`
          : `${apiUrl}/service/${service}/othersService?state=${state}&city=${city}&uploadDate=${uploadDate}&pageNumber=${pageNumber}&airportName=${airportName}&arrivalDate=${arrivalDate}&payment=${payment}`;

      const { data } = await axios.get(url, { withCredentials: true });
      return data.othersServices;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// othersInDService Slice definition
const othersInDServiceSlice = createSlice({
  name: "othersInDService",
  initialState: {
    loading: false,
    services: null,
    error: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.message = null;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // CompleteIDService actions
      .addCase(OthersInDService.pending, (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase(OthersInDService.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase(OthersInDService.rejected, (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      });
  },
});

// Create Interest
export const createInterest = createAsyncThunk(
  "CreateInterest",
  async ({ serviceName, id, file }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/service/${serviceName}/interest/${id}`,
        file,
        { withCredentials: true }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Remove Interest
export const RemoveInterest = createAsyncThunk(
  "RemoveInterest",
  async ({ serviceName, id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/service/${serviceName}/interest/${id}`,
        { withCredentials: true }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Interest Accept
export const interestAccept = createAsyncThunk(
  "InterestAccept",
  async ({ serviceName, id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${apiUrl}/service/${serviceName}/acceptInterest/${id}`,
        "",
        { withCredentials: true }
      );
      return data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Interest Slice definition
const interestSlice = createSlice({
  name: "dService",
  initialState: {
    loading: false,
    message: null,
    error: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.message = null;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Interest actions
      .addCase(createInterest.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createInterest.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createInterest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Interest actions
      .addCase(RemoveInterest.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(RemoveInterest.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(RemoveInterest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Accepted interest actions
      .addCase(interestAccept.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(interestAccept.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(interestAccept.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Get My Interests
export const GetMyInterests = createAsyncThunk(
  "GetMyInterest",
  async ({ serviceName, status, completed }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/myInterests/${serviceName}/${status}/${completed}`,
        { withCredentials: true }
      );
      return data.interests;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// myInterest Slice definition
const myInterestSlice = createSlice({
  name: "myInterests",
  initialState: {
    loading: false,
    interests: null,
    error: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Interest actions
      .addCase(GetMyInterests.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.interests = null;
      })
      .addCase(GetMyInterests.fulfilled, (state, action) => {
        state.loading = false;
        state.interests = action.payload;
      })
      .addCase(GetMyInterests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Get Others' Interests
export const GetOthersInterests = createAsyncThunk(
  "GetOthersInterest",
  async ({ serviceName, id, accepted }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/othersInterests/${serviceName}/${id}/${accepted}`,
        { withCredentials: true }
      );
      return data.interests;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// othersInterest Slice definition
const othersInterestSlice = createSlice({
  name: "othersInterests",
  initialState: {
    loading: false,
    interests: null,
    error: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Interest actions
      .addCase(GetOthersInterests.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.interests = null;
      })
      .addCase(GetOthersInterests.fulfilled, (state, action) => {
        state.loading = false;
        state.interests = action.payload;
      })
      .addCase(GetOthersInterests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Interest Notification
export const interestNotification = createAsyncThunk(
  "interestNotification",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/interest/notification`, {
        withCredentials: true,
      });
      return data.unreadInterests;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Interest Slice definition
const interestNotificationSlice = createSlice({
  name: "interest Notification",
  initialState: {
    loading: false,
    interestNotifications: null,
    error: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Interest actions
      .addCase(interestNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.interestNotifications = null;
      })
      .addCase(interestNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.interestNotifications = action.payload;
      })
      .addCase(interestNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions for clearing messages and errors
export const { clearMessages, clearErrors: clearDServiceErrors } =
  inDServiceSlice.actions;
export const { clearErrors: clearMyDServiceErrors } = myInDServiceSlice.actions;
export const { clearErrors: clearOthersDServiceErrors } =
  othersInDServiceSlice.actions;
export const { clearErrors: clearInterestErrors } = interestSlice.actions;
export const { clearErrors: clearMyInterestErrors } = myInterestSlice.actions;
export const { clearErrors: clearOthersInterestErrors } =
  othersInterestSlice.actions;
export const { clearErrors: clearInterestNotificationErrors } =
  interestNotificationSlice.actions;

// Export the reducers to include in your store
export const inDServiceReducer = inDServiceSlice.reducer;
export const myInDServiceReducer = myInDServiceSlice.reducer;
export const othersInDServiceReducer = othersInDServiceSlice.reducer;
export const interestReducer = interestSlice.reducer;
export const myInterestReducer = myInterestSlice.reducer;
export const othersInterestReducer = othersInterestSlice.reducer;
export const interestNotificationReducer = interestNotificationSlice.reducer;
