import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import {
  Button,
  Card,
  Form,
  Spinner,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const BlogPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [editingBlog, setEditingBlog] = useState(null);
  const [page, setPage] = useState(Number(query.get("page")) || 1);
  const [image, setImage] = useState("");
  const [caption, setCaption] = useState("");
  const [shortDetails, setShortDetails] = useState("");
  const [fullDetails, setFullDetails] = useState("");

  useEffect(() => {
    fetchBlogs();
  }, [page]);

  useEffect(() => {
    if (editingBlog) {
      setImage(editingBlog.image || "");
      setCaption(editingBlog.caption || "");
      setShortDetails(editingBlog.shortDetails || "");
      setFullDetails(editingBlog.fullDetails || "");
    } else {
      setImage("");
      setCaption("");
      setShortDetails("");
      setFullDetails("");
    }
  }, [editingBlog]);

  const handlePageChange = (newPage) => {
    if (newPage < 1) return;
    query.set("page", newPage);
    navigate(`${window.location.pathname}?${query.toString()}`);
    setPage(newPage);
  };

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/blogsAndArticles/${page}`);
      setBlogs(response.data.blogsAndArticles);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateBlog = async (e) => {
    e.preventDefault();
    try {
      const blogData = { image, caption, shortDetails, fullDetails };
      if (editingBlog) {
        await axios.put(
          `${apiUrl}/blogsAndArticles/${editingBlog._id}`,
          blogData
        );
      } else {
        await axios.post(`${apiUrl}/blogsAndArticles`, blogData);
      }
      setEditingBlog(null);
      fetchBlogs();
    } catch (error) {
      console.error("Error submitting blog:", error);
    }
  };

  const handleNewBlog = () => {
    setEditingBlog(null);
  };

  const handleEditBlog = (blog) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setEditingBlog(blog);
  };

  const submit = (title, message, label1, label2, clickHandler) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [{ label: label1, onClick: clickHandler }, { label: label2 }],
    });
  };

  const handleDeleteBlog = async (id) => {
    try {
      await axios.delete(`${apiUrl}/blogsAndArticles/${id}`);
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <Container className="my-4">
      <h1 className="text-center mb-4">Blogs and Articles</h1>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {/* Blog Form */}
          <Form
            onSubmit={handleCreateOrUpdateBlog}
            className="bg-white p-4 rounded shadow-sm mb-5"
          >
            <h4>{editingBlog ? "Edit Blog" : "Create New Blog"}</h4>
            <Form.Group controlId="formImage" className="mb-3">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image URL"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCaption" className="mb-3">
              <Form.Label>Caption</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formShortDetails" className="mb-3">
              <Form.Label>Short Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter short details"
                value={shortDetails}
                onChange={(e) => setShortDetails(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formFullDetails" className="mb-3">
              <Form.Label>Full Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter full details"
                value={fullDetails}
                onChange={(e) => setFullDetails(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="me-2">
              {editingBlog ? "Update Blog" : "Create Blog"}
            </Button>
            <Button variant="success" onClick={handleNewBlog} className="mt-2">
              Create New Blog
            </Button>
          </Form>

          {/* Blog List */}
          <Row xs={1} md={2} lg={3} className="g-4">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <Col key={blog._id}>
                  <Card className="h-100 shadow-sm">
                    <Card.Img
                      variant="top"
                      src={blog.image}
                      alt="Blog Image"
                      loading="lazy"
                    />
                    <Card.Body>
                      <Card.Title>{blog.caption}</Card.Title>
                      <Card.Text>{blog.shortDetails}</Card.Text>
                      <Button
                        variant="warning"
                        onClick={() => handleEditBlog(blog)}
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() =>
                          submit(
                            "Delete Blog",
                            "Are you sure you want to delete this blog?",
                            "Yes",
                            "No",
                            () => handleDeleteBlog(blog._id)
                          )
                        }
                      >
                        Delete
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">No blogs available.</p>
            )}
          </Row>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-center my-4">
            <Button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="me-2"
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(page + 1)}
              disabled={blogs.length < 12}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default BlogPage;
