import React, { useState } from "react";
import { Col, Row, Container, Card, ListGroup } from "react-bootstrap";
import Gallery from "react-image-gallery";
import { MdOutlineVerified, MdOutlineDateRange } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { AiFillEuroCircle } from "react-icons/ai";
import { t } from "i18next";

export default function VerificationReview() {
  let serviceView = {
    owner: "Niyas km",
    rating: "4.5/5",
    availableFrom: "12-May-2023",
    monthlyRent: "450$",
    securityDeposit: "1300$",
  };

  return (
    <>
      <Container fluid className="p-4">
        <Row>
          <Col md={8}>
            <Gallery
              items={[
                "/images/directPage/room1.jpg",
                "/images/directPage/room2.jpeg",
              ].map((image) => ({
                original: image,
                thumbnail: image,
              }))}
            />
          </Col>
          <Col md={4}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>
                  {t("directServicePage.Property Details")}
                </Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item className="d-flex align-items-center">
                    <MdOutlineVerified className="text-success mr-2" />
                    <span>{serviceView.owner}</span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-center">
                    <FaStar className="text-warning mr-2" />
                    {serviceView.rating}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="d-flex align-items-center">
                      <MdOutlineDateRange className="mr-2" />
                      {t("directServicePage.Available from")}:
                    </span>
                    <span className="font-weight-normal ml-5">
                      {serviceView.availableFrom}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-center">
                    <AiFillEuroCircle className="mr-2" />
                    {t("directServicePage.Monthly rent")}:{" "}
                    {serviceView.monthlyRent}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
