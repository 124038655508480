import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoLanguage } from "react-icons/io5";
import { studyCountriesLanguages } from "../../../DataFiles/countries";
import "../../components.scss";
import { Link } from "react-router-dom";

const Language = ({ size }) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);

  const searchContainerRef = useRef(null);
  const searchIconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target) &&
        searchIconRef.current &&
        !searchIconRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    setVisible(false);
  };

  return (
    <>
      <div ref={searchContainerRef}>
        {visible && (
          <div className="navLanguage-drop">
            <ul className="languageContainer">
              {studyCountriesLanguages.map((language, index) => (
                <li
                  key={index}
                  className="flex items-center rounded p-3 cursor-pointer hover:shadow-md"
                  onClick={() => changeLanguage(language.code)}
                >
                  <img
                    className="w-[30px] h-[20px] mr-2"
                    src={`/images/icons/Languages/${language.name}.webp`}
                    alt={language.name}
                  />{" "}
                  {t(`navigation.${language.name}`)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <Link
        aria-label="Change Language"
        to="#"
        className="cursor-pointer"
        ref={searchIconRef}
        onClick={() => setVisible(!visible)}
      >
        {size === "large" ? (
          <button
            className="flex items-center text-[#747474] text-[22px] mr-2 hoverBlue"
            variant=""
          >
            <IoLanguage className="mr-2 text-[24px]" /> {t("Language")}
          </button>
        ) : (
          <IoLanguage className="text-[26px]" />
        )}
      </Link>
    </>
  );
};

export default Language;
