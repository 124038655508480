import React, { useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import { roomStaticData } from "../../../../DataFiles/data";

export default function RoomFilters({
  t,
  germanStates,
  stateCities,
  service,
  stateOptions,
  state,
  setState,
  city,
  setCity,
  uploadDate,
  setUploadDate,
  moveInDate,
  setMoveInDate,
  rent,
  setRent,
  securityDeposit,
  setSecurityDeposit,
  serviceFee,
  setServiceFee,
  propertyType,
  setPropertyType,
  numberOfBedrooms,
  setNumberOfBedrooms,
  lengthOfLease,
  setLengthOfLease,
  roomType,
  setRoomType,
  utilities,
  setUtilities,
  showSort,
  setShowSort,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const updateQueryParams = useCallback(() => {
    const params = new URLSearchParams(location.search);

    if (state) params.set("state", state);
    if (city) params.set("city", city);
    if (uploadDate) params.set("uploadDate", uploadDate);
    if (moveInDate) params.set("moveInDate", moveInDate);
    if (rent) params.set("rent", rent);
    if (securityDeposit) params.set("securityDeposit", securityDeposit);
    if (serviceFee) params.set("serviceFee", serviceFee);
    if (propertyType) params.set("propertyType", propertyType);
    if (numberOfBedrooms) params.set("numberOfBedrooms", numberOfBedrooms);
    if (lengthOfLease) params.set("lengthOfLease", lengthOfLease);
    if (roomType) params.set("roomType", roomType);
    if (utilities) params.set("utilities", utilities);

    navigate({ search: params.toString() }, { replace: true });
  }, [
    navigate,
    location.search,
    state,
    city,
    uploadDate,
    moveInDate,
    rent,
    securityDeposit,
    serviceFee,
    propertyType,
    numberOfBedrooms,
    lengthOfLease,
    roomType,
    utilities,
  ]);

  useEffect(() => {
    updateQueryParams();
  }, [
    state,
    city,
    uploadDate,
    moveInDate,
    rent,
    securityDeposit,
    serviceFee,
    propertyType,
    numberOfBedrooms,
    lengthOfLease,
    roomType,
    utilities,
  ]);

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleCheckChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleDateChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <Form className="sortingDiv">
      {/* State Sorting */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {state && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.state")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={(e) => {
            setCity("");
            setState(e.target.value);
          }}
          value={state}
        >
          <option value="" hidden>
            {t("findWork.state")}
          </option>
          {stateOptions?.map((e, i) => (
            <option key={`state${i}`} value={e.sampleName}>
              {e.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* City Sorting */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {city && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.city")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={handleChange(setCity)}
          value={city}
        >
          <option value="" hidden>
            {t("findWork.city")}
          </option>
          {stateCities?.cities?.map((e, i) => (
            <option key={`city${i}`} value={e}>
              {e}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Service Fee */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {serviceFee && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.service fee")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={handleChange(setServiceFee)}
          value={serviceFee}
        >
          <option value="" hidden>
            {t("findWork.service fee")}
          </option>
          {["None", "€50", "€100", "€200", "More than €250"].map((fee, i) => (
            <option key={i} value={fee}>
              {fee === "€100"
                ? `€100 (*${t("findWork.recommended")}*)`
                : t(`findWork.${fee}`)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Upload Date */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {uploadDate && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.date order")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={handleChange(setUploadDate)}
          value={uploadDate}
        >
          <option value="" hidden>
            {t("findWork.date order")}
          </option>
          <option value="-1"> {t("findWork.newest first")}</option>
          <option value="1"> {t("findWork.oldest first")}</option>
        </Form.Select>
      </Form.Group>

      {/* Lease Length */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {lengthOfLease && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.lease length")}
          </Form.Label>
        )}
        <Form.Select
          className="border-0 shadow-none p-0"
          onChange={handleChange(setLengthOfLease)}
          value={lengthOfLease}
        >
          <option value="" hidden>
            {t("findWork.lease length")}{" "}
          </option>
          {roomStaticData?.lengthOfLease?.map((length, i) => (
            <option key={`length${i}`} value={length}>
              {length}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Property Type */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {propertyType && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.property type")}
          </Form.Label>
        )}
        <Form.Select
          className="shadow-none border-0 p-0"
          onChange={handleChange(setPropertyType)}
          value={propertyType}
        >
          <option value="" hidden>
            {t("findWork.property type")}
          </option>
          {roomStaticData.propertyType.map((type, i) => (
            <option key={`property${i}`} value={type}>
              {t(`findWork.${type}`)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Number of Bedrooms */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        {numberOfBedrooms && (
          <Form.Label className="font-bold text-secondary">
            {t("findWork.number of bedrooms")}
          </Form.Label>
        )}
        <Form.Select
          className="shadow-none border-0 p-0"
          onChange={handleChange(setNumberOfBedrooms)}
          value={numberOfBedrooms}
        >
          <option value="" hidden>
            {t("findWork.number of bedrooms")}
          </option>
          {roomStaticData.number_of_Bedrooms.map((bedrooms, i) => (
            <option key={`bedrooms${i}`} value={bedrooms}>
              {bedrooms}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Move-in Date */}
      <Form.Group className="border-b border-grey mb-4 pb-2">
        <Form.Label className="font-bold text-secondary">
          {t("findWork.move-in date")}
        </Form.Label>
        <Form.Control
          className="shadow-none border-0 p-0"
          value={moveInDate}
          onChange={handleDateChange(setMoveInDate)}
          type="date"
        />
      </Form.Group>

      {/* Utilities Included */}
      <Form.Group className="mb-4 pb-2">
        <Form.Label className="font-bold text-secondary">
          {t("findWork.utilities included")}
        </Form.Label>
        <br />
        {["Yes", "No"].map((opt, i) => (
          <Form.Check
            key={i}
            inline
            label={t(`findWork.${opt}`)}
            type="radio"
            value={opt}
            checked={utilities === opt}
            onChange={handleCheckChange(setUtilities)}
          />
        ))}
      </Form.Group>

      {/* Room Type */}
      <Form.Group className="mb-4 pb-2">
        <Form.Label className="font-bold text-secondary">
          {t("findWork.room type")}
        </Form.Label>
        <br />
        {roomStaticData.roomType.map((type, i) => (
          <Form.Check
            key={i}
            inline
            type="radio"
            id={`roomType${i}`}
            name="roomType"
            label={t(`findWork.${type}`)}
            value={type}
            checked={roomType === type}
            onChange={handleCheckChange(setRoomType)}
          />
        ))}
      </Form.Group>
    </Form>
  );
}
