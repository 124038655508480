import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";

import { reactUrl } from "../../utils/apiUrl";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function CareerScreen() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const seo = seoData.CareerScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />
      <Navigation />
      <Container className="py-20">
        <Row className="lg:h-[80vh] flex justify-center items-center">
          <Col className="lg:order-2 md:order-2 flex justify-center">
            <LazyLoad height={200} offset={100} once>
              <img
                style={{ height: "520px" }}
                src="/images/infopage/job.webp"
                alt="Career Opportunities"
              />
            </LazyLoad>
          </Col>
          <Col className="lg:order-1 md:order-1" md={12} lg={7}>
            <h1 className="text-5xl font-bold mb-6">
              {t("infoPage.Join our world-class team of creators & marketers")}
            </h1>
            <h3 className="tracking-tight mb-10 text-lightBlack">
              {t(
                "We are building the best solution for international students in germany"
              )}
            </h3>
            <p>{t("infoPage.Jobs")}: 0</p>
            <Form className="flex items-center border rounded p-1">
              <BiSearchAlt2 className="ml-2 text-2xl" />
              <Form.Control
                className="border-0 flex-grow"
                type="text"
                placeholder="Search positions"
              />
              <Button className="bg-[#1e8449] text-white p-2">
                {t("infoPage.Search")}
              </Button>
            </Form>
            <div className="mt-2">
              {["All", "Designer", "Developer", "Marketing"].map((category) => (
                <Button
                  key={category}
                  className="mr-2 bg-secondary border-light"
                >
                  {t(`${category}`)}
                </Button>
              ))}
            </div>
            <div className="flex justify-center bg-[#f3f6f9] py-10 my-3">
              <p>{t("infoPage.Now , There is no Career Options available")}</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="shadow-lg text-[#023020] py-6 mb-5">
        <h6>{t("infoPage.IF The team")}</h6>
        <h1>
          {t("infoPage.Meet our team of experts in development & marketing")}
        </h1>
        <p className="text-xs">
          {t("infoPage.To be the company our customers want")}
        </p>
        <div className="flex flex-wrap justify-around my-10">
          {[
            {
              name: "RINSHAD",
              role: "Chief Executive Officer",
              img: "person1.webp",
            },
            {
              name: "NIYAS",
              role: "Chief Technology Officer",
              img: "person3.webp",
            },
            {
              name: "NIDHEESH",
              role: "Chief Business Officer",
              img: "person2.webp",
            },
          ].map((person, index) => (
            <div key={index} className="text-center p-3 shadow-lg m-2">
              <LazyLoad height={200} offset={100} once>
                <img
                  className="w-[220px] h-[170px] rounded"
                  src={`/images/infopage/${person.img}`}
                  alt={person.name}
                />
              </LazyLoad>
              <h5 className="font-bold mt-2">{person.name}</h5>
              <p className="text-secondary text-sm">
                {t(`infoPage.${person.role}`)}
              </p>
            </div>
          ))}
        </div>
      </Container>
      <FooterTop bottom="bottom" />
    </>
  );
}
