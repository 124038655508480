export const socketUrl = "https://stayforall.com";

export const googleUrl = "https://stayforall.com/api/v1/auth/google";

export const apiUrl = "https://stayforall.com/api/v1";

export const reactUrl = "https://stayforall.com";

// http://ec2-3-110-128-81.ap-south-1.compute.amazonaws.com:5000/api/v1
// http://ec2-13-232-3-135.ap-south-1.compute.amazonaws.com
// http://localhost:5000/api/v1
// http://localhost:3000
// http://localhost:5000
// https://api.stayforall.com/api/v1
