import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useTranslation } from "react-i18next";

const VerifiedStatus = ({ isVerified }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  // Detect if the device is a touch device
  useEffect(() => {
    const handleTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };
    handleTouchDevice();
    window.addEventListener("resize", handleTouchDevice);

    return () => {
      window.removeEventListener("resize", handleTouchDevice);
    };
  }, []);

  // Image move left and hide animation (300ms duration)
  const imageAnimation = useSpring({
    transform: isHovered ? "translateX(0px)" : "translateX(20px)",
    opacity: isHovered ? 0 : 1, // Fade out when hovered/tapped
    config: { duration: 300 }, // Set animation duration to 300ms
  });

  // Text fade-in animation (300ms duration)
  const textAnimation = useSpring({
    opacity: isHovered ? 1 : 0, // Show text on hover/tap
    transform: isHovered ? "translateX(0px)" : "translateX(20px)", // Text comes in from the right
    config: { duration: 300 }, // Set animation duration to 300ms
  });

  // Handle hover or tap
  const handleInteractionStart = () => setIsHovered(true);
  const handleInteractionEnd = () => setIsHovered(false);

  return (
    <div
      className="flex items-center translate-x-[-16px]"
      // Handle hover for desktop and tap for touch devices
      onMouseEnter={!isTouchDevice ? handleInteractionStart : null}
      onMouseLeave={!isTouchDevice ? handleInteractionEnd : null}
      onClick={isTouchDevice ? handleInteractionStart : null}
      onTouchEnd={isTouchDevice ? handleInteractionEnd : null}
    >
      <animated.img
        style={imageAnimation}
        className="w-[15px] ml-1"
        src={
          !isVerified
            ? "/images/icons/notVerified.png"
            : "/images/icons/verified.png"
        }
        alt={isVerified ? "Verified user icon" : "Not verified user icon"}
      />

      <animated.span
        style={textAnimation}
        className="text-[#0CAFFF] font-normal text-[14px] underline ml-2"
      >
        {isVerified
          ? t("commonComponent.Verified")
          : t("commonComponent.Not Verified")}
      </animated.span>
    </div>
  );
};

export default VerifiedStatus;
