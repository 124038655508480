import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { GiButterfly } from "react-icons/gi";
import FooterTop from "../../../components/common/FooterTop";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../../../components/Navigation/Navigation";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllBlogsAndArticles } from "../../../store/Actions/BlogsAndArticle";
import Loading from "../../../components/samplePage/Loading";
import { useTranslation } from "react-i18next";

import "../Social.scss";
import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";

export default function BlogScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const blogsAndArticle = useSelector((state) => state.blogsAndArticle);
  const query = new URLSearchParams(location.search);
  const [blogs, setBlogs] = useState([]);
  const page = query.get("page") || 1;

  const handleUpdatePage = (newPage) => {
    navigate(`${location.pathname}?page=${newPage}`);
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    dispatch(getAllBlogsAndArticles(page));
  }, [page, dispatch]);

  useEffect(() => {
    setBlogs(blogsAndArticle.blogsAndArticle);
  }, [blogsAndArticle]);

  const seo = seoData.BlogScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <Navigation />
      <div className="py-10">
        <div className="text-center my-5">
          <h1 className="flex items-center justify-center my-2">
            {t("infoPage.Writings from our team")}
            <GiButterfly className="ml-2" />
          </h1>
          <h6>{t("infoPage.The latest details for students and workers")}</h6>
        </div>

        {blogsAndArticle.loading ? (
          <Loading type="servicePage" color="#36d7b7" size={120} />
        ) : (
          <Container className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {blogs?.map((blog, index) => (
              <Card
                key={blog._id}
                onClick={() =>
                  navigate(`/blogs&article-Stayforall/${blog._id}`)
                }
                className="shadow-md hover:scale-105 cursor-pointer"
              >
                <Card.Img
                  variant="top"
                  src={blog.image}
                  alt="Blog Image"
                  loading="lazy"
                />

                <Card.Body>
                  <Card.Title>{blog.caption}</Card.Title>
                  <Card.Text>{blog.shortDetails}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Container>
        )}

        <div className="mt-3 mb-4 flex justify-between items-center">
          <div
            onClick={() => handleUpdatePage(Math.max(1, Number(page) - 1))}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              page === "1" ? "hidden" : "flex"
            }`}
          >
            <GrLinkPrevious className="mr-2" />
            <span className="font-bold">{t("infoPage.Previous")}</span>
          </div>
          <div>{`${t("infoPage.Page")} ${page}`}</div>
          <div
            onClick={() => handleUpdatePage(Number(page) + 1)}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              blogs?.length < 12 ? "hidden" : "flex"
            }`}
          >
            <span className="font-bold">{t("infoPage.Next")}</span>
            <GrLinkNext className="ml-2" />
          </div>
        </div>
      </div>
      <FooterTop bottom="bottom" />
    </>
  );
}
