import React from "react";
import LazyLoad from "react-lazyload";

export default function SmallDescription({ t, item }) {
  return (
    <div id="service_description" className="indirectDescHead">
      <div className="grid lg:grid-cols-2 md:grid-cols-1 lg:px-[190px] mt-[90px]">
        <div className="indirectDescSmall lg:w-[60%] md:w-[100%] md:px-5">
          <h1>
            {t("inDirectServicePage.Finding")} {item.name}{" "}
            {t(
              "inDirectServicePage.and Applying for Appointment Can Be Overwhelming"
            )}
          </h1>
          <p>
            {t("inDirectServicePage.And without the right information")}{" "}
            {item.name}.{" "}
            {t("inDirectServicePage.You hop on a self-guided journey")}
          </p>
          <p>
            {t(
              "inDirectServicePage.Create requests detailing your accommodation"
            )}
          </p>
        </div>
        <div className="dethead3img1">
          <LazyLoad height={200} offset={100} once>
            <img
              className="tasklist"
              src="/path/to/your/image.jpg" // Replace with actual image path
              alt="Tasks"
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
}
