import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/Store";
import "react-toastify/dist/ReactToastify.css";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { I18nextProvider } from "react-i18next";
import enTranslations from "./languages/en.json";
import deTranslations from "./languages/de.json";
import frTranslations from "./languages/fr.json";

const root = ReactDOM.createRoot(document.getElementById("root"));
i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    de: {
      translation: deTranslations,
    },
    fr: {
      translation: frTranslations,
    },
  },
  detection: {
    order: ["localStorage"],
    lookupLocalStorage: "i18nextLng",
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
