import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const customerStories = [
  {
    name: "RINSHAD",
    roleKey: "Chief Executive Officer",
    imgSrc: "/images/homepage/download.webp",
    imgAlt: "Rinshad",
  },
  {
    name: "NIYAS",
    roleKey: "Chief Technology Officer",
    imgSrc: "/images/homepage/download.webp",
    imgAlt: "Niyas KM",
  },
  {
    name: "NIDHEESH",
    roleKey: "Chief Business Officer",
    imgSrc: "/images/homepage/download.webp",
    imgAlt: "Nidheesh",
  },
];

export default function CustomerStories() {
  const { t } = useTranslation();

  return (
    <Carousel fade controls={false} indicators={false}>
      {customerStories.map((story, index) => (
        <Carousel.Item key={index} interval={1500}>
          <img
            className="d-block w-100"
            style={{ height: "120px" }}
            src={story.imgSrc}
            alt={story.imgAlt}
          />
          <Carousel.Caption className="capcarousel">
            <p className="font-bold text-[28px]" style={{ color: "black" }}>
              {story.name}
            </p>
            <p style={{ color: "#F9913D" }}>
              {t(`commonComponent.${story.roleKey}`)}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
