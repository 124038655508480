import { TiMessages } from "react-icons/ti";

export default function NewChat({ t }) {
  return (
    <div className="flex justify-center w-full h-full text-[#424242]">
      <div className="flex flex-column items-center justify-center gap-2 px-4 font-semibold">
        <h4>{t("chatPage.Welcome 👋 to SFA Chat❄")}</h4>
        <p>
          {t(
            "chatPage.Start chatting, find the perfect fit, and complete your service"
          )}
        </p>
        <TiMessages className="text-3xl md:text-6xl text-center" />
      </div>
    </div>
  );
}
