import { useEffect } from "react";

export const useEffectDService = ({
  dispatch,
  setError,
  loadUser,
  getDService,
  item,
  myDService,
  setSavedUrl,
  stringStep,
  setServices,
  dService,
  services,
  location,
  savedUrl,
  navigate,
}) => {
  // Scroll to top and initialize data on component mount
  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: "instant" });
    setError("");
    dispatch(loadUser());
    dispatch(getDService({ serviceName: item }));
  }, [dispatch, setError, loadUser, getDService, item]);

  // Handle URL updates based on service availability and stage
  useEffect(() => {
    const newQuery = new URLSearchParams(location.search);
    const initialStage = newQuery.get("step");

    if (initialStage !== "status" && myDService.services) {
      newQuery.set("step", "status");
    } else if (initialStage === "status" && !myDService.services) {
      newQuery.set("step", "form");
    }
    setSavedUrl(`${location.pathname}?${newQuery.toString()}`);
  }, [myDService, stringStep, location.search, setSavedUrl, item]);

  // Navigate to saved URL without updating browser history
  useEffect(() => {
    if (savedUrl) {
      navigate(savedUrl, { replace: true });
    }
  }, [savedUrl]);

  // Update services state when myDService changes
  useEffect(() => {
    setServices(myDService);
  }, [myDService, setServices]);

  // successful redirection
  useEffect(() => {
    const handleInterestMessages = () => {
      const handleMessages = () => {
        switch (dService.message) {
          case "Post Created Successfully":
            dispatch({ type: "clearMessage" });
            dispatch({ type: "clearErrors" });
            window.location = `/directServicePage/${item}?step=status`;
            break;
          case "Post Deleted successfully":
            dispatch({ type: "clearMessage" });
            dispatch({ type: "clearErrors" });
            window.location = `/directServicePage/${item}?step=0`;
            // document.documentElement.scrollTo({ top: 0, behavior: "instant" });
            break;
          default:
            break;
        }
      };
      handleMessages();
    };

    handleInterestMessages();
  }, [dService.message]);

  // Set error state based on dService errors
  useEffect(() => {
    setError(dService.error);
  }, [dService.error, setError]);

  // Navigate based on stage change
  useEffect(() => {
    const newQuery = new URLSearchParams(location.search);
    const initialStage = newQuery.get("step");

    if (initialStage !== "status" && !services.services) {
      newQuery.set("step", "form");
      navigate(`/directServicePage/${item}?${newQuery.toString()}`);
    }
  }, [location.search, navigate, services.services, item]);

  // No return needed as this is not a React component
};
