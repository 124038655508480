import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Navigation from "../../../components/Navigation/Navigation";
import FooterTop from "../../../components/common/FooterTop";
import "../Social.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GrLinkPrevious } from "react-icons/gr";
import { GrLinkNext } from "react-icons/gr";
import { getAllNews } from "../../../store/Actions/News";
import { FaRegNewspaper } from "react-icons/fa6";
import Loading from "../../../components/samplePage/Loading";
import { useTranslation } from "react-i18next";

import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";

export default function NewsScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allNews = useSelector((state) => state.allNews);
  const query = new URLSearchParams(location.search);

  const [news, setNews] = useState([]);
  const page = query.get("page") || 1;

  // Change Page Function
  const handleUpdatePage = (e) => {
    query.set("page", e);
    navigate(`${location.pathname}?${query.toString()}`);
  };

  // useEffects
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    dispatch(getAllNews(page));
  }, [page]);

  useEffect(() => {
    setNews(allNews.allNews);
  }, [allNews]);

  const seo = seoData.NewsScreen;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <Navigation />
      <div className="py-10">
        <div className="text-center mt-5 mb-2">
          <h1 className="flex items-center justify-center my-2">
            {t("infoPage.Latest News")} <FaRegNewspaper className="ml-2" />
          </h1>
        </div>

        {/*all news*/}
        {news.loading ? (
          <Loading type="servicePage" color="#36d7b7" size={120} />
        ) : (
          <Container fluid className="py-2">
            {news?.map((newNews, index) => (
              <Row
                key={newNews?._id}
                onClick={() => navigate(`/news-Stayforall/${newNews?._id}`)}
                className="d-flex flex-row shadow-md hover:scale-105 border rounded my-2 py-2 cursor-pointer"
              >
                <Col md={4}>
                  <Card.Img
                    variant="top"
                    src={newNews?.image}
                    alt="Blog Image"
                    loading="lazy"
                  />
                </Col>
                <Col className="my-3">
                  <Card.Body>
                    <Card.Title className="mb-3 underline">
                      {newNews?.caption}
                    </Card.Title>
                    <Card.Text>{newNews?.shortDetails}</Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            ))}
          </Container>
        )}

        {/*end section*/}
        <div className="mt-3 mb-4 flex justify-between items-center">
          <div
            onClick={() => handleUpdatePage(Math.max(1, Number(page) - 1))}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              page === "1" ? "hidden" : "flex"
            }`}
          >
            <GrLinkPrevious className="mr-2" />
            <span className="font-bold">{t("infoPage.Previous")}</span>
          </div>
          <div>{`${t("infoPage.Page")} ${page}`}</div>
          <div
            onClick={() => handleUpdatePage(Number(page) + 1)}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              news?.length < 12 ? "hidden" : "flex"
            }`}
          >
            <span className="font-bold">{t("infoPage.Next")}</span>
            <GrLinkNext className="ml-2" />
          </div>
        </div>
      </div>

      <FooterTop bottom="bottom" />
    </>
  );
}
