import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { apiUrl } from "../../utils/apiUrl";
import { Container, Button, Card } from "react-bootstrap";

export default function AnalyticsDashboard() {
  const [analyticsData, setAnalyticsData] = useState([]);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const userGrowthResponse = await axios.get(
          `${apiUrl}/admin/analytics/user-growth`
        );
        const documentUploadsResponse = await axios.get(
          `${apiUrl}/admin/analytics/document-uploads`
        );
        const verificationTrendsResponse = await axios.get(
          `${apiUrl}/admin/analytics/verification-trends`
        );

        // Combine data by date for unified chart
        const combinedData = userGrowthResponse.data.growth.map((userEntry) => {
          const date = userEntry.date;
          const documentUploadEntry = documentUploadsResponse.data.find(
            (entry) => entry.date === date
          );
          const verificationTrendEntry =
            verificationTrendsResponse.data.userVerifications.find(
              (entry) => entry.date === date
            );

          return {
            date,
            new_users: userEntry.new_users,
            uploads: documentUploadEntry ? documentUploadEntry.uploads : 0,
            verifications: verificationTrendEntry
              ? verificationTrendEntry.count
              : 0,
          };
        });

        setAnalyticsData(combinedData);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    fetchAnalyticsData();
  }, []);

  const handleExportUsers = () => {
    window.location.href = `${apiUrl}/admin/export/users`;
  };

  const handleExportDocuments = () => {
    window.location.href = `${apiUrl}/admin/export/documents`;
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Analytics Dashboard</h2>

      <Card className="p-3 shadow-sm">
        <h3>Combined User Analytics Over Time</h3>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={analyticsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="new_users"
              stroke="#8884d8"
              name="New Users"
            />
            <Line
              type="monotone"
              dataKey="uploads"
              stroke="#82ca9d"
              name="Document Uploads"
            />
            <Line
              type="monotone"
              dataKey="verifications"
              stroke="#ff7300"
              name="Verifications"
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>

      <div className="text-center mt-4">
        <Button variant="primary" onClick={handleExportUsers} className="me-3">
          Export Users as CSV
        </Button>
        <Button variant="success" onClick={handleExportDocuments}>
          Export Documents as CSV
        </Button>
      </div>
    </Container>
  );
}
