import React from "react";
import { serviceHomeDetails } from "../../../../DataFiles/data";

export default function SelectService({
  t,
  service,
  navigate,
  status,
  selectClass,
}) {
  let query = new URLSearchParams(window.location.search);
  return (
    <div className="sorting-container-wrapper bg-[#f7f7f9]">
      <ul className="sorting-container bg-[#bdbdbd44]">
        <li className="selectServiceItems ml-6">
          {serviceHomeDetails.map((e, i) => (
            <div
              key={`selectService${i}`}
              className={`selectServiceItem bg-[#ffffff]  px-2 py-1 mr-6 rounded cursor-pointer ${
                service == e.title && "border-[2px]"
              } `}
              onClick={() => {
                selectClass
                  ? navigate(
                      `/myProfile/workStatus?service=${e.title}&work_status=${status}`
                    )
                  : navigate(
                      `/myProfile/find_work?service=${e.title}&pageNumber=1`
                    );
              }}
            >
              <img
                className="w-[30px] h-[30px] mr-2"
                src={`/images/smallicons/${e.title}.png`}
                alt=""
              />{" "}
              {t(`findWork.${e.title}`)}
            </div>
          ))}
        </li>
      </ul>
    </div>
  );
}
