import React from "react";
import { ImCancelCircle } from "react-icons/im";
import { MdOutlinePhone, MdOutlinePayments } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { PiWarningOctagonBold } from "react-icons/pi";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { inDirectCategories } from "../../DataFiles/data";
import "react-image-gallery/styles/scss/image-gallery.scss";

export default function AirportAssistanceInterest({
  submit,
  interestView,
  handleInterestView,
  handleAcceptInterest,
  handleRejectInterest,
  accepted,
  interestError,
}) {
  const { t } = useTranslation();

  return (
    <>
      {interestView && (
        <div className="popup-view">
          <div className="popView">
            <div className="flex items-center mx-3 my-3">
              <img
                className="w-[60px]"
                src={inDirectCategories.Airportassistance.smallicon3}
                alt="Airport assistance"
                aria-hidden="true"
              />
              <h2 className="ml-2 font-bold text-lg">
                {t("interestComponent.Airport assistance")}{" "}
                {t("interestComponent.Interest Details")}
              </h2>
            </div>
            <div className="mx-3 pb-6">
              <div className="grid grid-cols-1 gap-4">
                <div className="flex items-center py-3 px-4 bg-white rounded shadow-sm">
                  <MdOutlinePhone className="mr-2 text-secondary" size={24} />
                  <span className="text-lg font-bold text-secondary">
                    {t("interestComponent.Primary Number")}:
                  </span>
                  <span className="ml-2 text-lg">
                    {interestView.primaryNumber}
                  </span>
                </div>
                <div className="flex items-center py-3 px-4 bg-white rounded shadow-sm">
                  <MdOutlinePhone className="mr-2 text-secondary" size={24} />
                  <span className="text-lg font-bold text-secondary">
                    {t("interestComponent.Secondary Number")}:
                  </span>
                  <span className="ml-2 text-lg">
                    {interestView.secondaryNumber || t("N/A")}
                  </span>
                </div>
              </div>

              <div className="flex items-center py-3 px-4 bg-white rounded shadow-sm mt-4">
                <MdOutlinePayments className="mr-2 text-secondary" size={24} />
                <span className="text-lg font-bold text-secondary">
                  {t("interestComponent.Service fee")}:
                </span>
                <span className="ml-2 text-lg">
                  € {interestView.serviceFee_response}
                </span>
              </div>
              <div className="flex py-3 px-4 bg-white rounded shadow-sm mt-4">
                <span className="text-lg font-bold text-secondary mr-2">
                  {t("interestComponent.Details")}:
                </span>
                <span className="text-secondary text-base break-words">
                  {interestView.details_response}
                </span>
              </div>
              {interestError && (
                <div
                  className="col-span-2 text-danger mt-4 flex items-center"
                  role="alert"
                >
                  <PiWarningOctagonBold className="mr-2 text-2xl" />
                  <span>{interestError}</span>
                </div>
              )}
              {submit && !accepted && (
                <div className="flex justify-center gap-6 mt-6">
                  <Button
                    variant="success"
                    onClick={() =>
                      submit(
                        t("interestComponent.Accept Interest"),
                        t(
                          "interestComponent.are you sure to accept this interest"
                        ),
                        t("interestComponent.Yes, I do"),
                        t("interestComponent.No, I do'nt"),
                        handleAcceptInterest
                      )
                    }
                    className="d-flex items-center justify-center py-2 w-1/3 text-white"
                  >
                    {t("interestComponent.Accept")}
                  </Button>
                  <Button
                    onClick={() =>
                      submit(
                        t("interestComponent.Reject Interest"),
                        t(
                          "interestComponent.are you sure to reject this interest"
                        ),
                        t("interestComponent.Yes, I do"),
                        t("interestComponent.No, I do'nt"),
                        handleRejectInterest
                      )
                    }
                    className="d-flex items-center justify-center py-2 w-1/3 border-0"
                    style={{ backgroundColor: "grey", color: "white" }}
                  >
                    <AiOutlineDelete className="mr-2" />
                    {t("interestComponent.Delete")}
                  </Button>
                </div>
              )}
            </div>
            <button
              onClick={() => handleInterestView("")}
              className="closeBtn absolute top-2 right-2"
            >
              <ImCancelCircle className="text-2xl hover:scale-105" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
