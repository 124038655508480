import React, { useState, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";

const LocationSearchInput = ({ onSelect }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);

  const fetchResults = useCallback(
    debounce(async (query) => {
      if (query.trim() === "") {
        setResults([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const controller = new AbortController();
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
            query
          )}&countrycodes=de`,
          { signal: controller.signal }
        );

        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        setResults(data);
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Fetch request canceled");
        } else {
          console.error("Error fetching results:", err);
          setError(t("directServicePage.Error fetching results"));
        }
      } finally {
        setLoading(false);
      }
    }, 300), // Adjusted debounce time for responsiveness
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchText(query);
    fetchResults(query);
  };

  const handleSelectResult = (result) => {
    onSelect(result);
    setSearchText(result.display_name);
    setResults([]);
    setActiveIndex(-1);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setResults([]);
    setError(null);
    setActiveIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setActiveIndex((prev) => (prev < results.length - 1 ? prev + 1 : prev));
    } else if (e.key === "ArrowUp") {
      setActiveIndex((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter" && activeIndex >= 0) {
      handleSelectResult(results[activeIndex]);
    }
  };

  const ResultItem = React.memo(({ result, isActive, onSelect }) => (
    <li
      key={result.place_id}
      className={`cursor-pointer p-1 hover:bg-gray-200 ${
        isActive ? "bg-gray-200" : ""
      }`}
      onClick={() => onSelect(result)}
    >
      {result.display_name}
    </li>
  ));

  const resultList = useMemo(
    () =>
      results.map((result, index) => (
        <ResultItem
          key={result.place_id}
          result={result}
          isActive={index === activeIndex}
          onSelect={handleSelectResult}
        />
      )),
    [results, activeIndex]
  );

  return (
    <div className="relative">
      <div className="border rounded-[15px] px-2 py-1 mb-3">
        <Form.Control
          type="text"
          className="border-0 shadow-none"
          value={searchText}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder={t("directServicePage.Enter location")}
          aria-label="Location Search Input"
        />
      </div>

      {loading && (
        <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
          {/* Replace "..." with a spinner or loading icon */}
          <span className="spinner-border spinner-border-sm"></span>
        </div>
      )}

      {searchText && (
        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white border rounded p-1 cursor-pointer"
          type="button"
          onClick={handleClearSearch}
          aria-label="Clear Search"
        >
          {t("directServicePage.Clear")}
        </button>
      )}

      {error && <div className="mt-2 text-danger text-sm">{error}</div>}

      {results.length > 0 && (
        <ul className="mt-2 border rounded-lg p-2" aria-live="polite">
          {resultList}
        </ul>
      )}
    </div>
  );
};

export default LocationSearchInput;
