import React from "react";

export default function SocialMedia({ t }) {
  // Function to handle opening external URLs
  const openUrl = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="flex flex-col items-center">
      <div className="py-10">
        <h4 className="text-center pt-4 font-bold">
          {t("commonComponent.Reach us through")}
        </h4>
        <p className="text-center pb-0 mb-0 text-secondary">
          {t("commonComponent.Stayforall have more than 1,00,000+ members")}
        </p>
      </div>
      <div className="grid grid-cols-4 gap-3">
        <div
          onClick={() =>
            openUrl("https://www.facebook.com/profile.php?id=61557223952861")
          }
          className="flex flex-col items-center justify-center text-white bg-[#4971c0] rounded cursor-pointer"
          aria-label="Visit our Facebook page"
        >
          <img
            className="h-[50px] w-[50px] m-2"
            src="/images/icons/socialMedia/facebook.webp"
            alt="Facebook"
          />
        </div>
        <div
          onClick={() =>
            openUrl("https://www.instagram.com/stayforall.global/")
          }
          className="flex flex-col items-center justify-center text-white bg-[#feda75] rounded cursor-pointer"
          aria-label="Visit our Instagram page"
        >
          <img
            className="h-[50px] w-[50px] m-2"
            src="/images/icons/socialMedia/instagram.webp"
            alt="Instagram"
          />
        </div>
        <div
          onClick={() => openUrl("https://www.youtube.com/@Stayforall")}
          className="flex flex-col items-center justify-center text-white bg-[#c44040] rounded cursor-pointer"
          aria-label="Visit our YouTube channel"
        >
          <img
            className="h-[50px] w-[50px] m-2"
            src="/images/icons/socialMedia/youtube.webp"
            alt="YouTube"
          />
        </div>
        <div
          onClick={() =>
            openUrl(
              "https://wa.me/918075540817?text=Hello! This is a message from Stayforall Portal."
            )
          }
          className="flex flex-col items-center justify-center text-white bg-[#25D366] rounded cursor-pointer"
          aria-label="Chat with us on WhatsApp"
        >
          <img
            className="h-[50px] w-[50px] m-2"
            src="/images/icons/socialMedia/whatsapp.webp"
            alt="WhatsApp"
          />
        </div>
      </div>
    </div>
  );
}
