import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RiWhatsappFill } from "react-icons/ri";
import CustomerStories from "./CustomerStories";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileContract } from "react-icons/fa";
import { FaFileCircleExclamation } from "react-icons/fa6";
import { BsPersonFillExclamation } from "react-icons/bs";
import { MdOutlineWorkHistory } from "react-icons/md";
import { GrArticle } from "react-icons/gr";
import { GiNewspaper } from "react-icons/gi";
import { FaLaptopHouse } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";

const footerLinks = [
  {
    title: "About Stayforall",
    links: [
      {
        to: "/about-us-Stayforall",
        label: "About us",
        icon: BsPersonFillExclamation,
      },
      {
        to: "/career-Stayforall",
        label: "Careers",
        icon: MdOutlineWorkHistory,
      },
      { to: "/FAQs-Stayforall", label: "FAQs", icon: FaQuestionCircle },
    ],
  },
  {
    title: "Resources",
    links: [
      { to: "/news-Stayforall?page=1", label: "News", icon: GiNewspaper },
      {
        to: "/blogs&article-Stayforall?page=1",
        label: "Blog & Article",
        icon: GrArticle,
      },
      { to: "/webinars-Stayforall", label: "Webinars", icon: FaLaptopHouse },
    ],
  },
  {
    title: "Contact Us",
    links: [
      { to: "tel:+919656685380", label: "Call Us", icon: FaPhoneAlt },
      {
        to: `whatsapp://send?phone=+918075540817&text='Hello! This is a message from Stayforall Portal.'`,
        label: "WhatsApp",
        icon: RiWhatsappFill,
      },
      {
        to: "mailto:stayforall.info@gmail.com",
        label: "Email us",
        icon: MdEmail,
      },
    ],
  },
  {
    title: "Legal Information",
    links: [
      {
        to: "/terms-and-conditions",
        label: "Terms and conditions",
        icon: FaFileContract,
      },
      {
        to: "/privacy-policy",
        label: "Privacy policy",
        icon: FaFileCircleExclamation,
      },
      { to: "/cookies-policy", label: "Cookies policy", icon: FaFileAlt },
    ],
  },
];

const FooterTop = ({ top, bottom }) => {
  const { t } = useTranslation();

  return (
    <>
      {top === "top" && (
        <>
          <div className="flex justify-center items-center py-4">
            <img
              className="img-fluid"
              src="/images/commonitems/allcountries.svg"
              alt="All Countries"
            />
          </div>
          <div className="bg-gray-200 py-6 text-center">
            <h1 className="font-bold text-3xl sm:text-4xl md:text-5xl">
              {t("commonComponent.TRUSTED BY 1,000+ CUSTOMERS EVERY MONTH")}
            </h1>
          </div>
          <CustomerStories />
        </>
      )}

      {top === "search" && (
        <img
          className="w-full"
          src="/images/static/search_bg.webp"
          alt="search"
        />
      )}
      {top === "plane" && <div className="h-48 bg-gray-200"></div>}

      {bottom === "bottom" && (
        <div className="py-8 bg-[#ffffff00]">
          <Container>
            <Row className="justify-content-center">
              {footerLinks.map((section, index) => (
                <Col key={index} md={3} sm={6} className="mb-4">
                  <h6 className="font-semibold mb-3">
                    {t(`commonComponent.${section.title}`)}
                  </h6>
                  {section.links.map((link, idx) => (
                    <Link
                      key={idx}
                      to={link.to}
                      className="flex items-center text-black hover:text-blue mb-2"
                    >
                      <link.icon className="mr-2" />
                      {t(`commonComponent.${link.label}`)}
                    </Link>
                  ))}
                </Col>
              ))}
            </Row>

            <div className="flex items-center justify-center mt-4 border-t pt-4">
              <img
                className="w-24"
                src="/images/logo/SFAlargeLogo.webp"
                alt="SFA LOGO"
              />
              <p className="ml-3 text-sm text-gray-600">
                © Stayforall International Ltd. 2024
              </p>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default React.memo(FooterTop);
