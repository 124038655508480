import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { MdOutlineEdit } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { allCountries } from "../../../../DataFiles/countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function ContactInfo({
  t,
  name,
  email,
  number,
  nativeCountry,
  selectedSection,
  handleSelect,
  submitHandler,
}) {
  const [contactInfo, setContactInfo] = useState({
    email,
    number,
    nativeCountry,
  });

  const handleInputChange = (field) => (e) => {
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      [field]: e.target.value,
    }));
  };

  const handlePhoneNumberChange = (value) => {
    setContactInfo((prevInfo) => ({ ...prevInfo, number: value }));
  };

  const formSubmit = (e) => {
    e.preventDefault();
    submitHandler(contactInfo);
    handleSelect("");
  };

  return (
    <section className="bg-[white] my-2 border rounded-2xl p-3">
      {selectedSection !== "info" ? (
        <>
          <div className="flex items-center justify-between">
            <h6 className="font-bold">
              {t("profilePage.Personal Information")}
            </h6>
            <button
              onClick={() => handleSelect("info")}
              className="border border-grey rounded-circle text-[13px] p-1"
            >
              <MdOutlineEdit />
            </button>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <span className="text-secondary">
                {t("profilePage.Full Name")}
              </span>
              <p>{name}</p>
            </div>
            <div>
              <span className="text-secondary">{t("profilePage.Email")}</span>
              <p>{email || t("profilePage.Not Provided")}</p>
            </div>
            <div>
              <span className="text-secondary">{t("Phone Number")}</span>
              <p>{number || t("profilePage.Not Provided")}</p>
            </div>
            <div>
              <span className="text-secondary">
                {t("profilePage.Native Country")}
              </span>
              <p>{nativeCountry || t("profilePage.Not Provided")}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <h6 className="font-bold">{t("profilePage.Personal Information")}</h6>
          <Form
            onSubmit={formSubmit}
            className="flex items-start justify-between w-full"
          >
            <div className="grid gap-2 w-full">
              <Form.Group>
                <Form.Control
                  className="formBorder shadow-none"
                  defaultValue={email}
                  type="email"
                  placeholder={t("profilePage.Email")}
                  onChange={handleInputChange("email")}
                  required
                />
              </Form.Group>
              <Form.Group>
                <PhoneInput
                  country="de"
                  // value={contactInfo.number}
                  onChange={handlePhoneNumberChange}
                  inputStyle={{ width: "100%" }}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Select
                  className="option_choose"
                  defaultValue={nativeCountry}
                  onChange={handleInputChange("nativeCountry")}
                  required
                >
                  <option value="" hidden>
                    {t("profilePage.Choose Native Country")}
                  </option>
                  {allCountries.map((country, index) => (
                    <option value={country} key={`countries${index}`}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="flex flex-column ml-3">
              <Button
                variant="success"
                type="submit"
                className="rounded-xl mb-2 py-1 px-2"
              >
                <TiTick className="text-[21px]" />
              </Button>
              <Button
                variant="danger"
                onClick={() => handleSelect("")}
                className="rounded-xl mb-2 py-1 px-2"
              >
                <RxCross2 />
              </Button>
            </div>
          </Form>
        </>
      )}
    </section>
  );
}
