import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./ProfilePage.scss";
import {
  getUser,
  updateUser,
  logoutUser,
  removeUserAccount,
  passwordChange,
} from "../../../store/Actions/User";
import { Modal, Button, Form } from "react-bootstrap";
import ContactInfo from "./components/ContactInfo";
import PersonalInfo from "./components/PersonalInfo";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../components/samplePage/Loading";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineLogout, MdDeleteForever } from "react-icons/md";
import DocumentUpload from "./components/DocumentUpload";
import { RiLockPasswordFill } from "react-icons/ri";
import seoData from "../../../DataFiles/seoData";
import SEO from "../../../utils/SEO";

const ProfilePage = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const section = new URLSearchParams(location.search).get("section");

  const { user, userInfo, updateProfile } = useSelector((state) => ({
    user: state.user,
    userInfo: state.userInfo,
    updateProfile: state.updateProfile,
  }));

  const [selectedSection, setSelectedSection] = useState(section || "");
  const [profile, setProfile] = useState(null);
  const [accountRemove, setAccountRemove] = useState(false);
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const logoutHandler = useCallback(async () => {
    try {
      await dispatch(logoutUser());
      navigate("/");
    } catch (err) {
      toast.error(err.message || "Logout failed");
    }
  }, [dispatch, navigate]);

  const accountDeleteHandler = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(removeUserAccount(password));
    },
    [password]
  );

  const changePasswordHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (password === confirmPassword) {
        dispatch(passwordChange(password));
      } else toast.error(`password does'nt match`);
    },
    [password]
  );

  const submitHandler = useCallback(
    async (file) => {
      try {
        await dispatch(updateUser(file));
      } catch (error) {
        toast.error("Update failed. Please try again.");
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useMemo(() => {
    if (updateProfile.message === "Successfully updated user") {
      dispatch(getUser());
      setSelectedSection("");
      toast.success(t("profilePage.Profile updated successfully"));
    }
    if (updateProfile.message === "Account removed successfully") {
      navigate("/");
    }
    if (updateProfile.message === "Successfully updated password") {
      setChangePassword(false);
      toast.success(t("profilePage.Successfully updated password"));
    } else {
      toast.error(updateProfile.error);
    }
  }, [updateProfile, dispatch, t]);

  useEffect(() => {
    if (userInfo?.user) {
      setProfile(userInfo.user);
    }
  }, [userInfo]);

  useEffect(() => {
    document.body.style.overflow = accountRemove ? "hidden" : "auto";
    return () => (document.body.style.overflow = "auto");
  }, [accountRemove]);

  const seo = seoData.ProfilePage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />
      <div className="overveiw_cont p-3">
        <div className="flex justify-between">
          <h3>{t("profilePage.My Profile")}</h3>
          <a className="homeAnchor text-black" href="/">
            <AiOutlineHome className="text-[2.2rem] rounded-circle bg-grey text-white p-2 hover:scale-105" />
          </a>
        </div>
        {userInfo.loading ? (
          <Loading type="colOne" color="#36d7b7" size={20} />
        ) : (
          <>
            <PersonalInfo
              t={t}
              profileImage={profile?.profileImage}
              name={profile?.name}
              visaType={profile?.visaType}
              preferredState={profile?.preferredState}
              isVerified={profile?.isVerified}
              selectedSection={selectedSection}
              handleSelect={setSelectedSection}
              submitHandler={submitHandler}
            />
            <ContactInfo
              t={t}
              name={profile?.name}
              email={profile?.email}
              number={profile?.number}
              nativeCountry={profile?.nativeCountry}
              selectedSection={selectedSection}
              handleSelect={setSelectedSection}
              submitHandler={submitHandler}
            />
            <DocumentUpload dispatch={dispatch} t={t} />
            <div className="profileBtn">
              <button
                onClick={() => setChangePassword(true)}
                className="profileBtn3 mb-2 p-2"
              >
                <RiLockPasswordFill className="rounded-circle bg-grey p-1 text-[25px] mr-2" />
                {t("profilePage.Change Password")}
              </button>
              <button
                onClick={() => setAccountRemove(true)}
                className="profileBtn1 mb-2 p-2"
              >
                <MdDeleteForever className="rounded-circle bg-grey p-1 text-[25px] mr-2" />
                {t("profilePage.Delete My Account")}
              </button>
              <button
                onClick={logoutHandler}
                className="profileBt2 cursor-pointer px-2"
              >
                <MdOutlineLogout className="rounded-circle bg-[green] p-1 text-[25px] mr-2" />
                {t("profilePage.Logout")}
              </button>
            </div>
          </>
        )}
        <Modal
          show={accountRemove}
          onHide={() => setAccountRemove(false)}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("profilePage.Enter Password")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={accountDeleteHandler}>
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("profilePage.Enter your password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                {t("profilePage.Submit")}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={changePassword}
          onHide={() => setChangePassword(false)}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("profilePage.Change Your Password")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={changePasswordHandler}>
              <Form.Group controlId="formPassword">
                <Form.Label>{t("profilePage.Password")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("profilePage.Enter your password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label>{t("profilePage.Confirm Password")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("profilePage.Confirm your password")}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                {t("profilePage.Submit")}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ProfilePage;
