import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Form,
  Button,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../utils/apiUrl";

export default function AdminScreen() {
  const navigate = useNavigate();

  const [admins, setAdmins] = useState([]);
  const [logs, setLogs] = useState([]);
  const [newAdmin, setNewAdmin] = useState({
    username: "",
    email: "",
    password: "",
    role: "support",
  });

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const adminsResponse = await axios.get(`${apiUrl}/admin/admins`);
        setAdmins(adminsResponse.data);

        const logsResponse = await axios.get(`${apiUrl}/admin/admin-logs`);
        setLogs(logsResponse.data);
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }
    };

    fetchAdminData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateAdmin = async () => {
    try {
      await axios.post(`${apiUrl}/admin/admins`, newAdmin);
      alert("New admin account created successfully");
      setNewAdmin({ username: "", email: "", password: "", role: "support" });
      const adminsResponse = await axios.get(`${apiUrl}/admin/admins`);
      setAdmins(adminsResponse.data);
    } catch (error) {
      console.error("Error creating admin account:", error);
    }
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Admin Management</h2>

      <section className="mb-5">
        <h3>Admin Accounts</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin.id}>
                <td>{admin.username}</td>
                <td>{admin.email}</td>
                <td>{admin.role}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </section>

      <section className="mb-5">
        <h3>Create New Admin</h3>
        <Form className="mb-3">
          <Form.Group controlId="username">
            <Form.Control
              type="text"
              name="username"
              value={newAdmin.username}
              placeholder="Username"
              onChange={handleInputChange}
              className="mb-2"
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Control
              type="email"
              name="email"
              value={newAdmin.email}
              placeholder="Email"
              onChange={handleInputChange}
              className="mb-2"
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Control
              type="password"
              name="password"
              value={newAdmin.password}
              placeholder="Password"
              onChange={handleInputChange}
              className="mb-2"
            />
          </Form.Group>
          <Form.Group controlId="role">
            <Form.Select
              name="role"
              value={newAdmin.role}
              onChange={handleInputChange}
            >
              <option value="superadmin">Superadmin</option>
              <option value="verifier">Verifier</option>
              <option value="support">Support</option>
            </Form.Select>
          </Form.Group>
          <Button
            variant="primary"
            onClick={handleCreateAdmin}
            className="mt-3"
          >
            Create Admin
          </Button>
        </Form>
      </section>

      <section className="mb-5">
        <h3>Admin Activity Logs</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Admin</th>
              <th>Action</th>
              <th>Target ID</th>
              <th>Details</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id}>
                <td>{log.username}</td>
                <td>{log.action}</td>
                <td>{log.target_id}</td>
                <td>{log.details}</td>
                <td>{new Date(log.timestamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </section>

      <section className="text-center mb-5">
        <h3>Admin Panel</h3>
        <Row className="justify-content-center mt-4">
          <Col md={3} className="d-flex justify-content-center">
            <Card
              className="shadow-sm cursor-pointer p-3 text-center"
              onClick={() => navigate("/admin/dashboard")}
            >
              <Card.Img
                variant="top"
                src="/images/smallIcons/blog.png"
                className="w-50 mx-auto"
              />
              <Card.Body>Dashboard</Card.Body>
            </Card>
          </Col>
          <Col md={3} className="d-flex justify-content-center">
            <Card
              className="shadow-sm cursor-pointer p-3 text-center"
              onClick={() => navigate("/admin/users")}
            >
              <Card.Img
                variant="top"
                src="/images/smallIcons/news.png"
                className="w-50 mx-auto"
              />
              <Card.Body>Users</Card.Body>
            </Card>
          </Col>
          <Col md={3} className="d-flex justify-content-center">
            <Card
              className="shadow-sm cursor-pointer p-3 text-center"
              onClick={() => navigate("/admin/pendingVerifications")}
            >
              <Card.Img
                variant="top"
                src="/images/smallIcons/webinar.png"
                className="w-50 mx-auto"
              />
              <Card.Body>Pending Verifications</Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      <section className="text-center mb-5">
        <h3>Admin Panel</h3>
        <Row className="justify-content-center mt-4">
          <Col md={3} className="d-flex justify-content-center">
            <Card
              className="shadow-sm cursor-pointer p-3 text-center"
              onClick={() => navigate("/admin/blog?page=1")}
            >
              <Card.Img
                variant="top"
                src="/images/smallIcons/blog.png"
                className="w-50 mx-auto"
              />
              <Card.Body>Blogs</Card.Body>
            </Card>
          </Col>
          <Col md={3} className="d-flex justify-content-center">
            <Card
              className="shadow-sm cursor-pointer p-3 text-center"
              onClick={() => navigate("/admin/news?page=1")}
            >
              <Card.Img
                variant="top"
                src="/images/smallIcons/news.png"
                className="w-50 mx-auto"
              />
              <Card.Body>News</Card.Body>
            </Card>
          </Col>
          <Col md={3} className="d-flex justify-content-center">
            <Card
              className="shadow-sm cursor-pointer p-3 text-center"
              onClick={() => navigate("/admin/webinar")}
            >
              <Card.Img
                variant="top"
                src="/images/smallIcons/webinar.png"
                className="w-50 mx-auto"
              />
              <Card.Body>Webinar</Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
