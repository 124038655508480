import React from "react";
import PropTypes from "prop-types";
import "../Loader.scss";
import {
  DotLoader,
  ClipLoader,
  BeatLoader,
  CircleLoader,
} from "react-spinners";
import { css } from "@emotion/react";

const Loading = ({ heading, type, color, size, margin }) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red; /* Customize based on props or needs */
  `;

  const renderLoader = () => {
    switch (type) {
      case "homePage":
        return (
          <div className="loadingPage homePage">
            <h1 className="loaderMain shimmer-effect text-[80px]">{heading}</h1>
            <div className="loadingCircle"></div>
          </div>
        );
      case "servicePage":
        return (
          <div className="loadingPage flex-column">
            <DotLoader color={color} size={size} />
            <p className="mt-5 text-grey text-[1.1rem]">{heading}</p>
          </div>
        );
      case "hoverLoading":
        return (
          <div className="spinner-container">
            <div className="backdrop"></div>
            <div className="spinner">
              <CircleLoader color={color} css={override} size={size} />
            </div>
          </div>
        );
      case "colOne":
        return (
          <div className="flex items-center justify-center my-5">
            <BeatLoader color={color} size={size} />
          </div>
        );
      case "colTwo":
        return (
          <div className={`flex items-center justify-center my-${margin}`}>
            <ClipLoader color={color} size={size} />
          </div>
        );
      default:
        return null;
    }
  };

  return renderLoader();
};

Loading.propTypes = {
  heading: PropTypes.string,
  type: PropTypes.oneOf([
    "homePage",
    "servicePage",
    "hoverLoading",
    "colOne",
    "colTwo",
  ]),
  color: PropTypes.string,
  size: PropTypes.number,
  margin: PropTypes.number,
};

Loading.defaultProps = {
  heading: "",
  type: "colOne",
  color: "#36d7b7",
  size: 15,
  margin: 5,
};

export default Loading;
