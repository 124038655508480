import { extractTime } from "../utils/extractTime";

export default function Message({
  t,
  message,
  receiverId,
  read,
  showStatusIcon,
}) {
  const fromMe = message.receiverId == receiverId;
  const formattedTime = extractTime(message.timestamp);
  const chatClassName = fromMe ? "chat-end" : "chat-start";
  //   const profilePic = fromMe
  //     ? authUser.profilePic
  //     : selectedConversation?.profilePic;
  const bubbleBgColor = fromMe
    ? "bg-[#28535e] rounded-bl-md"
    : "bg-[#707070] rounded-br-md";

  const shakeClass = message.shouldShake ? "shake" : "";
  return (
    <div className={`chat ${chatClassName}`}>
      <div
        className={`chat-bubble bg-[] ${bubbleBgColor} ${shakeClass} pb-2 px-3 text-white rounded-t-md`}
      >
        <span className=" text-break">{message.message}</span>
        <span className="chat-footer opacity-50 text-[10px] flex justify-end p-0 ml-2">
          {formattedTime}
        </span>
      </div>
      {fromMe &&
        showStatusIcon && ( // Conditionally render the <img> element only if message is from current user
          <div className="flex items-center px-2">
            {read ? (
              <img className="w-[10px] mr-1" src="/images/icons/read.webp" />
            ) : (
              <img
                className="w-[9px] mr-1"
                src="/images/icons/check.webp"
                alt="Message Status"
              />
            )}
            <span className="text-[11px]">
              {read ? t("chatPage.Seen") : t("chatPage.Send")}
            </span>
          </div>
        )}
    </div>
  );
}
