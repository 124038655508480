import React, { memo, useCallback, useState, useEffect } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import Loading from "../../../components/samplePage/Loading";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdDateRange } from "react-icons/md";
import { FcViewDetails } from "react-icons/fc";

const Services = memo(
  ({
    t,
    status,
    category,
    services,
    handleServiceIdSelect,
    serviceIdSelect,
    handleServiceView,
    handleServiceDelete,
    handleCompleteSelect,
    accepted,
    myInDService,
  }) => {
    const [canSlideLeft, setCanSlideLeft] = useState(false);
    const [canSlideRight, setCanSlideRight] = useState(true);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [sliderWidth, setSliderWidth] = useState(0);
    const [sliderContainerWidth, setSliderContainerWidth] = useState(0);

    const formattedDate = (date) => {
      const arrivalDate = new Date(date);
      return arrivalDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };

    const submit = useCallback(
      (title, message, label1, label2, clickHandler) => {
        confirmAlert({
          title,
          message,
          buttons: [
            { label: label1, onClick: clickHandler },
            { label: label2 },
          ],
        });
      },
      []
    );

    const moveSlider = useCallback(
      (direction) => {
        const moveDistance = direction === "left" ? 200 : -200;
        let newLeft = sliderPosition + moveDistance;

        if (newLeft > 0) {
          newLeft = 0;
        } else if (Math.abs(newLeft) + sliderContainerWidth > sliderWidth) {
          newLeft = -(sliderWidth + 20 - sliderContainerWidth);
        }

        setSliderPosition(newLeft);
        setCanSlideLeft(newLeft < 0);
        setCanSlideRight(
          Math.abs(newLeft) + sliderContainerWidth < sliderWidth
        );
      },
      [sliderPosition, sliderContainerWidth, sliderWidth]
    );

    useEffect(() => {
      const slider = document.getElementById("slider");
      const sliderContainer = slider?.parentElement;
      const width = sliderContainer?.clientWidth;
      setSliderContainerWidth(width);
      setSliderWidth(slider?.scrollWidth);
      setCanSlideLeft(false);
      setCanSlideRight(sliderWidth > sliderContainerWidth);
    }, [services, sliderWidth, sliderContainerWidth]);

    return (
      <div className="flex items-center bg-[#f7f7f7] my-3 p-3 overflow-hidden relative">
        {canSlideLeft && (
          <MdKeyboardArrowLeft
            onClick={() => moveSlider("left")}
            className="absolute left-[10px] top-[50%] transform -translate-y-1/2 text-[47px] rounded-full bg-gray-300 p-[10px] hover:scale-110 cursor-pointer z-10"
          />
        )}
        {canSlideRight && (
          <MdKeyboardArrowRight
            onClick={() => moveSlider("right")}
            className="absolute right-[15px] top-[50%] transform -translate-y-1/2 text-[47px] rounded-full bg-gray-300 p-[10px] hover:scale-110 cursor-pointer z-10"
          />
        )}
        {myInDService.loading ? (
          <Loading type="colOne" color="#36d7b7" size={20} />
        ) : (
          <section
            id="slider"
            style={{ left: sliderPosition }}
            className="transition-left duration-300 ease relative left-0 flex items-center"
          >
            {services?.map((e, i) => (
              <div
                key={e.id}
                onClick={() => handleServiceIdSelect(e.id)}
                className={`${
                  serviceIdSelect !== e.id
                    ? "hover:scale-101 border"
                    : "border-1 border-[#24A4DF]"
                } min-w-[300px] min-h-[150px] mx-2 rounded-[20px] bg-[white] shadow-md cursor-pointer`}
              >
                <div className="p-3">
                  <div className="flex justify-between items-center mb-1">
                    <h5>
                      {category === "Room" ? (
                        <div className="flex items-center">
                          <img
                            className="w-[28px] mr-2"
                            src={`/images/smallicons/property.png`}
                            alt=""
                          />
                          {t(`inDirectServicePage.${e.propertyType}`)}
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <img
                            className="w-[28px] mr-2"
                            src={`/images/smallicons/airportName.png`}
                            alt=""
                          />
                          {e.airportName}
                        </div>
                      )}
                    </h5>
                    {serviceIdSelect === e.id && (
                      <button
                        className="flex items-start justify-end border-2 border-danger rounded-full text-danger p-[3px] mb-1 hover:scale-110"
                        onClick={() =>
                          submit(
                            t("inDirectServicePage.Confirm to Delete"),
                            `${t(
                              "inDirectServicePage.Are you sure to delete the"
                            )} ${t(`inDirectServicePage.${category}`)}.`,
                            t("inDirectServicePage.Yes"),
                            t("inDirectServicePage.No"),
                            handleServiceDelete
                          )
                        }
                      >
                        <AiOutlineDelete className="text-[15px]" />
                      </button>
                    )}
                  </div>
                  <p
                    data-interest-count={e.id}
                    className={`w-[100%] text-center bg-[#EDEDFF] text-green-500 text-[15px] p-1 ml-1 rounded ${
                      serviceIdSelect === e.id && accepted ? "hidden" : ""
                    }`}
                  >
                    {e.interestCount} {t("inDirectServicePage.response")}
                  </p>
                  <div>
                    <button
                      onClick={handleCompleteSelect}
                      className={`w-[100%] text-center bg-[#24A4DF] text-white text-[15px] p-1 ml-1 rounded ${
                        serviceIdSelect === e.id && accepted ? "" : "hidden"
                      }`}
                    >
                      {t("inDirectServicePage.Complete")}
                    </button>
                  </div>

                  <div className="flex text-secondary justify-between items-end mt-3">
                    <div>
                      <ls className="flex items-center font-bold text-[14px]">
                        <MdDateRange className="mr-2" />
                        {category === "Room"
                          ? t("inDirectServicePage.Move in date")
                          : t("inDirectServicePage.Arrival Date")}
                      </ls>
                      <ls className="text-[12px] text-[black]">
                        {formattedDate(
                          category === "Room" ? e.moveInDate : e.arrivalDate
                        )}
                      </ls>
                    </div>
                    <p
                      onClick={() => handleServiceView(serviceIdSelect)}
                      className={`flex items-center text-sm text-secondary cursor-pointer hover:underline ${
                        serviceIdSelect === e.id ? "" : "hidden"
                      }`}
                    >
                      <FcViewDetails className="mr-1" />
                      {t("inDirectServicePage.Details")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </section>
        )}
      </div>
    );
  }
);

export default Services;
