import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import LargeScreenNav from "./components/LargeScreenNav";
import SmallScreenNav from "./components/SmallScreenNav";
import "../components.scss";

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scrollPosition = useScrollPosition();
  const [cookieView, setCookieView] = useState(false);

  useEffect(() => {
    const viewCookiePolicyHead = localStorage.getItem("viewCookiePolicyHead");
    setCookieView(viewCookiePolicyHead !== "false");
  }, []);

  const handleCloseCookieView = () => {
    localStorage.setItem("viewCookiePolicyHead", "false");
    setCookieView(false);
  };

  return (
    <>
      {cookieView && (
        <div className="flex justify-between bg-[#2e6ea3] text-white p-1">
          <div className="flex items-center pt-1">
            <IoMdInformationCircleOutline className="mr-2 cookieIcon" />
            <h6 className="text-[12px] pt-1">
              {t("navigation.We use cookies to enhance your experience")}{" "}
              <a className="underline text-[#2ea35b]" href="/cookies-policy">
                {t("navigation.Find out more")}
              </a>{" "}
              {t("navigation.about cookies")}
            </h6>
          </div>
          <MdOutlineCancel
            onClick={handleCloseCookieView}
            className="ml-2 cookieIcon cursor-pointer"
          />
        </div>
      )}

      <div className={scrollPosition >= 200 ? "hide" : "homeNav2ndContainer"}>
        <LargeScreenNav t={t} navigate={navigate} dispatch={dispatch} />
      </div>

      <div
        className={`navigation-container ${
          scrollPosition >= 200 ? "shadow-xl homemovefix" : "homestablelargefix"
        }`}
      >
        <Nav className="largeNav">
          <SmallScreenNav t={t} navigate={navigate} dispatch={dispatch} />
        </Nav>
      </div>
    </>
  );
};

export default React.memo(Navigation);
