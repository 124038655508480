import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function NewPasswordPending() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email } = useParams();

  const seo = seoData.NewPasswordPending;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />
      <div>
        <section className="flex justify-between items-center py-3 px-1 border-bottom">
          <img
            className="w-[50px]"
            src="/images/logo/SFAmediumLogo.webp"
            alt="SFA LOGO"
          />
          <Button onClick={() => navigate("/")}>
            {t("authentication.Home")}
          </Button>
        </section>
        <section className="flex justify-center items-center mb-3">
          <div className="flex flex-column justify-center items-center max-w-[520px] border rounded my-3 mx-2 p-3">
            <img
              className="w-[100px]"
              src="/images/logo/SFAlargeLogo.webp"
              alt="SFA large Logo"
            />
            <h4>{t("authentication.Get a verification link")}</h4>
            <p>{t("authentication.To help keep your account safe")}</p>
            <img
              className="w-[360px]"
              src="/images/loginpage/forgotPassword.webp"
              alt="forgot password"
            />
            <h5>{t("authentication.Get a verification link")}</h5>
            <p>
              {t("authentication.SFA will send a verification code to")} {email}
              .
            </p>
          </div>
        </section>
      </div>
    </>
  );
}
