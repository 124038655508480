import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { germanStates } from "../../../DataFiles/countries";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { AiOutlineArrowRight, AiOutlineArrowDown } from "react-icons/ai";

const Header = ({ t }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    selectService: "Room",
    state: "Berlin",
    rent: "",
    securityDeposit: "",
    airport: "",
    serviceFee: "",
  });

  const scrollToComponent = (id) => {
    document
      .getElementById(id)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleServiceChange = (service) => {
    setFormData((prevData) => ({
      ...prevData,
      selectService: service,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (formData.selectService === "Room") {
      navigate(
        `/myProfile/find_work?service=Room&pageNumber=1&state=${formData.state}&rent=${formData.rent}&securityDeposit=${formData.securityDeposit}`
      );
    } else
      navigate(
        `/myProfile/find_work?service=Airport+assistance&pageNumber=1&state=${formData.state}&uploadDate=-1&airportName=${formData.airport}&payment=${formData.serviceFee}`
      );
  };

  const airports =
    germanStates.find((e) => e.sampleName === formData.state)?.airport || [];

  return (
    <div className="backgroundsethome">
      <Container className="headerHead">
        <h1 className="">
          <span className="typewriter"></span>
          <br />
          {t(`homePage.Help others to find`)}
        </h1>
        <div className="pt-6">
          <div className="flex mb-4">
            {["Room", "Airport assistance"].map((service) => (
              <button
                key={service}
                className={`flex items-center rounded mr-4 px-3 py-1 font-bold border border-gray-500 ${
                  formData.selectService === service
                    ? "bg-[#3f3f3f] text-white"
                    : "hover:bg-gray-200"
                }`}
                onClick={() => handleServiceChange(service)}
              >
                <img
                  className="w-[28px] mr-2"
                  src={`/images/smallicons/${service}.png`}
                  alt=""
                />{" "}
                {t(`homePage.${service}`)}
              </button>
            ))}
          </div>
          <Form onSubmit={handleSearch} className="homeFormSection shadow-lg">
            <Form.Group className="flex items-center justify-center">
              <img
                className="w-[28px]"
                src={`/images/smallicons/city.png`}
                alt=""
              />
              <Form.Select
                className="border-0 shadow-none"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              >
                {germanStates.map((e) => (
                  <option key={e.sampleName} value={e.sampleName}>
                    {e.sampleName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {formData.selectService === "Room" && (
              <>
                {["rent", "securityDeposit"].map((field) => (
                  <Form.Group
                    style={{ borderLeft: "1px solid grey" }}
                    key={field}
                    className="flex items-center mr-2 pl-4"
                  >
                    <img
                      className="w-[22px]"
                      src={`/images/smallicons/${field}.png`}
                      alt=""
                    />
                    <Form.Control
                      className="border-0 shadow-none"
                      type="text"
                      placeholder={t(`homePage.${field}`)
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()}
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                ))}
              </>
            )}
            {formData.selectService === "Airport assistance" && (
              <>
                <Form.Group
                  style={{ borderLeft: "1px solid grey" }}
                  className="flex items-center mr-2 pl-4"
                >
                  <img
                    className="w-[22px]"
                    src={`/images/smallicons/selectAirport.png`}
                    alt=""
                  />
                  <Form.Select
                    className="border-0 shadow-none"
                    name="airport"
                    value={formData.airport}
                    onChange={handleInputChange}
                  >
                    <option hidden value="">
                      {t(`homePage.select airport`)}
                    </option>
                    {airports.map((e) => (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  style={{ borderLeft: "1px solid grey" }}
                  className="flex items-center mr-2 pl-4"
                >
                  <img
                    className="w-[22px]"
                    src={`/images/smallicons/rent.png`}
                    alt=""
                  />
                  <Form.Control
                    className="border-0 shadow-none"
                    type="text"
                    placeholder={t(`homePage.service fee`)}
                    name="serviceFee"
                    value={formData.serviceFee}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </>
            )}
            <button
              className="p-2 text-[21px] border rounded-circle text-white bg-[#24A4DF] hover:shadow-lg"
              type="submit"
            >
              <CiSearch />
              {/* <span className="findMatchBtn ml-1">{t(`homePage.search`)}</span> */}
            </button>
          </Form>
        </div>
      </Container>
      <Container className="flex justify-end">
        <div className="homeimgmain shadow-lg rounded-[20px]">
          <div className="w-100 h-100 verificationHeader">
            <h3 className="">
              {t("homePage.Room verification").toUpperCase()}{" "}
            </h3>
            <div className="flex items-center">
              <img
                src="/images/logo/SFAsmallLogo.webp"
                alt="Stayforall Logo"
                className="w-5 mr-2"
              />
              <p className="text-sm text-gray-600 mt-3">
                {t("homePage.Sponsored by Stayforall")}
              </p>
            </div>
            <p className="mt-1 mb-3 text-[14px]">
              {t("homePage.Assisting you in verifying properties")} <br />
              {t("homePage.across 16 states with 160+ agents")}
            </p>
            {/* <p>{t("homePage.Room verification description")}</p> */}
            <div className="flex justify-end">
              <Button
                style={{ border: "1px solid #0cafff", background: "#e4e6e79d" }}
                variant=""
                className=" d-flex items-center"
                onClick={() => {
                  window.location.href = "/directServicePage/Room verification";
                }}
              >
                <span className="font-bold">{t("homePage.APPLY NOW")}</span>
                <img className="w-[30px] ml-2" src="/quality.png" alt="" />
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default React.memo(Header);
