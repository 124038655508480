import { useEffect } from "react";

export const useSignUpHandler = ({
  submitBtn,
  password,
  setError,
  dispatch,
  registerUser,
  file,
  user,
  setNumber,
}) => {
  const signUpHandler = (e) => {
    let messages = [];
    submitBtn.disabled = true;
    if (password.length < 7) {
      messages.push("Password at least 7 character long");
    }
    if (messages.length > 0) {
      e.preventDefault();
      setError(messages.join(", "));
    }
    if (messages.length == 0) {
      setError("");
      e.preventDefault();
      dispatch(registerUser(file));
      user.error && setError(user.error);
    }

    submitBtn.disabled = false;
  };

  const handlePhoneNumberChange = (value, country) => {
    setNumber(value);
  };
  return { signUpHandler, handlePhoneNumberChange };
};

export const useEffectSignUp = ({
  dispatch,
  loadUser,
  location,
  user,
  navigate,
  setError,
}) => {
  useEffect(() => {
    document.documentElement.scrollTo({ top: "0", behavior: "instant" });
    dispatch(loadUser());
  }, []);

  useEffect(() => {
    if (location.pathname == "/register" && user.isAuthenticated) navigate("/");
  }, [user.isAuthenticated]);

  useEffect(() => {
    setError(user.error);
  }, [user.error]);
  return;
};
