import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { logoutUser } from "../../store/Actions/User";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { MdOutlineLogout } from "react-icons/md";
import { serviceHomeDetails } from "../../DataFiles/data";
import { useSpring, animated } from "react-spring";
import { IoIosArrowDropdownCircle } from "react-icons/io";

export default function NavProfile({ t, category }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let query = new URLSearchParams(location.search);

  const service = query.get("service");
  const workStatus = query.get("work_status");

  const [isDropdownServiceVisible, setIsDropdownServiceVisible] =
    useState(true);
  const [isDropdownStatus, setIsDropdownStatus] = useState("");

  const springServiceProps = useSpring({
    transform: isDropdownServiceVisible ? "scaleY(1)" : "scaleY(0)",
    transformOrigin: "top",
    opacity: isDropdownServiceVisible ? 1 : 0,
    config: { duration: 100, tension: 10, friction: 1 },
  });

  const springStatusProps = useSpring({
    transform: isDropdownStatus ? "scaleY(1)" : "scaleY(0)",
    transformOrigin: "top",
    opacity: isDropdownStatus ? 1 : 0,
    config: { duration: 100, tension: 10, friction: 1 },
  });

  const logoutHandler = () => {
    dispatch(logoutUser())
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    setIsDropdownStatus(service);
  }, [service]);

  return (
    <>
      <ToastContainer position="bottom-center" autoClose={2000} />
      <>
        {/* for display greater than 600px */}
        <div className="sideNav_profile  overflow-y-scroll shadow-md select-none">
          <Nav className="d-flex flex-column justify-between px-3 z-1">
            <Nav.Item className="">
              <Nav.Link
                aria-label="Home"
                href="/"
                className="d-flex item-center justify-center rounded mt-3 mb-4"
              >
                <img
                  className="w-[150px]    rounded p-1"
                  alt=""
                  src="/images/logo/SFAlargeLogo.webp"
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mb-5">
              <h6
                onClick={() => {
                  setIsDropdownServiceVisible(!isDropdownServiceVisible);
                }}
                className="flex justify-between font-bold text-[black]"
              >
                {t("findWork.Services")}{" "}
                <span
                  className={
                    isDropdownServiceVisible ? "rotate-180" : "rotate-0"
                  }
                >
                  <IoIosArrowDropdownCircle />
                </span>
              </h6>
              <animated.div style={springServiceProps}>
                <div
                  style={{
                    display: isDropdownServiceVisible ? "block" : "none",
                  }}
                >
                  {serviceHomeDetails.map((e, i) => (
                    <>
                      <div
                        key={`selectService${i}`}
                        className={`${
                          service == e.title && category == "find_work"
                            ? "shadow-md rounded bg-[#0CAFFF] text-white font-bold"
                            : ""
                        } flex items-center p-2`}
                        onClick={() =>
                          navigate(
                            `/myProfile/find_work?service=${e.title}&pageNumber=1`
                          )
                        }
                      >
                        <img
                          className="w-[30px] h-[30px] mr-2"
                          src={`/images/smallicons/${e.title}.png`}
                          alt=""
                        />{" "}
                        {t(`findWork.${e.title}`)}
                      </div>
                      <animated.div style={springStatusProps}>
                        <div
                          className="ml-5"
                          style={{
                            display: service == e.title ? "block" : "none",
                            borderLeft: "1px solid grey",
                          }}
                        >
                          {["pending", "accepted"].map((e, i) => (
                            <div
                              key={`selectStatus${i}`}
                              className={`${
                                workStatus == e
                                  ? "shadow-md rounded bg-[#249dda] text-white font-bold"
                                  : ""
                              } flex items-center p-2 mb-1`}
                              onClick={() =>
                                navigate(
                                  `/myProfile/workStatus?service=${service}&work_status=${e}`
                                )
                              }
                            >
                              <img
                                className="w-[18px] h-[18px] mr-2"
                                src={`/images/smallicons/${e}.png`}
                                alt=""
                              />{" "}
                              {t(`findWork.${e}`)}
                            </div>
                          ))}
                        </div>
                      </animated.div>
                    </>
                  ))}
                </div>
              </animated.div>
            </Nav.Item>

            <Nav.Item>
              <h6 className="font-bold text-[black]">{t("findWork.Others")}</h6>
              <div
                className={`${
                  category == "profile"
                    ? "shadow-md rounded bg-[#0CAFFF] text-white font-bold"
                    : ""
                } flex items-center p-2`}
                onClick={() => navigate("/myProfile/profile")}
              >
                <img
                  className="w-[20px] mr-2"
                  src="/images/smallicons/user.png"
                  alt="user"
                />
                {t("findWork.Profile")}
              </div>
              <div className="flex items-center p-2" onClick={logoutHandler}>
                <img
                  className="w-[20px] mr-2"
                  src="/images/smallicons/logout.png"
                  alt="user"
                />
                {t("findWork.Logout")}
              </div>
            </Nav.Item>
          </Nav>
        </div>
      </>

      {/* for display lesser than 600px */}

      <>
        <div className="smallNavDashboard ">
          <div className="w-[100%] flex justify-around items-center py-[7px]">
            <div
              onClick={() => {
                navigate(
                  "/myProfile/workStatus?service=Room&work_status=pending"
                );
              }}
              className={`${
                category == "workStatus"
                  ? "bg-[orange] text-white shadow-lg"
                  : "border"
              } flex items-center rounded-[20px] px-2 py-2`}
            >
              <img
                className="w-[22px] h-[22px] mr-2"
                src="/images/smallicons/workStatus.png"
                alt="job Status"
              />
              <span className="text-[15px]">{t("findWork.Work Status")}</span>
            </div>

            <div
              onClick={() => {
                navigate("/myProfile/find_work?service=Room&pageNumber=1");
              }}
              className={`${
                category == "find_work"
                  ? "bg-[orange] text-white shadow-lg"
                  : "border"
              } flex items-center rounded-[20px] px-2 py-2`}
            >
              <img
                className="w-[22px] h-[22px] mr-2"
                src="/images/smallicons/services.png"
                alt="find Job"
              />
              <span className="text-[15px]">{t("findWork.Services")}</span>
            </div>

            <div
              onClick={() => {
                navigate("/myProfile/profile");
              }}
              className={`${
                category == "profile"
                  ? "bg-[orange] text-white shadow-lg"
                  : "border"
              } flex items-center rounded-[20px] px-2 py-2`}
            >
              <img
                className="w-[22px] h-[22px] mr-2"
                src="/images/smallicons/user.png"
                alt="user"
              />
              <span className="text-[15px]">{t("findWork.Profile")}</span>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
