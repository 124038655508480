import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";

import { reactUrl } from "../../utils/apiUrl";
import { faqs } from "../../DataFiles/data";
import "./Social.scss";
import seoData from "../../DataFiles/seoData";
import SEO from "../../utils/SEO";

export default function FAQSPage() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const seo = seoData.FAQSPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />
      <Navigation />
      <Container className="py-10">
        <p className="text-4xl text-[#4C787E] mb-5 ml-2">
          {t("infoPage.We'll take care of your doubts")}
        </p>
        <Row>
          <Col lg={6} className="flex justify-center mb-5 lg:mb-0">
            <LazyLoad height={200} offset={100} once>
              <img
                className="h-[370px]"
                alt="FAQs"
                src="/images/infopage/faqs.webp"
              />
            </LazyLoad>
          </Col>
          <Col lg={6} className="flex flex-column justify-center">
            <h2 className="font-bold mb-2">
              {t("infoPage.Frequently Asked Questions")}
            </h2>
            <Accordion bsPrefix="accordionfaqs" defaultActiveKey="0">
              {faqs.map((faq, index) => (
                <Accordion.Item
                  bsPrefix="accordion_item"
                  key={index}
                  eventKey={`${index}`}
                >
                  <Accordion.Header bsPrefix="accordion_head">
                    {t(`infoPage.${faq.question}`)}
                  </Accordion.Header>
                  <Accordion.Body bsPrefix="accordion_body">
                    {t(`infoPage.${faq.answer}`)}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
      <FooterTop bottom="bottom" />
    </>
  );
}
