import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../store/Actions/User";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import { reactUrl } from "../../utils/apiUrl";

import FindWork from "./FindWork/FindWork";
import NavProfile from "./NavProfile";
import WorkStatus from "./WorkStatus/WorkStatus";
import ProfilePage from "./ProfilePage/ProfilePage";
import "./OverviewPage.scss";
import Loading from "../../components/samplePage/Loading";
import LoginScreen from "../LoginPage/LoginScreen";

export default function UserProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { category } = useParams();
  const user = useSelector((state) => state.user);
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const renderPage = () => {
    switch (category) {
      case "find_work":
        return <FindWork t={t} />;
      case "workStatus":
        return <WorkStatus t={t} />;
      case "profile":
        return <ProfilePage t={t} />;
      default:
        return null;
    }
  };

  return (
    <>
      <ToastContainer position="bottom-center" autoClose={2000} />
      {user.loading && (
        <Loading type="servicePage" color="#36d7b7" size={120} />
      )}
      {user.isAuthenticated && (
        <div className="dashboard_container">
          <div className="cont_profile ">
            <NavProfile t={t} category={category} />
            {renderPage()}
          </div>
        </div>
      )}
      {!userInfo.loading && !user.isAuthenticated && <LoginScreen />}
    </>
  );
}
