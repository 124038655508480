import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import { germanStates } from "../../../../DataFiles/countries";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import VerifiedStatus from "../../../../components/common/VerifiedStatus";

export default function PersonalInfo({
  t,
  profileImage,
  name,
  visaType,
  preferredState,
  isVerified,
  selectedSection,
  handleSelect,
  submitHandler,
}) {
  const ProfilePicRef = useRef(null);
  const [personalInfo, setPersonalInfo] = useState({
    profileImage,
    name,
    visaType,
    preferredState,
  });

  useEffect(() => {
    setPersonalInfo({ profileImage, name, visaType, preferredState });
  }, [profileImage, name, visaType, preferredState]);

  const handleInputChange = (field, value) =>
    setPersonalInfo((prev) => ({ ...prev, [field]: value }));

  const formSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(personalInfo).forEach(([key, value]) =>
      formData.append(key, value)
    );
    submitHandler(formData);
  };

  const renderForm = () => (
    <Form
      onSubmit={formSubmit}
      className="flex items-start justify-between w-full"
    >
      <Form.Group
        onClick={(e) => {
          e.preventDefault();
          ProfilePicRef.current.click();
        }}
        className="flex"
      >
        <img
          className="rounded-circle w-[80px] h-[80px] profilePic"
          src={profileImage}
          alt="profile"
        />
        <button className="editBtn">
          <FaRegEdit />
        </button>
      </Form.Group>
      <Form.Group style={{ display: "none" }}>
        <Form.Control
          type="file"
          ref={ProfilePicRef}
          onChange={(e) => {
            handleInputChange("profileImage", e.target.files[0]);
            document.querySelector(".profilePic").src = URL.createObjectURL(
              e.target.files[0]
            );
          }}
        />
      </Form.Group>
      <div className="flex-1 grid gap-2 ml-4">
        <Form.Group>
          <Form.Control
            className="formBorder shadow-none"
            defaultValue={name}
            placeholder={t("profilePage.Full Name")}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        </Form.Group>
        <Form.Group className="my-3">
          <Form.Select
            className="formBorder shadow-none"
            value={personalInfo.visaType}
            required
            onChange={(e) => handleInputChange("visaType", e.target.value)}
          >
            <option hidden>
              {t("Select")} {t("profilePage.Visa Type")}
            </option>
            {[
              "student",
              "work",
              "tourist",
              "business",
              "family reunion",
              "transit",
              "diplomatic/official",
              "refugee/ asylum",
              "permanent",
              "investor/ entrepreneur",
              "exchange visitor",
            ].map((e, i) => (
              <option key={i} value={e}>
                {t(`profilePage.${e}`)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 mt-1">
          <Form.Select
            className="formBorder shadow-none"
            value={personalInfo.preferredState}
            onChange={(e) =>
              handleInputChange("preferredState", e.target.value)
            }
          >
            <option hidden>{t("profilePage.Preferred state")}</option>
            {germanStates.map((e, i) => (
              <option key={i} value={e.sampleName}>
                {e.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="flex flex-column items-center ml-3">
        <Button
          type="submit"
          variant="success"
          className="rounded-xl mb-2 py-1 px-2"
        >
          <TiTick className="text-[21px]" />
        </Button>
        <Button
          variant="danger"
          onClick={() => handleSelect("")}
          className="rounded-xl mb-2 py-1 px-2"
        >
          <RxCross2 />
        </Button>
      </div>
    </Form>
  );

  return (
    <section className="flex items-center justify-between bg-white my-2 border rounded-2xl p-3">
      {selectedSection !== "head" ? (
        <>
          <div className="flex items-center">
            <img
              className="rounded-circle w-[60px] h-[60px]"
              src={profileImage}
              alt="profile"
            />
            <div className="flex flex-column ml-3 text-[14px]">
              <div className="flex items-center font-bold text-[17px]">
                {name}
                <VerifiedStatus isVerified={isVerified} />
              </div>
              <div>{visaType}</div>
              <div>{preferredState}, Germany</div>
            </div>
          </div>
          <button
            onClick={() => handleSelect("head")}
            className="border border-grey rounded-circle text-[13px] p-1"
          >
            <MdOutlineEdit />
          </button>
        </>
      ) : (
        renderForm()
      )}
    </section>
  );
}
