import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import LocationSearchInput from "./LocationSearchInput";
import "leaflet/dist/leaflet.css";

const MapComponent = ({
  streetAddress,
  setStreetAddress,
  center = [52.52, 13.405],
  zoom = 13,
}) => {
  const handleLocationSelect = (location) => {
    setStreetAddress(location);
  };

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  const UpdateMapCenter = ({ newCenter }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(newCenter, map.getZoom());
    }, [newCenter, map]);
    return null;
  };

  return (
    <div className="relative h-full w-full">
      <LocationSearchInput onSelect={handleLocationSelect} />
      <MapContainer center={center} zoom={zoom} className="h-[24rem] w-[100%]">
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {streetAddress && streetAddress.lat && streetAddress.lon && (
          <>
            <Marker position={[streetAddress.lat, streetAddress.lon]}>
              <Popup>{streetAddress.display_name}</Popup>
            </Marker>
            <UpdateMapCenter
              newCenter={[streetAddress.lat, streetAddress.lon]}
            />
          </>
        )}
      </MapContainer>
    </div>
  );
};

MapComponent.propTypes = {
  streetAddress: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
    display_name: PropTypes.string,
  }),
  setStreetAddress: PropTypes.func.isRequired,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
};

export default MapComponent;
