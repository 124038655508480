import React from "react";

const Bubble = ({ item }) => {
  // Generate an array with 10 elements (assuming 10 bubbles based on your example)
  const bubbles = Array.from({ length: 10 }).map((_, index) => (
    <div
      key={index}
      style={{
        backgroundImage: `url(${item.bubble})`,
      }}
      className="bubble"
    ></div>
  ));

  return <div className="bubbles">{bubbles}</div>;
};

export default Bubble;
