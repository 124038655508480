import React from "react";
import { BsCalendarDate } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { MdOutlinePayments } from "react-icons/md";
import { AiOutlineFieldTime } from "react-icons/ai";
import { GiTempleGate } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";
import { CiAirportSign1, CiLocationOn } from "react-icons/ci";
import { ImCancelCircle } from "react-icons/im";
import { useTranslation } from "react-i18next";
import { inDirectCategories } from "../../DataFiles/data";

export default function AirportAssistanceView({
  serviceView,
  handleServiceId,
}) {
  const { t } = useTranslation();
  return (
    <>
      {serviceView && (
        <div className="popup-view">
          <div className="popView ">
            <p className="flex items-center mx-3 mt-3 font-bold">
              <img
                className="w-[60px]"
                src={inDirectCategories.Airportassistance.smallicon1}
                alt="Airport Assistance"
              />
              <span className="ml-2">
                {t("inDirectServicePage.Airport assistance")}{" "}
                {t("inDirectServicePage.Details")}
              </span>
            </p>

            <div className="m-3">
              <h3 className="flex items-center font-bold">
                <CiAirportSign1 className="text-[35px] mx-2 text-secondary" />
                {serviceView.airportName}
              </h3>
              <p className="ml-5">{serviceView.state}</p>
              <p className="mt-2 pt-2 border-t">
                {t("inDirectServicePage.I need")}{" "}
                {t("inDirectServicePage.Airport assistance")}{" "}
                {t("inDirectServicePage.at")} {serviceView.airportName}{" "}
                {t("inDirectServicePage.for a passenger arriving on")}{" "}
                {new Date(serviceView?.arrivalDate).toLocaleDateString(
                  "en-GB",
                  {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )}
                , at {serviceView.arrivalTime},{" "}
                {t("inDirectServicePage.with drop-off at")} "
                {serviceView.dropOffLocation}"{" "}
                {t("inDirectServicePage.The payment offered is")} €
                {serviceView.payment}.
              </p>
            </div>

            <div className="m-3 border rounded grid md:grid-cols-3 sm:grid-col-1 items-center bg-white">
              {/* State */}
              <div className="flex items-center md:justify-center py-2 text-[16px] md:border-r border-grey">
                <FiMapPin className="text-[21px] mx-2 text-secondary" />
                <p className="text-[17px] font-bold text-secondary mr-2">
                  <span>{t("inDirectServicePage.State")}</span>
                  <br />
                  {serviceView.state}
                </p>
              </div>

              {/* Arrival Date */}
              <div className="flex items-center md:justify-center py-2 text-[16px] md:border-r border-grey">
                <BsCalendarDate className="text-[21px] mx-2 text-secondary" />
                <p className="text-[17px] font-bold text-secondary mr-2">
                  <span>{t("inDirectServicePage.Arrival Date")}</span>
                  <br />
                  {new Date(serviceView.arrivalDate).toLocaleDateString(
                    "en-GB",
                    {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}
                </p>
              </div>

              {/* Arrival Time */}
              <div className="flex items-center md:justify-center py-2 text-[16px]">
                <AiOutlineFieldTime className="text-[21px] mx-2 text-secondary" />
                <p className="text-[17px] font-bold text-secondary mr-2">
                  <span>{t("inDirectServicePage.Arrival Time")}</span>
                  <br />
                  {serviceView.arrivalTime}
                </p>
              </div>
            </div>

            <div className="m-3 border-t border-b border-grey py-2 px-3">
              <h4>{t("inDirectServicePage.Additional Details")}</h4>
              <div className="flex flex-wrap">
                {/* Airport Name */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <CiAirportSign1 className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Airport name")}
                    </span>
                    <br />
                    {serviceView.airportName}
                  </p>
                </div>

                {/* Drop Off Location */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <CiLocationOn className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Drop Off Location")}
                    </span>
                    <br />
                    {serviceView.dropOffLocation || "N/A"}
                  </p>
                </div>

                {/* Payment */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <GiMoneyStack className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Payment")}
                    </span>
                    <br />€{serviceView.payment}
                  </p>
                </div>
              </div>
            </div>

            <div className="border-b border-grey py-2 px-3">
              <h4>{t("inDirectServicePage.Special Requirements")}</h4>
              <div className="flex flex-wrap">
                {serviceView.requirements?.map((requirement, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1"
                  >
                    <GiTempleGate className="border rounded-circle text-[40px] mx-2 p-1" />
                    <p className="font-bold mr-2">
                      <span className="text-[15px] text-secondary">
                        {t("inDirectServicePage.Requirement")}
                      </span>
                      <br />
                      {t(`inDirectServicePage.${requirement}`)}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Short Detail */}
            {serviceView.detail && (
              <div className="col-span-2 py-2 px-3 rounded text-[16px] pb-5">
                <span className="text-[17px] font-bold text-secondary mr-2">
                  {t("inDirectServicePage.More details")}
                </span>
                <br />
                {serviceView.detail}
              </div>
            )}
            <div onClick={() => handleServiceId("")} className="closeBtn">
              <ImCancelCircle className=" hover:scale-105" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
