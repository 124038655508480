import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../utils/apiUrl";

// Async actions

// Register User
export const registerUser = createAsyncThunk(
  "user/register",
  async (file, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/register`, file, {
        withCredentials: true,
      });
      return response.data; // You can return whatever you need from the response
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Login User
export const loginUser = createAsyncThunk(
  "user/login",
  async (file, { rejectWithValue }) => {
    try {
      await axios.post(`${apiUrl}/login`, file, {
        withCredentials: true,
      });

      const { data } = await axios.get(`${apiUrl}/load`, {
        withCredentials: true,
      });

      return data.user; // Return user data
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Logout User
export const logoutUser = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/logout`, {
        withCredentials: true,
      });
      return data.message; // Return logout message
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// user slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    error: null,
    isAuthenticated: false,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle registerUser actions
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = false;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle loginUser actions
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle logoutUser actions
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = true;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Load User
export const loadUser = createAsyncThunk(
  "user/loadUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // Dispatch a custom login pending action
      // dispatch({ type: "user/login/pending" });

      const { data } = await axios.get(`${apiUrl}/load`, {
        withCredentials: true,
      });

      // Dispatch a custom login success action
      dispatch({ type: "user/login/fulfilled" });

      return data.user; // Return user data
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Get User Info
export const getUser = createAsyncThunk(
  "user/getUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/user`, {
        withCredentials: true,
      });
      return data.user; // Return user data
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// loadUser slice
const loadUserSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle loadUser actions
      .addCase(loadUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle getUser actions
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Update User Profile
export const updateUser = createAsyncThunk(
  "user/updateProfile",
  async (profileInfo, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`${apiUrl}/update`, profileInfo, {
        withCredentials: true,
      });
      return data.message; // Return success message
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Change Password Email Send
export const changePasswordMailSend = createAsyncThunk(
  "user/changePasswordMailSend",
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${apiUrl}/forget_password_mail`, {
        email,
      });
      return data.message; // Return success message
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Forgot Password Change
export const forgotPasswordChange = createAsyncThunk(
  "user/forgotPasswordChange",
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/forgot_password_change/${token}`,
        { password },
        { withCredentials: true }
      );
      return data.message; // Return success message
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Password Change
export const passwordChange = createAsyncThunk(
  "user/passwordChange",
  async (password, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/password_change`,
        { password },
        { withCredentials: true }
      );
      return data.message; // Return success message
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Remove User Account
export const removeUserAccount = createAsyncThunk(
  "user/removeUserAccount",
  async (file, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/remove-account/${file}`, {
        withCredentials: true,
      });
      return data.message; // Return success message
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Create the updateProfile slice
const updateProfileSlice = createSlice({
  name: "webinars",
  initialState: {
    loading: false,
    message: null,
    error: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getAllWebinars actions
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })
      .addCase(changePasswordMailSend.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(changePasswordMailSend.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase(changePasswordMailSend.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })
      .addCase(forgotPasswordChange.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(forgotPasswordChange.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase(forgotPasswordChange.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })
      .addCase(passwordChange.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(passwordChange.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase(passwordChange.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })
      .addCase(removeUserAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(removeUserAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase(removeUserAccount.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      });
  },
});

// Upload Document
export const uploadDocument = createAsyncThunk(
  "user/uploadDocument",
  async (file, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${apiUrl}/upload-document`, file, {
        withCredentials: true,
      });
      return data.message; // Return success message
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Create the uploadUserDocument slice
const uploadUserDocumentSlice = createSlice({
  name: "Upload User Document",
  initialState: {
    loading: false,
    error: null,
    message: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getAllWebinars actions
      .addCase(uploadDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Get Document Status
export const getDocumentStatus = createAsyncThunk(
  "user/getDocumentStatus",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/verification-status`, {
        withCredentials: true,
      });
      return data.document; // Return document status
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Create the loadDocumentVerify slice
const loadDocumentVerifySlice = createSlice({
  name: "Load Document Verify",
  initialState: {
    loading: false,
    document: null,
    error: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getAllWebinars actions
      .addCase(getDocumentStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.document = null;
      })
      .addCase(getDocumentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.document = action.payload;
      })
      .addCase(getDocumentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to clear errors
export const { clearErrors: clearUserErrors } = userSlice.actions;
export const { clearErrors: clearLoadUserErrors } = loadUserSlice.actions;
export const { clearErrors: clearUpdateProfileErrors } =
  updateProfileSlice.actions;
export const { clearErrors: clearUploadDocumentErrors } =
  uploadUserDocumentSlice.actions;
export const { clearErrors: clearLoadDocumentVerifyErrors } =
  loadDocumentVerifySlice.actions;

export const userReducer = userSlice.reducer;
export const loadUserReducer = loadUserSlice.reducer;
export const updateProfileReducer = updateProfileSlice.reducer;
export const uploadUserDocumentReducer = uploadUserDocumentSlice.reducer;
export const loadDocumentVerifyReducer = loadDocumentVerifySlice.reducer;
