import React, { useState, useRef } from "react";
import { directCategories, serviceHomeDetails } from "../../../DataFiles/data";
import "../../components.scss";
import Language from "./Language";
import { CgProfile } from "react-icons/cg";
import MessageNotification from "./MessageNotification";
import ServiceNotification from "./ServiceNotification";
import { BsPersonFillExclamation } from "react-icons/bs";
import { GrArticle } from "react-icons/gr";
import { GiNewspaper } from "react-icons/gi";
import { FaLaptopHouse, FaQuestionCircle } from "react-icons/fa";
import { FaUser, FaUsers } from "react-icons/fa";
import { useSpring, animated } from "react-spring";
import { t } from "i18next";

const LargeScreenNav = ({ t, navigate, dispatch }) => {
  const [hover, setHover] = useState("");
  const timeoutRef = useRef(null);

  const handleMouseEnter = (key) => {
    clearTimeout(timeoutRef.current);
    setHover(key);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => setHover(""), 200);
  };

  return (
    <nav>
      <div className="flex justify-between items-center p-2 bg-white">
        <img
          onClick={() => navigate("/")}
          src="/images/logo/SFAmediumLogo.webp"
          className="ml-2 w-[16vh] cursor-pointer"
          alt="SFA Logo"
        />
        <div className="flex items-center text-[#747474]">
          <Language size="large" />
          <button
            style={{ borderColor: "rgb(36, 164, 223)" }}
            onClick={() => navigate("/myProfile/profile")}
            className="hoverBlue flex items-center text-[22px] border-[3px] px-3 py-[6px] rounded-[10px] ml-5"
          >
            <CgProfile className="mr-2 text-[24px]" />
            {t("navigation.Profile")}
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between bg-[#1e202d] text-white p-0">
        <ul className="flex m-0 p-0 translate-y-2 lg:text-[20px]">
          {[
            {
              title: "Explore",
              items: [
                {
                  to: "/about-us-Stayforall",
                  icon: BsPersonFillExclamation,
                  label: "About us",
                },
                {
                  to: "/blogs&article-Stayforall?page=1",
                  icon: GrArticle,
                  label: "Blog & Article",
                },
                {
                  to: "/news-Stayforall?page=1",
                  icon: GiNewspaper,
                  label: "News",
                },
                {
                  to: "/webinars-Stayforall",
                  icon: FaLaptopHouse,
                  label: "Webinars",
                },
                {
                  to: "/FAQs-Stayforall",
                  icon: FaQuestionCircle,
                  label: "FAQs",
                },
              ],
            },
            {
              title: "Services",
              items: directCategories.map((e) => ({
                to: `/directServicePage/${e.name}`,
                icon: () => (
                  <img
                    src={`/images/smallicons/${e.name}.png`}
                    alt={e.name}
                    className="mr-2 w-[25px]"
                  />
                ),
                label: e.name,
              })),
            },
            ...serviceHomeDetails.map((e) => ({
              title: e.title,
              items: [
                {
                  to: `/inDirectServicePage/${e.title}?status=form`,
                  icon: () => (
                    <img
                      src="/images/smallicons/My request.png"
                      alt="pending"
                      className="mr-2 w-[25px]"
                    />
                  ),
                  label: "My request",
                },
                {
                  to: `/myProfile/find_work?service=${e.title}&pageNumber=1`,
                  icon: () => (
                    <img
                      src="/images/smallicons/Others request.png"
                      alt="pending"
                      className="mr-2 w-[22px]"
                    />
                  ),
                  label: "Others request",
                },
                {
                  to: `/myProfile/workStatus?service=${e.title}&work_status=pending`,
                  icon: () => (
                    <img
                      src="/images/smallicons/pending.png"
                      alt="pending"
                      className="mr-2 ml-4 w-[22px]"
                    />
                  ),
                  label: "Pending response",
                },
                {
                  to: `/myProfile/workStatus?service=${e.title}&work_status=accepted`,
                  icon: () => (
                    <img
                      src="/images/smallicons/accepted.png"
                      alt="pending"
                      className="mr-2 ml-4 w-[20px]"
                    />
                  ),
                  label: "Accepted response",
                },
              ],
            })),
          ].map((navItem, idx) => (
            <NavItem
              t={t}
              key={idx}
              title={navItem.title}
              hover={hover}
              onEnter={() => handleMouseEnter(navItem.title)}
              onLeave={handleMouseLeave}
              items={navItem.items}
            />
          ))}
        </ul>
        <ul className="flex items-center bg-white m-0 p-0 border">
          <MessageNotification dispatch={dispatch} t={t} navigate={navigate} />
          <ServiceNotification dispatch={dispatch} t={t} navigate={navigate} />
        </ul>
      </div>
    </nav>
  );
};

const NavItem = ({ t, title, hover, onEnter, onLeave, items }) => {
  const springProps = useSpring({
    transform: hover === title ? "scaleY(1)" : "scaleY(0)",
    transformOrigin: "top",
    opacity: hover === title ? 1 : 0,
    config: { duration: 100, tension: 10, friction: 1 },
  });

  return (
    <li
      className="mr-[50px] ml-[10px] cursor-pointer"
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <p className="flex items-center text-white lg:text-[22px]">
        <img
          className="w-[18px] mr-2"
          src={`/images/smallicons/${title}.png`}
          alt=""
        />
        {t(`navigation.${title}`)}
      </p>
      <animated.div
        style={{
          ...springProps,
          width: "260px",
        }}
        className="absolute bg-white border rounded p-2 text-[17px]"
      >
        {items.map((item, idx) => (
          <a
            key={idx}
            className="text-black flex items-center hoverBlue py-1 mb-1"
            href={item.to}
          >
            {React.createElement(item.icon, { className: "mr-2" })}
            {t(`navigation.${item.label}`)}
          </a>
        ))}
      </animated.div>
    </li>
  );
};

export default React.memo(LargeScreenNav);
