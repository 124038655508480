import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../utils/apiUrl";

// Get All Blogs and Articles
export const getAllBlogsAndArticles = createAsyncThunk(
  "getAllBlogsAndArticles",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/blogsAndArticles/${page}`, {
        withCredentials: true,
      });
      return data.blogsAndArticles;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Get a Single Blog or Article by ID
export const getBlogAndArticleDetails = createAsyncThunk(
  "getBlogAndArticleDetails",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/blogsAndArticleSelected/${id}`,
        {
          withCredentials: true,
        }
      );
      return data.blogsAndArticles;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// blogsAndArticleSlice definition
const blogsAndArticleSlice = createSlice({
  name: "blogsAndArticle",
  initialState: {
    loading: false,
    error: null,
    blogsAndArticle: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogsAndArticles.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.blogsAndArticle = null;
      })
      .addCase(getAllBlogsAndArticles.fulfilled, (state, action) => {
        state.loading = false;
        state.blogsAndArticle = action.payload;
      })
      .addCase(getAllBlogsAndArticles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBlogAndArticleDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.blogsAndArticle = null;
      })
      .addCase(getBlogAndArticleDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.blogsAndArticle = action.payload;
      })
      .addCase(getBlogAndArticleDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearErrors } = blogsAndArticleSlice.actions;

export const blogsAndArticleReducer = blogsAndArticleSlice.reducer;
