import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../utils/apiUrl";

// Fetch all news with pagination
export const getAllNews = createAsyncThunk(
  "news/getAllNews",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/news/${page}`, {
        withCredentials: true,
      });
      return data.allNews;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Fetch a single news item by ID
export const getNews = createAsyncThunk(
  "news/getNews",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/newsSelected/${id}`, {
        withCredentials: true,
      });
      return data.news;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Define the newsSlice
const newsSlice = createSlice({
  name: "news",
  initialState: {
    loading: false,
    error: null,
    allNews: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getAllNews actions
      .addCase(getAllNews.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allNews = [];
      })
      .addCase(getAllNews.fulfilled, (state, action) => {
        state.loading = false;
        state.allNews = action.payload;
      })
      .addCase(getAllNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle getNews actions
      .addCase(getNews.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allNews = null;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.loading = false;
        state.allNews = action.payload;
      })
      .addCase(getNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the clearErrors action for use in components
export const { clearErrors } = newsSlice.actions;

// Export the reducer to include in the store configuration
export const newsReducer = newsSlice.reducer;
