import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container, Form, ProgressBar } from "react-bootstrap";
import { BsCalendarDate } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { AiOutlineFieldTime } from "react-icons/ai";
import { GiTempleGate } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";
import { CiLocationOn } from "react-icons/ci";
import { t } from "i18next";
import { germanStates } from "../../../DataFiles/countries";
import { useSpring, animated } from "react-spring";
import { ImSpinner9 } from "react-icons/im";

const initialState = {
  state: "",
  airportName: null,
  arrivalDate: null,
  arrivalTime: null,
  dropOffLocation: null,
  payment: null,
  requirements: [],
  detail: null,
};

export default function AirportAssistance({
  user,
  createFormHandler,
  error,
  setError,
  loading,
}) {
  const formRef = useRef(null);
  const [formData, setFormData] = useState(initialState);
  const [currentSection, setCurrentSection] = useState(0);
  const [animatedElements, setAnimatedElements] = useState(false);

  useEffect(() => {
    if (user?.preferredState) {
      setFormData((prev) => ({ ...prev, state: user.preferredState }));
    }
  }, [user]);

  const handleChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleCheckboxChange = (value, isChecked) => {
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        requirements: [...prev.requirements, value],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        requirements: prev.requirements.filter((item) => item !== value),
      }));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createFormHandler(formData);
  };

  const stateData = germanStates.find(
    (state) => state.sampleName === formData.state
  );
  const airports = stateData ? stateData.airport : [];

  const progressPercentage = ((currentSection + 1) / 5) * 100; // 5 sections in total

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const nextSection = () => {
    setAnimatedElements(true);
    if (currentSection < 4) {
      setCurrentSection(currentSection + 1);
      scrollToForm();
    }
    setTimeout(() => setAnimatedElements(false), 300);
  };

  const previousSection = () => {
    setAnimatedElements(true);
    setError("");
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      scrollToForm();
    }
    setTimeout(() => setAnimatedElements(false), 300);
  };

  const animationProps = useSpring({
    opacity: 1,
    transform: "translateX(0)",
    from: { opacity: 0, transform: "translateX(100px)" },
    reset: true,
    config: { duration: animatedElements ? 300 : 0 },
  });

  return (
    <Container
      ref={formRef}
      className="flex justify-center lg:mt-[190px] md:mt-[240px]"
    >
      <Form
        onSubmit={submitHandler}
        className="indirect_align w-full lg:w-3/4 p-4 bg-light rounded shadow-sm"
      >
        <div className="col-span-2 form_smallScreen_caption">
          <h2 className="text-center">
            {t("inDirectServicePage.Create your")}{" "}
            {t("inDirectServicePage.Airport assistance")}{" "}
            {t("inDirectServicePage.request")}
          </h2>
          <p>
            {t("inDirectServicePage.Airport assistance description")}{" "}
            <a
              href="#service_description"
              className="text-[#4475b4] cursor-pointer"
            >
              {t("inDirectServicePage.know more")}
            </a>
          </p>
        </div>

        <ProgressBar
          animated
          variant="info"
          now={progressPercentage}
          className="col-span-2 mb-4"
        />

        <animated.div style={animationProps}>
          {/* Step 1: State and Airport Name */}
          {currentSection === 0 && (
            <>
              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <FiMapPin className="ml-2" />
                <Form.Select
                  className="border-0 shadow-none"
                  value={formData.state}
                  onChange={handleChange("state")}
                  required
                >
                  {germanStates.map((state, idx) => (
                    <option key={idx} value={state.sampleName}>
                      {state.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <Form.Select
                  className="border-0 shadow-none"
                  value={formData.airportName}
                  onChange={handleChange("airportName")}
                  required
                >
                  <option hidden value="">
                    {t("inDirectServicePage.Select airport")}
                  </option>
                  s
                  {airports?.map((airport, idx) => (
                    <option key={idx} value={airport.name}>
                      {airport.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </>
          )}

          {/* Step 2: Arrival Date and Time */}
          {currentSection === 1 && (
            <>
              <Form.Group className="bg-[#eeeeee] border rounded mb-2">
                <Form.Label className="flex items-center w-[100%] rounded-top mr-3 bg-white  p-2">
                  <BsCalendarDate className="text-[19px] mr-2" />
                  {t("inDirectServicePage.Arrival Date")}
                </Form.Label>{" "}
                <div className="p-2 ">
                  <Form.Control
                    type="date"
                    className="border-0 shadow-none"
                    value={formData.arrivalDate}
                    onChange={handleChange("arrivalDate")}
                    placeholder={t("inDirectServicePage.Move in date")}
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group className="border rounded bg-[#eeeeee] mb-2">
                <Form.Label className="flex items-center w-[100%] rounded-top bg-white  p-2">
                  <AiOutlineFieldTime className="text-[19px] mr-2" />
                  {t("inDirectServicePage.Arrival Time")}
                </Form.Label>
                <div className="p-2">
                  <Form.Control
                    className="border-0 shadow-none"
                    value={formData.arrivalTime}
                    onChange={handleChange("arrivalTime")}
                    type="time"
                    required
                  />
                </div>
              </Form.Group>
            </>
          )}

          {/* Step 3: Drop-off Location and Payment */}
          {currentSection === 2 && (
            <>
              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <CiLocationOn />
                <Form.Control
                  className="border-0 shadow-none"
                  value={formData.dropOffLocation}
                  onChange={handleChange("dropOffLocation")}
                  type="text"
                  placeholder={t("inDirectServicePage.Drop Off Location")}
                />
              </Form.Group>

              <Form.Group className="flex bg-white items-center py-1 pl-2 rounded mb-2">
                <GiMoneyStack />
                <Form.Control
                  className="border-0 shadow-none"
                  value={formData.payment}
                  onChange={handleChange("payment")}
                  type="number"
                  placeholder={`${t("inDirectServicePage.Payment")} ( €100 + )`}
                  required
                />
              </Form.Group>
            </>
          )}

          {/* Step 4: Special Requirements */}
          {currentSection === 3 && (
            <Form.Group className="col-span-2 border rounded bg-[#eeeeee] mb-2">
              <Form.Label className="flex w-[100%] rounded-top mr-3 bg-white  p-2">
                <GiTempleGate className="text-[19px] mr-2" />
                {t("inDirectServicePage.Special Requirements")}
              </Form.Label>
              <div className="p-2">
                {[
                  "Wheelchair assistance",
                  "Language assistance",
                  "Baggage handling",
                  "Arrival notification",
                  "Customs and immigration assistance",
                  "Transportation coordination",
                  "VIP lounge access",
                  "Specialized meet-and-greet services",
                  "Document verification and guidance",
                  "Emergency assistance contacts",
                ].map((req, index) => (
                  <Form.Check
                    className="m-2"
                    key={index}
                    inline
                    type="checkbox"
                    value={req}
                    label={t(`inDirectServicePage.${req}`)}
                    name="group2"
                    id={`inline-checkbox-${index}`}
                    onChange={(e) =>
                      handleCheckboxChange(req, e.target.checked)
                    }
                  />
                ))}
              </div>
            </Form.Group>
          )}

          {/* Step 5: Additional Details */}
          {currentSection === 4 && (
            <Form.Group className="col-span-2 bg-white rounded mb-2">
              <Form.Control
                as="textarea"
                style={{ minHeight: "120px" }}
                className="border-0 shadow-none"
                value={formData.detail}
                onChange={handleChange("detail")}
                placeholder={`${t(
                  "inDirectServicePage.Anything more you would like to add"
                )}?`}
              />
            </Form.Group>
          )}
        </animated.div>

        {/* Error Handling */}
        {error && (
          <Alert variant="danger" className="col-span-2 mt-3">
            {error}
          </Alert>
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-end mt-4">
          {currentSection > 0 && (
            <Button
              variant="secondary"
              className="mr-3"
              onClick={previousSection}
            >
              {t("inDirectServicePage.Previous")}
            </Button>
          )}
          {currentSection < 4 && (
            <Button variant="info" onClick={nextSection}>
              {t("inDirectServicePage.Next")}
            </Button>
          )}
          {currentSection === 4 && (
            <Button
              className="d-flex items-center"
              variant="info"
              type="submit"
              disabled={loading}
            >
              {loading && <ImSpinner9 className="animate-spin mr-2" />}
              {t("inDirectServicePage.Submit")}
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
}
