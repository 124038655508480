// src/data/seoData.js
const seoData = {
  Error404Page: {
    title: "404 Error - Page Not Found | Stayforall",
    description:
      "Oops! The page you are looking for does not exist. Explore Stayforall for seamless transition services in Germany.",
    keywords: "404 error, page not found, Stayforall",
    ogTitle: "404 Error - Page Not Found | Stayforall",
    ogDescription:
      "We couldn't find the page you were looking for. Visit Stayforall for comprehensive services for your move to Germany.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/404",
    twitterTitle: "404 Error - Page Not Found | Stayforall",
    twitterDescription:
      "This page is not available. Discover Stayforall's services to make your move to Germany easier.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/404",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./AbouScreen.scss",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "404 Error - Stayforall",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Stayforall's 404 error page. Discover services for students, professionals, and more.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  SitemapPage: {
    title: "Sitemap | Stayforall",
    description:
      "Explore Stayforall's sitemap to find all pages and services offered to help your transition to Germany.",
    keywords:
      "Sitemap, Stayforall, Germany services, accommodation, airport assistance",
    ogTitle: "Sitemap | Stayforall",
    ogDescription:
      "Navigate through Stayforall’s sitemap to find all the information you need for a smooth move to Germany.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/sitemap",
    twitterTitle: "Sitemap | Stayforall",
    twitterDescription:
      "Find all the pages and services Stayforall offers to make your stay in Germany easier.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/sitemap",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./AbouScreen.scss",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "Sitemap - Stayforall",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Navigate through Stayforall's sitemap to easily find our services and information for your transition to Germany.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  ChatPage: {
    title: "Chat with Us | Stayforall",
    description:
      "Have questions or need help? Chat with Stayforall's support team to get assistance with your transition to Germany.",
    keywords: "chat support, Stayforall chat, assistance, Germany move support",
    ogTitle: "Chat with Us | Stayforall",
    ogDescription:
      "Get quick answers to your questions about moving to or living in Germany with Stayforall's chat support.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/chat/Room/:reciver/:sender",
    twitterTitle: "Chat with Us | Stayforall",
    twitterDescription:
      "Need help with your move to Germany? Chat with our support team at Stayforall for assistance.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/chat/Room/:reciver/:sender",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "Chat Support - Stayforall",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Chat with Stayforall's support team for personalized assistance regarding your stay in Germany.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  HomeScreen: {
    title: "Stayforall - Seamless Transition to Germany",
    description:
      "Stayforall offers end-to-end support for students, professionals, and families moving to Germany. Discover room rentals, airport assistance, and more.",
    keywords:
      "Stayforall, Germany relocation, room rentals, airport assistance, student services",
    ogTitle: "Stayforall - Your Trusted Partner for Transitioning to Germany",
    ogDescription:
      "Looking for a smooth transition to Germany? Stayforall offers comprehensive support services, from accommodation to airport pickups.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com",
    twitterTitle: "Stayforall - Seamless Transition to Germany",
    twitterDescription:
      "Discover Stayforall's services for making your move to Germany smooth and stress-free. Accommodation, airport assistance, and more.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./HomeScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "Stayforall Services",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Comprehensive support services for those moving to Germany, including room rentals, airport assistance, and more.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  DirectServicePage: {
    title: "Direct Services | Stayforall",
    description:
      "Stayforall provides direct services like room verification and student admissions support to make your move to Germany stress-free.",
    keywords:
      "room verification, student admissions, direct services, Stayforall",
    ogTitle: "Direct Services | Stayforall",
    ogDescription:
      "Explore Stayforall's direct services including room verification and admissions support for students in Germany.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/direct-services",
    twitterTitle: "Direct Services | Stayforall",
    twitterDescription:
      "Stayforall offers direct services like room verification and support for student admissions in Germany.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/direct-services",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./Productscreen.scss",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "Direct Services - Stayforall",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Direct services from Stayforall to ensure a smooth transition to Germany, including room verification and admissions support.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  InDirectServicePage: {
    title: "Indirect Services | Stayforall",
    description:
      "Stayforall connects users with service providers for room rentals, airport pickups, and more, making your move to Germany easy.",
    keywords: "indirect services, room rentals, airport assistance, Stayforall",
    ogTitle: "Indirect Services | Stayforall",
    ogDescription:
      "Browse Stayforall's indirect services like room rentals and airport pickups to simplify your transition to Germany.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/indirect-services",
    twitterTitle: "Indirect Services | Stayforall",
    twitterDescription:
      "Find indirect services through Stayforall, including room rentals and airport assistance for a smooth move to Germany.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/indirect-services",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "Productscreen.scss",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "Indirect Services - Stayforall",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Stayforall's indirect services connect users with room providers and airport assistance for a hassle-free move to Germany.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  BlogScreen: {
    title: "Stayforall Blog - Tips and Insights for Moving to Germany",
    description:
      "Explore our blog for valuable tips and insights on transitioning to Germany, covering topics from accommodation to local culture.",
    keywords: "Germany, moving tips, accommodation, student life, expat guide",
    ogTitle: "Stayforall Blog - Your Guide to Germany",
    ogDescription:
      "Discover tips and insights on moving to Germany through our blog.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/blogs&article-Stayforall?page=1",
    twitterTitle: "Stayforall Blog - Your Guide to Germany",
    twitterDescription: "Explore our blog for tips on moving to Germany.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/blogs&article-Stayforall?page=1",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./query.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Blog",
      name: "Stayforall Blog",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Explore valuable tips and insights on transitioning to Germany.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  BlogDetailedScreen: {
    title: "Stayforall Blog - [Blog Title]",
    description:
      "Read our detailed article on [Blog Topic] to help with your transition to Germany.",
    keywords: "[Relevant Keywords]",
    ogTitle: "Stayforall Blog - [Blog Title]",
    ogDescription: "In-depth insights on [Blog Topic].",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/blogs&article-Stayforall/[blog-id]",
    twitterTitle: "Stayforall Blog - [Blog Title]",
    twitterDescription: "Read more about [Blog Topic].",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/blogs&article-Stayforall/[blog-id]",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./query.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      name: "[Blog Title]",
      image: "/images/logo/SFAlargeLogo.webp",
      description: "In-depth insights on [Blog Topic].",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  NewsScreen: {
    title: "Stayforall News - Updates and Announcements",
    description:
      "Stay updated with the latest news and announcements from Stayforall regarding services and features.",
    keywords: "Stayforall news, updates, announcements, Germany",
    ogTitle: "Stayforall News - Latest Updates",
    ogDescription: "Read the latest news and updates from Stayforall.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/news-Stayforall?page=1",
    twitterTitle: "Stayforall News - Latest Updates",
    twitterDescription: "Catch up on the latest news from Stayforall.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/news-Stayforall?page=1",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./query.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      name: "Stayforall News",
      image: "/images/logo/SFAlargeLogo.webp",
      description:
        "Stay updated with the latest news and announcements from Stayforall.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  NewsDetailedScreen: {
    title: "Stayforall News - [News Title]",
    description: "Read our latest news on [News Topic] and stay informed.",
    keywords: "[Relevant Keywords]",
    ogTitle: "Stayforall News - [News Title]",
    ogDescription: "Detailed insights on [News Topic].",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/news-Stayforall/[news-title]",
    twitterTitle: "Stayforall News - [News Title]",
    twitterDescription: "Learn more about [News Topic].",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/news-Stayforall/[news-title]",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./query.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      name: "[News Title]",
      image: "/images/logo/SFAlargeLogo.webp",
      description: "Detailed insights on [News Topic].",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  WebinarPage: {
    title: "Stayforall Webinars - Join Our Sessions",
    description:
      "Join our informative webinars on transitioning to Germany and maximizing your stay.",
    keywords: "webinars, Stayforall, Germany transition, expat tips",
    ogTitle: "Stayforall Webinars - Join Us",
    ogDescription:
      "Participate in our webinars to learn more about moving to Germany.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/webinars-Stayforall",
    twitterTitle: "Stayforall Webinars - Join Us",
    twitterDescription:
      "Join our informative webinars to ease your move to Germany.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/webinars-Stayforall",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./ReviewPage.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Event",
      name: "Stayforall Webinars",
      image: "/images/logo/SFAlargeLogo.webp",
      description: "Join our informative webinars on transitioning to Germany.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  AboutScreen: {
    title:
      "About Stayforall - Your Trusted Partner for Transitioning to Germany",
    description:
      "Learn about Stayforall and our mission to assist individuals moving to Germany with tailored services and support.",
    keywords: "Stayforall, About Us, Moving to Germany, Transition Services",
    ogTitle: "About Stayforall",
    ogDescription:
      "Discover how Stayforall helps you transition smoothly to Germany with our comprehensive services.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/about-us-Stayforall",
    twitterTitle: "About Stayforall",
    twitterDescription:
      "Learn about our services and mission to assist your transition to Germany.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/about-us-Stayforall",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Organization",
      name: "Stayforall",
      logo: "/images/logo/SFAlargeLogo.webp",
      description:
        "Stayforall specializes in providing comprehensive services for individuals moving to Germany.",
    },
  },
  CareerScreen: {
    title: "Careers at Stayforall - Join Our Team",
    description:
      "Explore career opportunities at Stayforall and become part of our dedicated team helping others transition to Germany.",
    keywords: "Careers, Join Us, Work with Us, Stayforall Jobs",
    ogTitle: "Careers at Stayforall",
    ogDescription:
      "Join our team and help individuals make their move to Germany easier and more efficient.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/career-Stayforall",
    twitterTitle: "Careers at Stayforall",
    twitterDescription:
      "Discover exciting job opportunities and join our mission to assist individuals moving to Germany.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/career-Stayforall",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "JobPosting",
      title: "Career Opportunities at Stayforall",
      description:
        "Join our team to assist individuals in their transition to Germany.",
      hiringOrganization: {
        "@type": "Organization",
        name: "Stayforall",
        sameAs: "https://stayforall.com",
      },
      employmentType: "FULL_TIME",
      industry: "Service",
    },
  },
  FAQSPage: {
    title: "Frequently Asked Questions - Stayforall",
    description:
      "Find answers to common questions about our services and moving to Germany.",
    keywords: "FAQ, Questions, Stayforall, Moving to Germany",
    ogTitle: "FAQ - Stayforall",
    ogDescription:
      "Explore our FAQs to understand more about how Stayforall can assist you during your transition to Germany.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/FAQs-Stayforall",
    twitterTitle: "FAQ - Stayforall",
    twitterDescription:
      "Have questions? Check out our FAQs about moving to Germany with Stayforall.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/FAQs-Stayforall",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What services does Stayforall offer?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Stayforall offers a variety of services including room rentals, airport assistance, and student admissions.",
          },
        },
        // Add more questions and answers as needed
      ],
    },
  },
  LoginScreen: {
    title: "Login to Your Stayforall Account",
    description:
      "Access your Stayforall account to manage your services and requests.",
    keywords: "Login, Stayforall Account, User Login",
    ogTitle: "Login - Stayforall",
    ogDescription:
      "Log in to your Stayforall account to manage your services during your transition to Germany.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/login",
    twitterTitle: "Login - Stayforall",
    twitterDescription:
      "Access your account with Stayforall to simplify your move to Germany.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/login",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Login Page",
      description: "Login to your Stayforall account to access your services.",
    },
  },
  NewPasswordPage: {
    title: "Reset Your Stayforall Password",
    description:
      "Reset your password to regain access to your Stayforall account.",
    keywords: "Reset Password, Stayforall Account",
    ogTitle: "Reset Password - Stayforall",
    ogDescription:
      "Follow the instructions to reset your password for your Stayforall account.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/reset-password",
    twitterTitle: "Reset Password - Stayforall",
    twitterDescription:
      "Need to reset your password? Here’s how you can regain access to your account.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/reset-password",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Reset Password Page",
      description: "Reset your password for your Stayforall account.",
    },
  },
  NewPasswordPending: {
    title: "Password Reset Pending - Stayforall",
    description:
      "Your password reset request has been received. Check your email for instructions.",
    keywords: "Password Reset Pending, Stayforall",
    ogTitle: "Password Reset Pending - Stayforall",
    ogDescription:
      "Please check your email for further instructions to reset your password.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/login/passwordRecovery/",
    twitterTitle: "Password Reset Pending - Stayforall",
    twitterDescription:
      "Your request is being processed. Check your email to complete the reset.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/login/passwordRecovery/",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Password Reset Pending Page",
      description:
        "Instructions for completing your password reset for Stayforall.",
    },
  },
  NewPasswordSuccess: {
    title: "Password Reset Successful - Stayforall",
    description:
      "Your password has been successfully reset. You can now log in to your account.",
    keywords: "Password Reset Successful, Stayforall",
    ogTitle: "Password Reset Successful - Stayforall",
    ogDescription:
      "Your password has been reset. Click here to log in to your account.",
    ogImage: "/images/logo/SFAlargeLogo.webp",
    ogUrl: "https://stayforall.com/login/recoverySuccess/",
    twitterTitle: "Password Reset Successful - Stayforall",
    twitterDescription:
      "Your password has been successfully reset. Log in to your account now.",
    twitterImage: "/images/logo/SFAlargeLogo.webp",
    canonicalUrl: "https://stayforall.com/login/recoverySuccess/",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Password Reset Successful Page",
      description: "Confirming your password reset for Stayforall.",
    },
  },
  CookiesPolicy: {
    title: "Cookies Policy - Stayforall",
    description:
      "Learn about our use of cookies at Stayforall and how they enhance your experience.",
    keywords: "cookies policy, Stayforall, privacy, moving to Germany",
    ogTitle: "Stayforall Cookies Policy",
    ogDescription:
      "This page outlines how we use cookies to provide better services and experiences.",
    ogImage: "/images/logo/SFAsmallLogo.webp",
    ogUrl: "https://stayforall.com/cookies-policy",
    twitterTitle: "Cookies Policy - Stayforall",
    twitterDescription: "Learn about how Stayforall uses cookies on our site.",
    twitterImage: "/images/logo/SFAsmallLogo.webp",
    canonicalUrl: "https://stayforall.com/cookies-policy",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Cookies Policy - Stayforall",
      description: "Learn about our use of cookies at Stayforall.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  PrivacyPolicy: {
    title: "Privacy Policy - Stayforall",
    description:
      "Understand how Stayforall protects your privacy and handles your data.",
    keywords: "privacy policy, Stayforall, data protection, moving to Germany",
    ogTitle: "Stayforall Privacy Policy",
    ogDescription:
      "This page explains how Stayforall protects your personal data.",
    ogImage: "/images/logo/SFAsmallLogo.webp",
    ogUrl: "https://stayforall.com/privacy-policy",
    twitterTitle: "Privacy Policy - Stayforall",
    twitterDescription: "Learn about Stayforall's commitment to your privacy.",
    twitterImage: "/images/logo/SFAsmallLogo.webp",
    canonicalUrl: "https://stayforall.com/privacy-policy",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Privacy Policy - Stayforall",
      description:
        "Understand how Stayforall protects your privacy and handles your data.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  TermsAndCondition: {
    title: "Terms and Conditions - Stayforall",
    description:
      "Review the terms and conditions for using Stayforall services.",
    keywords: "terms and conditions, Stayforall, moving to Germany",
    ogTitle: "Stayforall Terms and Conditions",
    ogDescription:
      "This page outlines the terms and conditions for using our services.",
    ogImage: "/images/logo/SFAsmallLogo.webp",
    ogUrl: "https://stayforall.com/terms-and-conditions",
    twitterTitle: "Terms and Conditions - Stayforall",
    twitterDescription:
      "Read the terms and conditions for using Stayforall services.",
    twitterImage: "/images/logo/SFAsmallLogo.webp",
    canonicalUrl: "https://stayforall.com/terms-and-conditions",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Terms and Conditions - Stayforall",
      description:
        "Review the terms and conditions for using Stayforall services.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  FindWork: {
    title: "Find Work - Stayforall",
    description:
      "Explore job opportunities and resources for finding work in Germany.",
    keywords: "find work, Stayforall, job opportunities, Germany",
    ogTitle: "Find Work with Stayforall",
    ogDescription: "Discover resources to help you find work in Germany.",
    ogImage: "/images/logo/SFAsmallLogo.webp",
    ogUrl: "https://stayforall.com/find-work",
    twitterTitle: "Find Work - Stayforall",
    twitterDescription: "Explore job opportunities in Germany with Stayforall.",
    twitterImage: "/images/logo/SFAsmallLogo.webp",
    canonicalUrl: "https://stayforall.com/find-work",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Find Work - Stayforall",
      description:
        "Explore job opportunities and resources for finding work in Germany.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  ProfilePage: {
    title: "Profile - Stayforall",
    description: "Manage your profile and preferences on Stayforall.",
    keywords: "user profile, Stayforall, manage account",
    ogTitle: "User Profile - Stayforall",
    ogDescription: "Access and manage your profile information on Stayforall.",
    ogImage: "/images/logo/SFAsmallLogo.webp",
    ogUrl: "https://stayforall.com/profile",
    twitterTitle: "Profile - Stayforall",
    twitterDescription: "Manage your profile on Stayforall.",
    twitterImage: "/images/logo/SFAsmallLogo.webp",
    canonicalUrl: "https://stayforall.com/profile",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Profile - Stayforall",
      description: "Manage your profile and preferences on Stayforall.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  WorkStatus: {
    title: "Work Status - Stayforall",
    description:
      "Check the status of your work applications and opportunities.",
    keywords: "work status, Stayforall, job applications",
    ogTitle: "Work Status - Stayforall",
    ogDescription:
      "Monitor the status of your job applications with Stayforall.",
    ogImage: "/images/logo/SFAsmallLogo.webp",
    ogUrl: "https://stayforall.com/work-status",
    twitterTitle: "Work Status - Stayforall",
    twitterDescription: "Check your work application status with Stayforall.",
    twitterImage: "/images/logo/SFAsmallLogo.webp",
    canonicalUrl: "https://stayforall.com/work-status",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Work Status - Stayforall",
      description:
        "Check the status of your work applications and opportunities.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  SignUpScreen: {
    title: "Sign Up - Stayforall",
    description: "Create an account to start using Stayforall services.",
    keywords: "sign up, register, Stayforall",
    ogTitle: "Sign Up for Stayforall",
    ogDescription: "Join Stayforall and simplify your transition to Germany.",
    ogImage: "/images/logo/SFAsmallLogo.webp",
    ogUrl: "https://stayforall.com/register",
    twitterTitle: "Sign Up - Stayforall",
    twitterDescription: "Create an account with Stayforall today.",
    twitterImage: "/images/logo/SFAsmallLogo.webp",
    canonicalUrl: "https://stayforall.com/register",
    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Sign Up - Stayforall",
      description: "Create an account to start using Stayforall services.",
      brand: {
        "@type": "Brand",
        name: "Stayforall",
      },
    },
  },
  // Add more pages as needed
};

export default seoData;
