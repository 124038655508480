import { configureStore } from "@reduxjs/toolkit";
import {
  loadDocumentVerifyReducer,
  loadUserReducer,
  updateProfileReducer,
  uploadUserDocumentReducer,
  userReducer,
} from "./Actions/User";
import {
  inDServiceReducer,
  myInDServiceReducer,
  othersInDServiceReducer,
} from "./Actions/InDirectService";
import { dServiceReducer, myDServiceReducer } from "./Actions/DirectService";
import {
  interestNotificationReducer,
  interestReducer,
  myInterestReducer,
  othersInterestReducer,
} from "./Actions/InDirectService";
import { blogsAndArticleReducer } from "./Actions/BlogsAndArticle";
import { newsReducer } from "./Actions/News";
import { webinarNotifyReducer, webinarReducer } from "./Actions/Webinar";
import {
  authChatReducer,
  getAllMessagesReducer,
  messageNotificationReducer,
  sendMessageReducer,
} from "./Actions/chat";

const store = configureStore({
  reducer: {
    // user reducers
    user: userReducer,
    userInfo: loadUserReducer,
    updateProfile: updateProfileReducer,
    uploadUserDocument: uploadUserDocumentReducer,
    loadDocumentVerify: loadDocumentVerifyReducer,
    // indirect service reducer
    inDService: inDServiceReducer,
    myInDService: myInDServiceReducer,
    othersInDService: othersInDServiceReducer,
    // interest reducer
    interest: interestReducer,
    myInterests: myInterestReducer,
    othersInterests: othersInterestReducer,
    // direct service reducer
    dService: dServiceReducer,
    myDService: myDServiceReducer,
    // blogs and articles
    blogsAndArticle: blogsAndArticleReducer,
    // news
    allNews: newsReducer,
    // webinars
    allWebinar: webinarReducer,
    webinarNotify: webinarNotifyReducer,
    // chat reducer
    getChatReceiver: authChatReducer,
    getMessages: getAllMessagesReducer,
    sendMessage: sendMessageReducer,
    // Notifications
    getMessageNotification: messageNotificationReducer,
    getInterestNotification: interestNotificationReducer,
  },
});

export default store;
