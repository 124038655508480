import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { Form } from "react-bootstrap";
import { FaCity } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { IoStatsChartSharp } from "react-icons/io5";
import Offcanvas from "react-bootstrap/Offcanvas";
import RoomFilters from "./RoomFilters";
import AirportAssistanceFilters from "./AirportAssistanceFilters";

export default function Filtering({
  t,
  germanStates,
  service,
  stateOptions,
  state,
  setState,
  // Room
  city,
  setCity,
  uploadDate,
  setUploadDate,
  moveInDate,
  setMoveInDate,
  rent,
  setRent,
  securityDeposit,
  setSecurityDeposit,
  serviceFee,
  setServiceFee,
  propertyType,
  setPropertyType,
  numberOfBedrooms,
  setNumberOfBedrooms,
  lengthOfLease,
  setLengthOfLease,
  roomType,
  setRoomType,
  utilities,
  setUtilities,
  // Airport assistance
  airportOptions,
  airportName,
  setAirportName,
  arrivalDate,
  setArrivalDate,
  arrivalTime,
  setArrivalTime,
  showSort,
  setShowSort,
  forLarge,
}) {
  let stateCities = stateOptions.filter((y) => y.sampleName == state)[0];

  const [offcanvasWidth, setOffcanvasWidth] = useState("80%");
  const [value, setValue] = useState(100);
  const min = 50;
  const max = 300;
  const step = 50;

  const handleChange = (e) => {
    setValue(Number(e.target.value));
  };

  const updateWidth = () => {
    const width = window.innerWidth;

    if (width >= 1400) {
      setOffcanvasWidth("20%"); // Extra large screens
    } else if (width >= 992) {
      setOffcanvasWidth("30%"); // Large screens
    } else if (width >= 768) {
      setOffcanvasWidth("40%"); // Medium screens
    } else {
      setOffcanvasWidth("80%"); // Small screens
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);
  return (
    <>
      <Offcanvas
        style={{
          background: "#fff",
          width: offcanvasWidth,
        }}
        placement="end"
        show={showSort}
        onHide={() => setShowSort(false)}
      >
        <Offcanvas.Header>
          <Offcanvas.Title style={{ fontWeight: "bold" }}>
            {t("findWork.Filter")} {t(`findWork.${service}`)}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {service == "Room" ? (
            <RoomFilters
              t={t}
              germanStates={germanStates}
              stateCities={stateCities}
              service={service}
              stateOptions={germanStates}
              state={state}
              setState={setState}
              city={city}
              setCity={setCity}
              uploadDate={uploadDate}
              setUploadDate={setUploadDate}
              moveInDate={moveInDate}
              setMoveInDate={setMoveInDate}
              rent={rent}
              setRent={setRent}
              securityDeposit={securityDeposit}
              setSecurityDeposit={setSecurityDeposit}
              serviceFee={serviceFee}
              setServiceFee={setServiceFee}
              propertyType={propertyType}
              setPropertyType={setPropertyType}
              numberOfBedrooms={numberOfBedrooms}
              setNumberOfBedrooms={setNumberOfBedrooms}
              lengthOfLease={lengthOfLease}
              setLengthOfLease={setLengthOfLease}
              roomType={roomType}
              setRoomType={setRoomType}
              utilities={utilities}
              setUtilities={setUtilities}
              showSort={showSort}
              setShowSort={setShowSort}
            />
          ) : service == "Airport assistance" ? (
            <AirportAssistanceFilters
              t={t}
              germanStates={germanStates}
              service={service}
              stateOptions={germanStates}
              state={state}
              setState={setState}
              uploadDate={uploadDate}
              setUploadDate={setUploadDate}
              airportOptions={airportOptions}
              airportName={airportName}
              setAirportName={setAirportName}
              arrivalDate={arrivalDate}
              setArrivalDate={setArrivalDate}
              arrivalTime={arrivalTime}
              setArrivalTime={setArrivalTime}
              showSort={showSort}
              setShowSort={setShowSort}
            />
          ) : (
            ""
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* for large screens */}
      {forLarge && service == "Room" ? (
        <RoomFilters
          t={t}
          germanStates={germanStates}
          stateCities={stateCities}
          service={service}
          stateOptions={germanStates}
          state={state}
          setState={setState}
          city={city}
          setCity={setCity}
          uploadDate={uploadDate}
          setUploadDate={setUploadDate}
          moveInDate={moveInDate}
          setMoveInDate={setMoveInDate}
          rent={rent}
          setRent={setRent}
          securityDeposit={securityDeposit}
          setSecurityDeposit={setSecurityDeposit}
          serviceFee={serviceFee}
          setServiceFee={setServiceFee}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          numberOfBedrooms={numberOfBedrooms}
          setNumberOfBedrooms={setNumberOfBedrooms}
          lengthOfLease={lengthOfLease}
          setLengthOfLease={setLengthOfLease}
          roomType={roomType}
          setRoomType={setRoomType}
          utilities={utilities}
          setUtilities={setUtilities}
          showSort={showSort}
          setShowSort={setShowSort}
        />
      ) : forLarge && service == "Airport assistance" ? (
        <AirportAssistanceFilters
          t={t}
          germanStates={germanStates}
          service={service}
          stateOptions={germanStates}
          state={state}
          setState={setState}
          uploadDate={uploadDate}
          setUploadDate={setUploadDate}
          airportOptions={airportOptions}
          airportName={airportName}
          setAirportName={setAirportName}
          arrivalDate={arrivalDate}
          setArrivalDate={setArrivalDate}
          arrivalTime={arrivalTime}
          setArrivalTime={setArrivalTime}
          showSort={showSort}
          setShowSort={setShowSort}
        />
      ) : (
        ""
      )}
    </>
  );
}
